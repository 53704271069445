var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { ApiService } from "src/app/shared/api.service";
import { AuthService } from "src/app/shared/auth.service";
import * as moment from "moment-timezone";
import { MatAutocomplete, MatDialog } from "@angular/material";
import { timezones } from "src/app/shared/timezones";
import { EditNotificationDialogComponent } from "src/app/shared/edit-notification-dialog/edit-notification-dialog.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
var OfficesComponent = /** @class */ (function () {
    function OfficesComponent(apiService, authService, dialog) {
        this.apiService = apiService;
        this.authService = authService;
        this.dialog = dialog;
        this.offices = [];
        this.departments = [];
        this.newOfficeSchedule = {};
        this.schedules = [];
        this.officeMap = {};
        this.deptMap = {};
        this.teamInfo = {};
        this.channels = [];
        this.channelMap = {};
        this.timezones = [];
        this.userInfo = {};
        this.allLeaveTypes = [];
        this.icsParams = {
            officeId: "-1",
            period: 1,
            leaveTypes: [],
        };
        this.periods = [
            { p: 1, l: "One month" },
            { p: 3, l: "Three months" },
            { p: 6, l: "Six months" },
        ];
        this.timeSlots = [
            { id: 0, label: "00:00 AM" },
            { id: 1, label: "01:00 AM" },
            { id: 2, label: "02:00 AM" },
            { id: 3, label: "03:00 AM" },
            { id: 4, label: "04:00 AM" },
            { id: 5, label: "05:00 AM" },
            { id: 6, label: "06:00 AM" },
            { id: 7, label: "07:00 AM" },
            { id: 8, label: "08:00 AM" },
            { id: 9, label: "09:00 AM" },
            { id: 10, label: "10:00 AM" },
            { id: 11, label: "11:00 AM" },
            { id: 12, label: "12:00 PM" },
            { id: 13, label: "01:00 PM" },
            { id: 14, label: "02:00 PM" },
            { id: 15, label: "03:00 PM" },
            { id: 16, label: "04:00 PM" },
            { id: 17, label: "05:00 PM" },
            { id: 18, label: "06:00 PM" },
            { id: 19, label: "07:00 PM" },
            { id: 20, label: "08:00 PM" },
            { id: 21, label: "09:00 PM" },
            { id: 22, label: "10:00 PM" },
            { id: 23, label: "11:00 PM" },
        ];
        this.countries = [];
        this.teamHolidays = [];
        this.newHoliday = {};
        this.fromTemplate = false;
    }
    OfficesComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var allOffices, _a, channelResponse, channelResponse3, channelResponse4, channels4, channels3, zones, tzLabel, _loop_1, key;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
                        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
                        this.planId = localStorage.getItem("planId");
                        this.userId = localStorage.getItem("userId");
                        this.allLeaveTypes = this.apiService.getTeamEventTypes(this.teamInfo);
                        return [4 /*yield*/, this.apiService.getAllOffices().toPromise()];
                    case 1:
                        allOffices = _b.sent();
                        this.offices = allOffices.filter(function (m) {
                            return m.type === "office";
                        });
                        this.departments = allOffices.filter(function (m) {
                            return m.type === "department";
                        });
                        console.log("allOffices", allOffices);
                        _a = this;
                        return [4 /*yield*/, this.apiService.getAllSchedules().toPromise()];
                    case 2:
                        _a.schedules = _b.sent();
                        this.dataSource = new MatTableDataSource(this.schedules);
                        this.schedules.forEach(function (m) {
                            if (m.onlyDaily === undefined) {
                                m.onlyDaily = true;
                            }
                            if (m.onlyWeekly === undefined) {
                                m.onlyWeekly = true;
                            }
                        });
                        this.offices.map(function (m) {
                            _this.officeMap[m.id] = m;
                        });
                        this.departments.map(function (m) {
                            _this.deptMap[m.id] = m;
                        });
                        return [4 /*yield*/, this.authService.getChannels().toPromise()];
                    case 3:
                        channelResponse = _b.sent();
                        return [4 /*yield*/, this.authService.getChannels3().toPromise()];
                    case 4:
                        channelResponse3 = _b.sent();
                        return [4 /*yield*/, this.authService.getChannels4().toPromise()];
                    case 5:
                        channelResponse4 = _b.sent();
                        //console.log('channelResponse',channelResponse);
                        if (channelResponse.ok) {
                            this.channels = channelResponse.channels;
                            this.channels = this.channels.filter(function (m) {
                                return m.is_archived !== true;
                            });
                            this.channels.map(function (m) {
                                _this.channelMap[m.id] = m;
                            });
                        }
                        if (channelResponse4.ok) {
                            channels4 = channelResponse4.channels.filter(function (m) {
                                return m.is_archived !== true && !_this.channelMap[m.id];
                            });
                            this.channels = this.channels.concat(channels4);
                            this.channels.map(function (m) {
                                _this.channelMap[m.id] = m;
                            });
                        }
                        if (channelResponse3.ok) {
                            channels3 = channelResponse3.groups.filter(function (m) {
                                return m.is_archived !== true && !_this.channelMap[m.id];
                            });
                            this.channels = this.channels.concat(channels3);
                            this.channels.map(function (m) {
                                _this.channelMap[m.id] = m;
                            });
                        }
                        this.channels = this.channels.sort(function (a, b) { return (a.name > b.name ? 1 : -1); });
                        zones = [];
                        tzLabel = moment.tz.guess();
                        _loop_1 = function (key) {
                            // this.countries.push({
                            //   code:key,
                            //   name:timezones.countries[key].name
                            // })
                            timezones.countries[key].zones.forEach(function (m) {
                                if (zones.indexOf(m) === -1) {
                                    zones.push(m);
                                }
                                if (m == tzLabel) {
                                    _this.country = key;
                                }
                            });
                        };
                        for (key in timezones.countries) {
                            _loop_1(key);
                        }
                        zones = zones.sort();
                        //generate timezone
                        zones.map(function (m) {
                            var tz = moment.tz(m).utcOffset();
                            _this.timezones.push({ offset: tz * 60, name: m });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    OfficesComponent.prototype.checked = function (value) {
        return this.icsParams.leaveTypes.includes(value);
    };
    OfficesComponent.prototype.getChannelName = function (id) {
        var channel = this.channels.filter(function (m) {
            return m.id === id;
        });
        if (channel && channel.length > 0) {
            //console.log(`resolved channel name ${channel[0].name} for id ${id}`);
            return channel[0].name;
        }
        else {
            //console.log(`could not resolve channel name for id ${id}`);
            return id;
        }
    };
    OfficesComponent.prototype.valueChanged = function (value, $event) {
        console.log($event);
        if ($event.checked) {
            this.icsParams.leaveTypes.push(value);
        }
        else {
            var indx = this.icsParams.leaveTypes.indexOf(value);
            console.log("indx", indx);
            this.icsParams.leaveTypes.splice(indx, 1);
        }
    };
    OfficesComponent.prototype.getTz = function (offset) {
        var v = this.timezones.filter(function (m) {
            return m.offset == offset;
        });
        if (v.length > 0) {
            return v[0].name;
        }
        else {
            ("404");
        }
    };
    OfficesComponent.prototype.getTimezones = function (filterText) {
        if (filterText) {
            return this.timezones.filter(function (m) {
                return m.name.toUpperCase().includes(filterText.toUpperCase());
            });
        }
        else {
            return this.timezones;
        }
    };
    OfficesComponent.prototype.getTimeSlotLabel = function (timeId) {
        var timeSlot = this.timeSlots.find(function (slot) { return slot.id === timeId; });
        if (!timeSlot) {
            // Convert number to time format if no matching slot found
            var ampm = timeId >= 12 ? "PM" : "AM";
            var hours = timeId > 12 ? timeId - 12 : timeId;
            return hours + ":00 " + ampm;
        }
        return timeSlot.label;
    };
    OfficesComponent.prototype.getInitial = function (name) {
        if (name) {
            return name.substring(0, 1);
        }
    };
    OfficesComponent.prototype.getRole = function () {
        var role = "member";
        var appRoles = this.teamInfo["appRoles"];
        if (appRoles) {
            for (var key in appRoles) {
                if (appRoles[key].indexOf(this.userId) != -1 && role !== "admin") {
                    role = key;
                }
            }
        }
        else if (this.teamInfo.userId == this.userId) {
            role = "admin";
        }
        return role;
    };
    OfficesComponent.prototype.create = function (name, type) {
        var _this = this;
        console.log("creating ", type, name);
        var tzLabel = moment.tz.guess();
        var data = {
            type: type,
            officeName: name,
            tz_label: tzLabel,
            tz_offset: moment.tz(tzLabel).utcOffset() * 60,
        };
        this.apiService.createOffice(data).subscribe(function (res) {
            //console.log('returned ',res);
            _this.apiService.toast("New " + type + " created.", "success");
            if (type === "office") {
                _this.offices.push(res);
                _this.officeMap[res.id] = res;
            }
            else {
                _this.departments.push(res);
                _this.deptMap[res.id] = res;
            }
        });
    };
    OfficesComponent.prototype.validForm = function () {
        var valid = false;
        if (this.newOfficeSchedule["channelId"] &&
            this.newOfficeSchedule["localHour"] &&
            this.newOfficeSchedule["tz_label"]) {
            valid = true;
        }
        return valid;
    };
    OfficesComponent.prototype.delete = function (office, index) {
        var _this = this;
        console.log("delete index", index);
        this.apiService.deleteOffice(office.id).subscribe(function (res) {
            //console.log('returned ',res);
            _this.apiService.toast(office.type + " deleted.", "success");
            if (office.type === "office") {
                _this.offices.splice(index, 1);
            }
            else {
                _this.departments.splice(index, 1);
            }
        });
    };
    OfficesComponent.prototype.createSchedule = function (newOfficeSchedule) {
        return __awaiter(this, void 0, void 0, function () {
            var offsetHour, utcHour, utcHour2, res, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log(newOfficeSchedule);
                        this.newOfficeSchedule = newOfficeSchedule;
                        delete this.newOfficeSchedule["tz_filter"];
                        offsetHour = moment.tz(this.newOfficeSchedule["tz_label"]).utcOffset() / 60;
                        console.log("offsetHour", offsetHour);
                        utcHour = this.newOfficeSchedule["localHour"] + offsetHour * -1;
                        if (utcHour > 24) {
                            utcHour = utcHour - 24;
                        }
                        this.newOfficeSchedule["utcHour"] = utcHour;
                        utcHour2 = this.newOfficeSchedule["localHour2"] + offsetHour * -1;
                        if (utcHour2 > 24) {
                            utcHour2 = utcHour2 - 24;
                        }
                        this.newOfficeSchedule["utcHour2"] = utcHour2;
                        this.newOfficeSchedule["tz_offset"] =
                            moment.tz(this.newOfficeSchedule["tz_label"]).utcOffset() * 60;
                        if (this.newOfficeSchedule["deptId"] == undefined) {
                            this.newOfficeSchedule["deptId"] = "-1";
                        }
                        if (this.newOfficeSchedule["officeId"] == undefined) {
                            this.newOfficeSchedule["officeId"] = "-1";
                        }
                        return [4 /*yield*/, this.apiService
                                .createSchedule(this.newOfficeSchedule)
                                .toPromise()];
                    case 1:
                        res = _a.sent();
                        this.apiService.toast("New notification schedule was created.", "success");
                        data = this.dataSource.data;
                        data.push(res);
                        this.dataSource.data = data.slice();
                        return [2 /*return*/];
                }
            });
        });
    };
    OfficesComponent.prototype.deleteSchedule = function (schedule, index) {
        return __awaiter(this, void 0, void 0, function () {
            var data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("delete index", index, schedule);
                        console.log("delete id", schedule.id);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiService.deleteSchedule(schedule.id).toPromise()];
                    case 2:
                        _a.sent();
                        data = this.dataSource.data;
                        data.splice(index, 1);
                        this.dataSource.data = data.slice(); // Trigger change detection
                        this.apiService.toast("Notification schedule was deleted.", "success");
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error("Error deleting schedule:", error_1);
                        this.apiService.toast("Error deleting notification schedule.", "error");
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    OfficesComponent.prototype.updateSchedule = function (schedule, index) {
        return __awaiter(this, void 0, void 0, function () {
            var offsetHour, utcHour, utcHour2, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("update");
                        delete schedule.tz_filter;
                        offsetHour = moment.tz(schedule.tz_label).utcOffset() / 60;
                        utcHour = schedule.localHour + offsetHour * -1;
                        schedule.utcHour = utcHour;
                        utcHour2 = schedule.localHour2 + offsetHour * -1;
                        schedule.utcHour2 = utcHour2;
                        if (schedule.deptId == undefined) {
                            schedule.deptId = null;
                        }
                        return [4 /*yield*/, this.apiService.updateSchedule(schedule).toPromise()];
                    case 1:
                        _a.sent();
                        delete schedule.editing;
                        data = this.dataSource.data;
                        data[index] = schedule;
                        this.dataSource.data = data;
                        this.apiService.toast("Notification schedule was updated.", "success");
                        return [2 /*return*/];
                }
            });
        });
    };
    OfficesComponent.prototype.openNotificationDialog = function (schedule, index) {
        var _this = this;
        console.log("this is new notification", index);
        var isEdit = !!schedule;
        var dialogData = {
            schedule: isEdit ? __assign({}, schedule) : this.getEmptySchedule(),
            offices: this.offices,
            departments: this.departments,
            channels: this.channels,
            timeSlots: this.timeSlots,
            getTimezones: this.getTimezones.bind(this),
            isEdit: isEdit,
        };
        var dialogRef = this.dialog.open(EditNotificationDialogComponent, {
            data: dialogData,
        });
        dialogRef.afterClosed().subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!result) return [3 /*break*/, 4];
                        if (!isEdit) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updateSchedule(result, index)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.createSchedule(result)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    OfficesComponent.prototype.getEmptySchedule = function () {
        return {
            officeId: null,
            deptId: null,
            channelId: null,
            localHour: null,
            localHour2: null,
            tz_label: null,
            disableTomorrow: false,
            enableNoShow: false,
            onlyDaily: true,
            onlyWeekly: true,
            tz_filter: "",
        };
    };
    OfficesComponent.prototype.openEditDialog = function (schedule, index) {
        var _this = this;
        console.log("this is edit notification", index);
        var dialogRef = this.dialog.open(EditNotificationDialogComponent, {
            data: {
                schedule: __assign({}, schedule),
                offices: this.offices,
                departments: this.departments,
                channels: this.channels,
                timeSlots: this.timeSlots,
                getTimezones: this.getTimezones.bind(this),
                isEdit: true,
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.updateSchedule(result, index);
            }
        });
    };
    OfficesComponent.prototype.getAvatarColor = function (name) {
        // Simple hash function
        var hash = name.split("").reduce(function (acc, char) {
            return char.charCodeAt(0) + ((acc << 5) - acc);
        }, 0);
        // Convert hash to RGB values
        var r = (hash & 0xff0000) >> 16;
        var g = (hash & 0x00ff00) >> 8;
        var b = hash & 0x0000ff;
        // Ensure the color is not too light (for visibility on white background)
        var minBrightness = 50;
        var adjustedR = Math.max(r, minBrightness);
        var adjustedG = Math.max(g, minBrightness);
        var adjustedB = Math.max(b, minBrightness);
        // Convert to hex and return
        return "#" + ((adjustedR << 16) | (adjustedG << 8) | adjustedB)
            .toString(16)
            .padStart(6, "0");
    };
    OfficesComponent.prototype.createOneHoliday = function () {
        var _this = this;
        console.log(this.newHoliday);
        this.apiService.createOneHoliday(this.newHoliday).subscribe(function (res) {
            _this.apiService.toast("New holiday created.", "success");
            res.fromDate = _this.apiService.createDateAsUTC(new Date(res.fromDate));
            res.toDate = _this.apiService.createDateAsUTC(new Date(res.toDate));
            _this.teamHolidays.push(res);
            _this.teamHolidays = _this.teamHolidays.sort(function (m, n) {
                return m.fromDate - n.fromDate;
            });
        });
    };
    OfficesComponent.prototype.deleteHoliday = function (holiday, index) {
        var _this = this;
        if (!holiday.id) {
            this.teamHolidays.splice(index, 1);
        }
        else {
            this.apiService.deleteOneHolidays(holiday.id).subscribe(function (res) {
                _this.apiService.toast("Holiday deleted.", "success");
                _this.teamHolidays.splice(index, 1);
            });
        }
    };
    OfficesComponent.prototype.updateHoliday = function (holiday) {
        var _this = this;
        console.log(holiday);
        if (holiday.id) {
            this.apiService.updateOneHoliday(holiday).subscribe(function (res) {
                delete holiday.editing;
                _this.apiService.toast("Holiday updated.", "success");
                _this.teamHolidays = _this.teamHolidays.sort(function (m, n) {
                    return m.fromDate - n.fromDate;
                });
            });
        }
        else {
            delete holiday.editing;
        }
    };
    OfficesComponent.prototype.copyFromTemplate = function (country) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log(country);
                        _a = this;
                        return [4 /*yield*/, this.apiService
                                .getCountryHolidays(country)
                                .toPromise()];
                    case 1:
                        _a.teamHolidays = _b.sent();
                        this.fromTemplate = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    OfficesComponent.prototype.generateICS = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log(this.icsParams);
                        this.downloadLink = undefined;
                        return [4 /*yield*/, this.apiService
                                .generateICS(this.icsParams)
                                .toPromise()];
                    case 1:
                        response = _a.sent();
                        this.downloadLink = response.fileName;
                        return [2 /*return*/];
                }
            });
        });
    };
    OfficesComponent.prototype.saveFromTemplate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _i, _a, m, response, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _i = 0, _a = this.teamHolidays;
                        _c.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        m = _a[_i];
                        m.fromTemplate = true;
                        return [4 /*yield*/, this.apiService.createOneHoliday(m).toPromise()];
                    case 2:
                        response = _c.sent();
                        _c.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.apiService.toast("Holidays created for your team.", "success");
                        this.fromTemplate = false;
                        _b = this;
                        return [4 /*yield*/, this.apiService.getTeamHolidays().toPromise()];
                    case 5:
                        _b.teamHolidays = _c.sent();
                        this.teamHolidays = this.teamHolidays.sort(function (m, n) {
                            return m.fromDate - n.fromDate;
                        });
                        this.teamHolidays.forEach(function (m) {
                            m.fromDate = _this.apiService.createDateAsUTC(new Date(m.fromDate));
                            m.toDate = _this.apiService.createDateAsUTC(new Date(m.toDate));
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return OfficesComponent;
}());
export { OfficesComponent };
