<div class="confimation-dialog">
  <!-- <mat-toolbar color="primary" >
      <mat-card-header>Confirm</mat-card-header>
    </mat-toolbar> -->

  <h4 mat-dialog-title>{{ data.message }}</h4>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
      <mat-form-field appearance="outline" fxFlex="100%">
        <mat-select placeholder="Event Type" [(ngModel)]="newEventType">
          <mat-option *ngFor="let eventType of data.eventTypes" [value]="eventType">
            {{ eventType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button [disabled]="!newEventType" mat-raised-button color="primary" (click)="onYesClick()">Save</button>
    <button mat-raised-button (click)="onNoClick()">Dismiss</button>
  </div>
</div>
