<!-- <div class="example-box" cdkDrag>
    Drag me around
  </div> -->
<mat-progress-bar
  *ngIf="authService.getProgressIndicator()"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>

<mat-card *ngIf="!hasShiftAccess()">
  <p>Shift Management is not available under <strong>Basic</strong> plan.</p>
  <!-- <h6>Looking for shift management? Checkout the video below.</h6>
  <video width="450px" controls>
    <source [src]="'assets/roster.mp4'" type="video/mp4" />

    Your browser does not support HTML5 video.
  </video> -->
</mat-card>
<mat-card
  *ngIf="!(teamInfo && teamInfo.roster) || !hasShiftAccess()"
  style="text-align: left"
>
  <p>
    The Shift Roster feature in Officebot enables you to create and manage
    weekly schedules for your team efficiently. Our intuitive and streamlined
    interface simplifies the process of building and publishing rosters. Once
    you've finalized a schedule, Officebot automatically notifies each team
    member of their assigned shifts via Slack and email, ensuring clear
    communication across your organization. To get started with Shift Roster,
    you'll need to complete a one-time setup of your roster configuration. This
    initial setup allows you to tailor the roster to your team's specific needs
    and preferences, setting the foundation for smooth, ongoing schedule
    management.
  </p>

  <h6>Key features of Shift Roster include:</h6>
  <ul>
    <li>User-friendly interface for easy schedule creation</li>
    <li>Instant publishing with automated notifications</li>
    <li>Multi-channel communication (Slack and email)</li>
    <li>Customizable roster configuration</li>
  </ul>
  <p>
    With Shift Roster, you can streamline your team's scheduling process,
    improve communication, and increase overall operational efficiency.
  </p>
  <button
    [disabled]="!hasShiftAccess()"
    style="margin-top: 20px"
    mat-raised-button
    color="primary"
    (click)="openSettings($event)"
  >
    Get Started
  </button>

  <!-- <h6>Watch The Demo</h6>
  <video width="450px" controls>
    <source [src]="'assets/roster.mp4'" type="video/mp4" />

    Your browser does not support HTML5 video.
  </video> -->
</mat-card>
<mat-card
  *ngIf="
    teamInfo && teamInfo.roster && getRole() !== 'member' && hasShiftAccess()
  "
>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutAlign="space-between start"
    fxFill
  >
    <div>
      <mat-form-field
        appearance="outline"
        style="font-size: 0.8rem"
        color="accent"
      >
        <mat-label>Office</mat-label>
        <mat-select
          placeholder="Office"
          [(ngModel)]="officeId"
          (ngModelChange)="refreshByOffice($event)"
        >
          <mat-option *ngFor="let m of offices" [value]="m.id">
            {{ m.officeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <div class="total">$500</div> -->
    </div>
    <div fxLayout="row" *ngIf="roster">
      <button mat-icon-button color="accent" (click)="prev()">
        <mat-icon aria-label="prev">navigate_before</mat-icon>
      </button>
      <div fxLayout="column">
        <h4 class="header mat-subheading-2">
          {{ startOfWeek.format("ddd") }} {{ startOfWeek.format("ll") }} ->
          {{ endOfWeek.format("ddd") }}
          {{ endOfWeek.format("ll") }}
        </h4>
        <!-- <span *ngIf="roster" class="status active">{{roster.rStatus}}</span>-->
        <span *ngIf="extraPublishStatus" style="font-size: 10px; color: gray">
          {{ extraPublishStatus }}</span
        >
        <div class="line"></div>
        <div fxLayout="row" class="stepper">
          <div
            fxLayout="column"
            class="step"
            *ngFor="let s of statuses; index as i"
          >
            <div class="circle" [ngClass]="{ active: s === roster.rStatus }">
              {{ i + 1 }}
            </div>
            <div class="text" [ngClass]="{ active: s === roster.rStatus }">
              {{ s }}
            </div>
          </div>
        </div>
      </div>
      <button mat-icon-button color="accent" (click)="next()">
        <mat-icon aria-label="next">navigate_next</mat-icon>
      </button>
    </div>
    <div fxLayout="row">
      <button
        style="margin-left: 20px"
        mat-mini-fab
        (click)="openSettings($event)"
        matTooltip="Modify settings"
      >
        <mat-icon aria-label="Example icon-button with a heart icon"
          >settings</mat-icon
        >
      </button>

      <button
        style="margin-left: 20px"
        mat-mini-fab
        color="accent"
        [matMenuTriggerFor]="menu"
        matTooltip="Download report for this week"
      >
        <mat-icon aria-label="Example icon-button with a heart icon"
          >download</mat-icon
        >
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="downloadAsPDF($event)">
          <mat-icon>picture_as_pdf</mat-icon>
          <span>As PDF</span>
        </button>
        <button mat-menu-item (click)="downloadAsExcel($event)">
          <mat-icon>border_inner</mat-icon>
          <span>As Excel</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-card>
<mat-card
  style="overflow: scroll"
  *ngIf="
    teamInfo &&
    teamInfo.roster &&
    getRole() != 'member' &&
    !loading &&
    hasShiftAccess()
  "
>
  <div fxLayout="column" fxLayoutWrap fxLayoutAlign="space-around start">
    <table class="roster-table" id="shitftable" #shitftable>
      <thead>
        <tr>
          <th>Member</th>
          <th *ngFor="let m of daysOfTheWeek">
            {{ m.format("ddd") }}<br />{{ m.format("MMM Do") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let m of botUsers">
          <td class="icon">
            <div fxLayout="column">
              <span *ngIf="membersMap[m.userId]"
                >&nbsp; {{ membersMap[m.userId].displayName }}</span
              >
              <div fxLayout="row">
                <div fxFlex="25%">
                  <img
                    style="border: 1px solid gray"
                    *ngIf="membersMap[m.userId]"
                    [src]="membersMap[m.userId].image_48"
                  />
                </div>
                <div fxFlex="75%">
                  <span
                    ><i class="fa fa-lg fa-money" style="font-size: 1.2rem"
                      >&nbsp;{{
                        getTotalWage(m) | currency : teamInfo.roster.currency
                      }}</i
                    ></span
                  >
                </div>
              </div>
              <div
                class="footer"
                fxLayout="row"
                fxLayoutWrap
                fxLayoutAlign="space-around center"
                fxFill
              >
                <div fxFlex="50%">
                  <span *ngIf="m.editing === true"
                    ><input
                      [options]="options"
                      class="editable"
                      currencyMask
                      [(ngModel)]="m.wageRate"
                      (blur)="updateWage(m)"
                  /></span>
                  <span *ngIf="m.editing !== true"
                    ><i class="fa fa fa-money" style="color: #8d8989"
                      >&nbsp;<strong
                        >{{
                          m.wageRate | currency : teamInfo.roster.currency
                        }}/hr</strong
                      ></i
                    >&nbsp;<i
                      (click)="m.editing = true"
                      class="fa fa-edit"
                      style="color: rgb(180, 180, 185)"
                    ></i
                  ></span>
                </div>
                <div fxFlex="50%">
                  <span
                    ><i
                      class="fa fa-lg fa-clock-o"
                      style="color: #8d8989; font-size: 16px"
                      >&nbsp;{{ getTotalHour(m) }} Hrs</i
                    ></span
                  >
                </div>
              </div>
              <!-- <div fxLayout="column">
                            <span *ngIf="membersMap[m.userId]">&nbsp; {{membersMap[m.userId].name}}</span>
                            
                           
                            
                            
                         </div> -->
            </div>
          </td>
          <td
            *ngFor="let event of daysOfTheWeek"
            class="events"
            (click)="createShift($event, m, event)"
          >
            <div class="drag-container">
              <div
                cdkDropList
                class="drag-list-container"
                [id]="getShiftCellId(m.userId, event)"
                [cdkDropListConnectedTo]="connectedTo"
                [cdkDropListData]="getShiftCell(m.userId, event)"
                (cdkDropListDropped)="drop($event)"
              >
                <div
                  [cdkDragData]="slot"
                  cdkDrag
                  [cdkDragDisabled]="roster.rStatus === 'closed'"
                  (click)="openShift($event, slot, m, event)"
                  class="capsule"
                  [ngClass]="{ fake: slot.fake === true }"
                  [ngStyle]="{
                    'margin-left': slot.multiple === true ? '75px' : '0px'
                  }"
                  *ngFor="let slot of getShiftCell(m.userId, event); index as i"
                >
                  <div *ngIf="slot.fake === undefined">
                    <div
                      class="drag-handle"
                      cdkDragHandle
                      (click)="dragging($event)"
                      *ngIf="slot.fake === undefined"
                    >
                      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                        <path
                          d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"
                        ></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg>
                    </div>
                    <span class="time"
                      >{{ slot.start.format("h:mm a") }}-{{
                        slot.end.format("h:mm a")
                      }}
                      <span class="diff">{{ getDiff(slot) }}h</span></span
                    >
                    <span
                      class="at"
                      [ngStyle]="{
                        'background-color': stringToColour(slot.shiftType)
                      }"
                      >{{ slot.shiftType }}</span
                    >

                    <i
                      class="fa fa-trash fa-lg trash"
                      (click)="delete($event, slot, m, event, i)"
                    ></i>
                    <i
                      *ngIf="slot.sComment"
                      class="fa fa-comment-o fa-lg comment"
                      [matTooltip]="slot.sComment"
                    ></i>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="capsule"
              [ngStyle]="{ 'background-color': colorMap[slot.type] }"
              *ngFor="let slot of getLeaveCell(m.userId, event)"
            >
              <span class="at">{{ slot.type }}</span>
            </div>

            <div
              class="capsule"
              [ngStyle]="{ 'background-color': '#2196F3' }"
              *ngFor="let slot of getHolidayCell(event)"
            >
              <span class="at">{{ slot.holidayName }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <button
      style="margin-top: 1rem"
      mat-raised-button
      color="primary"
      [disabled]="disablePublish"
      *ngIf="
        roster &&
        (roster.rStatus === 'active' || roster.rStatus === 'published')
      "
      (click)="publish()"
    >
      {{ buttonText }}
    </button>

    <button
      mat-raised-button
      color="accent"
      [disabled]="disablePublish"
      *ngIf="roster && roster.rStatus === 'adjustment'"
      (click)="release()"
    >
      Mark as Close
    </button>
  </div>
</mat-card>
