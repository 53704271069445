<app-header></app-header>
<div class="pricing-page">
  <div class="mat-display-1">Satisfy your cravings for productivity with our feature-rich plans</div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center none" fxLayoutGap="10px">
    <mat-card class="plan-card pro">
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column">
          <div class="mat-headline">Free</div>
          <div>
            <span class="mat-display-1"> $0</span>
            <br /><span class="sub-price">/mo/user</span> <br /><br /><span class="sub-price"
              >For a team of 3 or less</span
            >
            <br />
          </div>
          <div>
            <br />

            <p>
              Jumpstart your leave management with our feature-packed Free plan, perfect for small teams and startups.
            </p>
            <p>
              <strong> Discounts </strong> available for eligible nonprofits and educational institutions. Contact us at
              hello@appstacksolutions.com for more information.
            </p>
          </div>
        </div>
        <div></div>
      </div>
      <ul class="features">
        <li>✔️ Leave management</li>

        <li>✔️ Shift management</li>
      </ul>
    </mat-card>
    <mat-card class="plan-card basic">
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column">
          <div class="mat-headline">Basic</div>
          <div>
            <span class="mat-display-1"> ${{ plans.basic.user }}</span>
            <br /><span class="sub-price">/mo/user</span> <br />

            <!-- <h3>+${{ plans.basic.base }}/workspace</h3> -->
          </div>
          <div>
            <br />
            <span>Streamline your team's leave management with our cost-effective Basic plan.</span>
          </div>
        </div>
        <div>
          <!-- <img src="assets/donut.png" /> -->
        </div>
      </div>
      <h4>Leave Management</h4>
      <ul class="features">
        <li>✔️ Everything in leave management</li>
        <li>✔️ Slack Office Bot integration</li>
        <li>✔️ Up to 10 custom leave types</li>
        <li>✔️ Individual and team schedule visibility</li>
        <li>✔️ Recurring event scheduling</li>
        <li>✔️ Push notification to public & private channel</li>
        <li>✔️ Automated Slack status updates</li>
        <li>✔️ Holiday Reminders</li>
        <li>✔️ Export iCalendar ics) files</li>
        <li>✔️ Google Calendar synchronization</li>
        <li>✔️ Streamlined leave approval workflow</li>
        <li>✔️ Leave entitlement and quota management</li>
        <li>✔️ Personal and team leave balance tracking</li>
        <li>✔️ Daily and weekly Slack notifications</li>
        <li>✔️ Essential web console access</li>
      </ul>
    </mat-card>

    <mat-card class="plan-card standard">
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="column">
          <div class="mat-headline">Standard</div>

          <div>
            <span class="mat-display-1"> ${{ plans.standard.user }}</span>
            <br /><span class="sub-price">/mo/user</span> <br />
            <!-- <h3>+${{ plans.standard.base }}/workspace</h3> -->
          </div>
          <div class="popular mat-subheading-1">Most popular</div>
          <div>
            <span>Elevate your leave and shift management capabilities with our comprehensive Standard plan. </span>
          </div>
        </div>
        <div>
          <!-- <img src="assets/coffee.png" /> -->
        </div>
      </div>
      <h4>Leave + Shift Management</h4>
      <ul class="features">
        <li>✔️ All Basic plan features, plus:</li>
        <li>✔️ Advanced employee shift scheduling (4-week advance)</li>
        <li>✔️ Daily email notifications & reminders</li>
        <li>✔️ Ability to swap shift with other co-workers</li>
        <li>✔️ Export shift schedules and invoices</li>
        <li>✔️ Ability to manage wage rates & budget</li>
        <li>✔️ AI-assisted auto-scheduling (coming soon)</li>
      </ul>
    </mat-card>
  </div>

  <section style="margin-top: 1rem">
    <button mat-raised-button class="big" color="primary">
      Get Started <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
    </button>
  </section>
  <h3 id="faq">Frequently asked questions</h3>
  <div *ngFor="let faq of faqs">
    <h4>{{ faq.category }}</h4>
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="0.5%"
      fxLayoutAlign="center"
      style="margin-bottom: 1rem; text-align: left"
    >
      <mat-accordion fxFlex="50%">
        <mat-expansion-panel
          *ngFor="let qa of faq.questions"
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title> {{ qa.question }} </mat-panel-title>
          </mat-expansion-panel-header>
          <p [innerHTML]="qa.answer"></p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>

<app-footer></app-footer>
