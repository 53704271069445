var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ChangeDetectorRef } from "@angular/core";
import { AuthService } from "../shared/auth.service";
import { ApiService } from "../shared/api.service";
import { Angulartics2 } from "angulartics2";
import { MediaMatcher } from "@angular/cdk/layout";
import { EventEmitterService } from "../shared/event-emitter.service";
import { environment } from "../../environments/environment";
import { BuildInfo } from "../build-info";
import { ActivatedRoute, Router } from "@angular/router";
var SecuredComponent = /** @class */ (function () {
    function SecuredComponent(authService, apiService, angulartics2, changeDetectorRef, media, _eventEmiter, router, route) {
        this.authService = authService;
        this.apiService = apiService;
        this.angulartics2 = angulartics2;
        this._eventEmiter = _eventEmiter;
        this.router = router;
        this.route = route;
        this.profile = {};
        this.isAdmin = false;
        this.slackTimeZone = "";
        this.role = "member";
        this.plans = [];
        this.logout = function () {
            this.authService.logout();
        };
        this.mobileQuery = media.matchMedia("(max-width: 600px)");
        this._mobileQueryListener = function () { return changeDetectorRef.detectChanges(); };
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.angulartics2.eventTrack.next({
            action: "myAction",
            properties: {
                category: "myCategory",
                label: "myLabel",
            },
        });
    }
    SecuredComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that, data, userInfo, appRoles, key, r, members, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        that = this;
                        console.log("########  GET USER INFO EVERYTIME ");
                        console.log(environment);
                        localStorage.removeItem("profileloaded");
                        this.version = environment.version;
                        this.gitHash = BuildInfo.buildNo;
                        return [4 /*yield*/, this.authService.getUserInfo().toPromise()];
                    case 1:
                        data = _a.sent();
                        console.log("received data");
                        this.profile = data;
                        console.log("profile", this.profile);
                        if (!data.ok) {
                            this.logout();
                        }
                        this.profile = data;
                        localStorage.setItem("userInfo", JSON.stringify(data));
                        this.isAdmin = data.user.is_admin;
                        localStorage.setItem("teamId", data.user.team_id);
                        this.teamId = localStorage.getItem("teamId");
                        this.userId = localStorage.getItem("userId");
                        this.slackTimeZone = data.user.tz_label + " (UTC" + data.user.tz_offset / 3600 + ")";
                        return [4 /*yield*/, that.apiService.getBotUserInfo().toPromise()];
                    case 2:
                        userInfo = _a.sent();
                        localStorage.setItem("teamInfo", JSON.stringify(userInfo.teamInfo));
                        if (userInfo && userInfo.teamInfo) {
                            this.teamName = userInfo.teamInfo.teamName;
                        }
                        appRoles = userInfo.teamInfo["appRoles"];
                        if (appRoles) {
                            for (key in appRoles) {
                                if (appRoles[key].indexOf(userInfo.userId) != -1 && that.role !== "admin") {
                                    that.role = key;
                                }
                            }
                        }
                        else if (userInfo.teamInfo.userId == userInfo.userId) {
                            that.role = "admin";
                        }
                        localStorage.setItem("role", that.role);
                        if (userInfo.inActive == true) {
                            that.apiService.toast("Your account has been locked. Please contact your supervisor.", "error");
                            this.logout();
                        }
                        else if (userInfo.teamInfo.suspended == true) {
                            that.apiService.toast("Your team has been suspended from OfficeBot. Please contact customer support.", "error");
                            this.logout();
                        }
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, 6, 7]);
                        return [4 /*yield*/, this.authService.getMembers().toPromise()];
                    case 4:
                        r = _a.sent();
                        if (r.ok) {
                            members = r.members
                                .filter(function (user) {
                                return !user.is_bot && user.name != "slackbot";
                            })
                                .map(function (item) {
                                return {
                                    id: item.id,
                                    name: item.profile.display_name ? item.profile.display_name : item.name,
                                    displayName: item.profile.display_name,
                                    image_48: item.profile.image_48,
                                    tz_offset: item.tz_offset,
                                };
                            });
                            localStorage.setItem("members", JSON.stringify(members));
                        }
                        return [3 /*break*/, 7];
                    case 5:
                        error_1 = _a.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        console.log("setting profileloaded");
                        localStorage.setItem("profileloaded", "y");
                        this._eventEmiter.sendMessage("profileloaded");
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    SecuredComponent.prototype.beforeunloadHandler = function (event) {
        // localStorage.removeItem("userInfo");
        // localStorage.removeItem("userId");
        // localStorage.removeItem("teamId");
        // localStorage.removeItem("access_token");
        // localStorage.removeItem("bot_token");
    };
    return SecuredComponent;
}());
export { SecuredComponent };
