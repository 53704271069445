var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { ApiService } from "src/app/shared/api.service";
import { AuthService } from "src/app/shared/auth.service";
import * as moment from "moment-timezone";
import { timezones } from "src/app/shared/timezones";
var DepartmentsComponent = /** @class */ (function () {
    function DepartmentsComponent(apiService, authService) {
        var _this = this;
        this.apiService = apiService;
        this.authService = authService;
        this.offices = [];
        this.departments = [];
        this.officeMap = {};
        this.deptMap = {};
        this.teamInfo = {};
        this.channels = [];
        this.channelMap = {};
        this.timezones = [];
        this.userInfo = {};
        this.allLeaveTypes = [];
        this.create = function (name, type) { return __awaiter(_this, void 0, void 0, function () {
            var tzLabel, data, r;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("creating ", type, name);
                        tzLabel = moment.tz.guess();
                        data = {
                            type: type,
                            officeName: name,
                            tz_label: tzLabel,
                            tz_offset: moment.tz(tzLabel).utcOffset() * 60,
                        };
                        return [4 /*yield*/, this.apiService.createOffice(data).toPromise()];
                    case 1:
                        r = _a.sent();
                        console.log("returned ", r);
                        this.apiService.toast("New " + type.toUpperCase() + " created.", "success");
                        if (type === "office") {
                            this.offices.push(r);
                            this.officeMap[r.id] = r;
                        }
                        else {
                            this.departments.push(r);
                            this.deptMap[r.id] = r;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.delete = function (office, index) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("delete index", index);
                        return [4 /*yield*/, this.apiService.deleteOffice(office.id).toPromise()];
                    case 1:
                        _a.sent();
                        this.apiService.toast(office.type.toUpperCase() + " deleted.", "success");
                        if (office.type === "office") {
                            this.offices.splice(index, 1);
                        }
                        else {
                            this.departments.splice(index, 1);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.update = function (office) { return __awaiter(_this, void 0, void 0, function () {
            var tzLabel;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("update");
                        if (office.type === "department") {
                            tzLabel = moment.tz.guess();
                            office.tz_offset = moment.tz(tzLabel).utcOffset() * 60;
                            office.tz_label = tzLabel;
                        }
                        else {
                            office.tz_offset = moment.tz(office.tz_label).utcOffset() * 60;
                        }
                        return [4 /*yield*/, this.apiService.updateOffice(office).toPromise()];
                    case 1:
                        _a.sent();
                        delete office.editing;
                        this.apiService.toast(office.type.toUpperCase() + " name updated.", "success");
                        return [2 /*return*/];
                }
            });
        }); };
    }
    DepartmentsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var allOffices, zones, tzLabel, key;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
                        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
                        this.planId = "gold"; // localStorage.getItem("planId");
                        this.userId = localStorage.getItem("userId");
                        return [4 /*yield*/, this.apiService.getAllOffices().toPromise()];
                    case 1:
                        allOffices = _a.sent();
                        this.offices = allOffices.filter(function (m) {
                            return m.type === "office";
                        });
                        this.departments = allOffices.filter(function (m) {
                            return m.type === "department";
                        });
                        console.log("allOffices", allOffices);
                        this.offices.map(function (m) {
                            _this.officeMap[m.id] = m;
                        });
                        this.departments.map(function (m) {
                            _this.deptMap[m.id] = m;
                        });
                        zones = [];
                        tzLabel = moment.tz.guess();
                        for (key in timezones.countries) {
                            // this.countries.push({
                            //   code:key,
                            //   name:timezones.countries[key].name
                            // })
                            timezones.countries[key].zones.forEach(function (m) {
                                if (zones.indexOf(m) === -1) {
                                    zones.push(m);
                                }
                            });
                        }
                        zones = zones.sort();
                        //generate timezone
                        zones.map(function (m) {
                            var tz = moment.tz(m).utcOffset();
                            _this.timezones.push({ offset: tz * 60, name: m });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    DepartmentsComponent.prototype.getTimezones = function (filterText) {
        if (filterText) {
            return this.timezones.filter(function (m) {
                return m.name.toUpperCase().includes(filterText.toUpperCase());
            });
        }
        else {
            return this.timezones;
        }
    };
    DepartmentsComponent.prototype.getInitial = function (name) {
        if (name) {
            return name.substring(0, 1);
        }
    };
    DepartmentsComponent.prototype.getRole = function () {
        var role = "member";
        var appRoles = this.teamInfo["appRoles"];
        if (appRoles) {
            for (var key in appRoles) {
                if (appRoles[key].indexOf(this.userId) != -1 && role !== "admin") {
                    role = key;
                }
            }
        }
        else if (this.teamInfo.userId == this.userId) {
            role = "admin";
        }
        return role;
    };
    return DepartmentsComponent;
}());
export { DepartmentsComponent };
