import { FocusMonitor } from "@angular/cdk/a11y";
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { MatMenuTrigger, MatSort, MatTableDataSource } from "@angular/material";
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "src/app/shared/api.service";
import { AuthService } from "src/app/shared/auth.service";
import * as moment from "moment-timezone";

interface Holiday {
  id?: string;
  holidayName: string;
  fromDate: Date;
  toDate?: Date;
  fromTemplate?: boolean;
  editing?: boolean;
}

@Component({
  selector: "app-holidays",
  templateUrl: "./holidays.component.html",
  styleUrls: ["./holidays.component.scss"],
})
export class HolidaysComponent implements OnInit {
  @ViewChild("holidayDialog") holidayDialog!: TemplateRef<any>;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, {}) sort: MatSort;

  displayedColumns: string[] = ["name", "date", "actions"];

  newHoliday: Holiday = { holidayName: "", fromDate: new Date() };
  public country: string;
  public fromTemplate: boolean = false;

  currentHoliday: Holiday = { holidayName: "", fromDate: new Date() };
  isEditMode: boolean = false;
  editIndex: number = -1;

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    public authService: AuthService,
    private focusMonitor: FocusMonitor,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    let startOfCurrentYear = moment().startOf("year").format("YYYY-MM-DD");
    let endOfNextYear = moment()
      .add(1, "year")
      .endOf("year")
      .format("YYYY-MM-DD");
    let teamHolidays = await this.apiService
      .getTeamHolidaysWithRange(startOfCurrentYear, endOfNextYear)
      .toPromise();
    teamHolidays = teamHolidays.sort((m, n) => {
      return m.fromDate - n.fromDate;
    });
    teamHolidays.forEach((m) => {
      m.fromDate = this.apiService.createDateAsUTC(new Date(m.fromDate));
      m.toDate = this.apiService.createDateAsUTC(new Date(m.toDate));
    });
    this.dataSource = new MatTableDataSource(teamHolidays);
  }

  openHolidayDialog(holiday?: Holiday, index?: number): void {
    if (holiday && index !== undefined) {
      this.currentHoliday = { ...holiday };
      this.isEditMode = true;
      this.editIndex = index;
    } else {
      this.currentHoliday = { holidayName: "", fromDate: new Date() };
      this.isEditMode = false;
      this.editIndex = -1;
    }
    this.dialog.open(this.holidayDialog);
  }

  async createOneHoliday() {
    const res = await this.apiService
      .createOneHoliday(this.currentHoliday)
      .toPromise();

    res.fromDate = this.apiService.createDateAsUTC(new Date(res.fromDate));
    res.toDate = this.apiService.createDateAsUTC(new Date(res.toDate));
    let data = this.dataSource.data;
    data.push(res);
    data = data.sort((m: any, n: any) => {
      return m.fromDate - n.fromDate;
    });
    this.dataSource.data = [...data];
    this.currentHoliday = { holidayName: "", fromDate: new Date() };
    this.apiService.toast("New holiday was created.", "success");
    //this.dialog.closeAll();
  }

  async saveHoliday() {
    if (this.isEditMode) {
      // this.teamHolidays[this.editIndex] = {...this.currentHoliday};
      console.log("updating....", this.editIndex, this.currentHoliday);
      this.updateHoliday(this.currentHoliday, this.editIndex);
    } else {
      // this.teamHolidays.push({...this.currentHoliday});
      console.log("creating....", this.currentHoliday);
      this.createOneHoliday();
    }
    this.dialog.closeAll();
  }

  async updateHoliday(holiday: Holiday, index: number) {
    console.log("updating....");
    if (holiday.id) {
      this.apiService.updateOneHoliday(holiday).toPromise();
      delete holiday.editing;
      this.apiService.toast("Holiday was updated.", "success");
      let data = this.dataSource.data;
      data[index] = holiday;
      data = data.sort((m: any, n: any) => {
        return m.fromDate - n.fromDate;
      });
      this.dataSource.data = data;
    } else {
      delete holiday.editing;
    }
  }

  async deleteHoliday(holiday: Holiday, index: number) {
    const data = this.dataSource.data;
    if (!holiday.id) {
      data.splice(index, 1);
    } else {
      const res = await this.apiService
        .deleteOneHolidays(holiday.id)
        .toPromise();
      this.apiService.toast("Holiday was deleted.", "success");
      data.splice(index, 1);
    }

    this.dataSource.data = [...data];
  }

  async copyFromTemplate(country: string) {
    console.log(country);
    const r = await this.apiService.getCountryHolidays(country).toPromise();
    this.dataSource.data = [...r];
    this.fromTemplate = true;
  }

  // async saveFromTemplate() {
  //   // this.teamHolidays.forEach((m)=>{
  //   //    this.apiService.createOneHoliday(m);
  //   // })

  //   for (let m of this.teamHolidays) {
  //     m.fromTemplate = true;
  //     let response = await this.apiService.createOneHoliday(m).toPromise();
  //   }
  //   this.apiService.toast("Holidays created for your team.", "success");
  //   this.fromTemplate = false;
  //   this.teamHolidays = await this.apiService.getTeamHolidays().toPromise();
  //   this.teamHolidays = this.teamHolidays.sort((m, n) => {
  //     return m.fromDate.getTime() - n.fromDate.getTime();
  //   });
  //   this.teamHolidays.forEach((m) => {
  //     m.fromDate = this.apiService.createDateAsUTC(new Date(m.fromDate));
  //     m.toDate = this.apiService.createDateAsUTC(new Date(m.toDate));
  //   });
  // }

  getAvatarColor(name: string): string {
    // Simple hash function
    const hash = name.split("").reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);

    // Convert hash to RGB values
    const r = (hash & 0xff0000) >> 16;
    const g = (hash & 0x00ff00) >> 8;
    const b = hash & 0x0000ff;

    // Ensure the color is not too light (for visibility on white background)
    const minBrightness = 50;
    const adjustedR = Math.max(r, minBrightness);
    const adjustedG = Math.max(g, minBrightness);
    const adjustedB = Math.max(b, minBrightness);

    // Convert to hex and return
    return `#${((adjustedR << 16) | (adjustedG << 8) | adjustedB)
      .toString(16)
      .padStart(6, "0")}`;
  }
  getInitial(name: string): string {
    return name.charAt(0).toUpperCase();
  }
}
