import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { Angulartics2GoogleTagManager } from "angulartics2/gtm";
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from "ngx-cookieconsent";
import { Subscription } from "rxjs";
import { Router, NavigationEnd } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "web-app";

  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private cookieService: NgcCookieConsentService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    //angulartics2GoogleAnalytics.startTracking();
    angulartics2GoogleTagManager.startTracking();
  }

  ngOnInit() {
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.cookieService.popupOpen$.subscribe(() => {
      // you can use this.cookieService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.cookieService.popupClose$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      }
    );

    this.initializingSubscription = this.cookieService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        console.log(`initializing: ${JSON.stringify(event)}`);
      }
    );

    this.statusChangeSubscription = this.cookieService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.cookieService.getConfig() to do stuff...
        console.log("cookie status changed", this.cookieService.getConfig());
      }
    );

    this.revokeChoiceSubscription = this.cookieService.revokeChoice$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.cookieService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.cookieService.getConfig() to do stuff...
      }
    );

    // Update canonical URL on route change
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      const url = this.router.url;

      // Update canonical link
      let canonical = this.document.querySelector('link[rel="canonical"]');
      if (!canonical) {
        canonical = this.document.createElement("link");
        canonical.setAttribute("rel", "canonical");
        this.document.head.appendChild(canonical);
      }
      canonical.setAttribute("href", `https://officebot.info${url}`);

      // Update og:url
      let ogUrl = this.document.querySelector('meta[property="og:url"]');
      if (ogUrl) {
        ogUrl.setAttribute("content", `https://officebot.info${url}`);
      }
    });
  }
}
