<mat-progress-bar *ngIf="authService.getProgressIndicator()" mode="indeterminate" color="accent"></mat-progress-bar>

<!-- <h3 id="top" class="header mat-subheading-2">Your Subscription</h3> -->

<!-- draw flex layout with two card in one row -->
<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center" style="margin: 1rem">
  <mat-card class="plan-card" fxFlex="50%">
    <div class="card-loading" *ngIf="!subSummary">
      <div class="logo"></div>
    </div>
    <div *ngIf="subSummary && subSummary.plan">
      <h4 class="header mat-subheading-1 text-left">
        Current plan:
        <strong style="text-transform: capitalize; font-size: 1.2rem"> {{ subSummary.plan.planCode }}</strong>
        &nbsp;&nbsp;
        <span style="font-size: 0.8rem; opacity: 0.5">${{ subSummary.plan.price }} per user</span>
      </h4>
    </div>
    <div *ngIf="subSummary && !subSummary.plan">
      <h4 class="header mat-subheading-1 text-left">You don't have any active subscription.</h4>
    </div>
    <div *ngIf="subSummary && subSummary.invoice">
      <h4 class="header mat-subheading-1 text-left">
        Next billing on: {{ subSummary.invoice.dueDate * 1000 | date : "E, dd MMM" }}
      </h4>
    </div>

    <div *ngIf="cards && cards.length > 0">
      <h4 class="header mat-subheading-2 text-left">Payment Method</h4>

      <table>
        <thead></thead>
        <tr *ngFor="let c of cards">
          <td>
            <i
              class="fa fa-lg header-icon accent-color"
              [ngClass]="{
                'fa-cc-visa': c.brand === 'visa',
                'fa-cc-mastercard': c.brand === 'mastercard',
                'fa-cc-amex': c.brand === 'amex',
                'fa-cc-discover': c.brand === 'discover'
              }"
            ></i>
          </td>
          <td>**** {{ c.last4 }}</td>
          <td>{{ c.exp_month }}/{{ c.exp_year }}</td>
          <td>
            <div
              id="paymentmethods"
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutWrap
              fxLayoutGap="0.5%"
              fxLayoutAlign="center"
            >
              <button mat-raised-button color="dark" (click)="showCardEdit = true" [disabled]="subcriptionProcessing">
                Edit Card
              </button>
            </div>
          </td>
        </tr>
      </table>

      <div class="text-left" *ngIf="subSummary && subSummary.status !== 'cancelled'">
        <button
          style="margin-top: 20px"
          mat-stroked-button
          color="accent"
          (click)="cancelSubscription()"
          [disabled]="subcriptionProcessing"
        >
          Cancel Subscription
        </button>
      </div>
    </div>
    <div *ngIf="cards && cards.length === 0">
      <button mat-raised-button color="primary" (click)="showCardEdit = true" [disabled]="subcriptionProcessing">
        Add a payment method
      </button>
      <div class="text-left" style="margin-top: 1rem">
        <span style="font-size: large">⚠️ </span>
        <span style="font-size: small; opacity: 0.5"
          >You don't have a payment method. Your subscription will be automatically cancelled at the end of the billing
          cycle.</span
        >
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="0.25%" style="margin-top: 1rem" *ngFor="let plan of plans">
      <button
        *ngIf="!activeSubscription || activeSubscription.planCode !== plan.code"
        mat-raised-button
        color="primary"
        [disabled]="subcriptionProcessing"
        (click)="swicthOrSubscribe(plan)"
      >
        {{ activeSubscription ? "Switch to " : "Subscribe to " }}
        {{ plan.code }}
      </button>
    </div>
  </mat-card>
  <mat-card class="plan-card" fxFlex="50%" *ngIf="subSummary">
    <div>
      <div>
        <h4 class="header mat-subheading-2 text-left">Billing forecast</h4>
      </div>
      <div class="price1 text-left">
        <span>${{ subSummary && subSummary.invoice ? subSummary.invoice.amount : 0 }}</span>
      </div>
      <div>
        <h4 class="header mat-subheading-2 text-left" *ngIf="subSummary && subSummary.invoice">
          ${{ subSummary.invoice.discount }} discount applied
        </h4>
      </div>
      <div>
        <h4 class="header mat-subheading-2 text-left">No of users</h4>
      </div>
      <div class="price1 text-left">{{ subSummary && subSummary.invoice ? subSummary.invoice.quantity : 0 }}</div>
      <div class="text-left">
        <span style="font-size: 0.8rem; opacity: 0.5">Updates everyday at 00:00:00 GMT </span>
      </div>
    </div>
  </mat-card>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center" style="margin: 1rem">
  <mat-card fxFlex="50%" class="subscription" id="updatecard" *ngIf="showCardEdit">
    <div>
      <h4 class="header mat-subheading-2">
        {{ cards && cards.length > 0 ? "Replace your payment method" : "Add new payment method" }}
      </h4>
    </div>

    <form novalidate (ngSubmit)="updateCard($event)" [formGroup]="newCardFormGroup">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
        <mat-form-field appearance="outline" fxFlex="100%" color="primary">
          <mat-label>Card Holder Name</mat-label>
          <input matInput formControlName="name" placeholder="Enter name" [errorStateMatcher]="matcher" />

          <mat-error color="primary" *ngIf="newCardFormGroup.get('name').invalid">
            Please enter card holder name as appears on the credit card
          </mat-error>
        </mat-form-field>
      </div>
      <div
        style="margin-top: 50px"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="0.5%"
        fxLayoutAlign="center"
      >
        <ngx-stripe-card fxFlex="100%" [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
      </div>
      <div
        *ngIf="subcriptionProcessing"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="0.5%"
        fxLayoutAlign="center"
      >
        <mat-spinner color="accent"></mat-spinner>
      </div>

      <div
        style="margin-top: 50px"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="0.5%"
        fxLayoutAlign="center"
      >
        <button
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="newCardFormGroup.invalid || subcriptionProcessing"
        >
          Save
        </button>
      </div>
    </form>
  </mat-card>
</div>

<div class="billings" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center">
  <h3 class="header mat-subheading-2 text-left">Invoice history</h3>

  <table>
    <thead class="text-left">
      <tr>
        <th>Date</th>
        <th>Invoice #</th>

        <th>Status</th>
        <th>Paid</th>

        <th>Action</th>
      </tr>
    </thead>
    <tbody class="text-left">
      <tr *ngFor="let tnx of transactions">
        <td>{{ tnx.created * 1000 | date : "mediumDate" }}</td>
        <td>{{ tnx.number }}</td>

        <td>{{ tnx.paid ? "Complete" : "Incomplete" }}</td>
        <td>{{ tnx.amount_paid * 0.01 | currency : tnx.currency.toUpperCase() }}</td>

        <td>
          <a target="_blank" [href]="tnx.hosted_invoice_url">View</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<mat-card class="subscription" *ngIf="showCardEntry" id="card">
  <div class="header-box">
    <i class="fa fa-credit-card header-icon accent-color"></i>
  </div>
  <div class="header-text">
    <h4 class="title primary-color">Credit Card</h4>
  </div>

  <form novalidate (ngSubmit)="postSubscriptionFirst($event)" [formGroup]="stripeFormGroup">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
      <img src="./assets/stripe.png" style="height: 100%; margin: auto; width: 400px" />
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
      <mat-form-field appearance="outline" fxFlex="25%" color="primary">
        <mat-label>Card Holder Name</mat-label>
        <input matInput formControlName="name" placeholder="John Smith" [errorStateMatcher]="matcher" />

        <mat-error color="primary" *ngIf="stripeFormGroup.get('name').invalid">
          Please enter card holder name as appears on the credit card
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="25%">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" placeholder="abc@example.com" [errorStateMatcher]="matcher" />

        <mat-error *ngIf="stripeFormGroup.get('email').invalid">
          Please enter a valid and active email address. OfficeBot will send emails regarding subscription status,
          renewals and important communication related to your team.
        </mat-error>
      </mat-form-field>
    </div>
    <div
      style="margin-top: 50px"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="0.5%"
      fxLayoutAlign="center"
    >
      <ngx-stripe-card fxFlex="50%" [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
    </div>
    <div
      *ngIf="subcriptionProcessing"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="0.5%"
      fxLayoutAlign="center"
    >
      <mat-spinner color="accent"></mat-spinner>
    </div>

    <div
      style="margin-top: 50px"
      fxLayout="column"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="0.5%"
      fxLayoutAlign="center"
    >
      <p>
        All sales are final. If you are switching to another plan, you don't qualify for free trial. For first time
        subscription your card will be charged once trial period expires. You may cancel anytime during trial period.
      </p>
      <mat-checkbox style="margin-top: 10px" formControlName="termsChecked"
        >I accept the terms and conditions stated above.</mat-checkbox
      >
      <button
        style="margin-top: 10px"
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="
          stripeFormGroup.invalid || subcriptionProcessing || stripeFormGroup.get('termsChecked').value !== true
        "
      >
        Subscribe to {{ getTranslatedPlanCode(selectedNewPlan.code) }}
      </button>
    </div>
  </form>
</mat-card>
