/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-us-sheet.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./contact-us-sheet.component";
import * as i3 from "@angular/material/bottom-sheet";
var styles_ContactUsSheetComponent = [i0.styles];
var RenderType_ContactUsSheetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactUsSheetComponent, data: {} });
export { RenderType_ContactUsSheetComponent as RenderType_ContactUsSheetComponent };
export function View_ContactUsSheetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" For any enquiry please write email to "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["appstacksolutions@gmail.com"]))], null, null); }
export function View_ContactUsSheetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-us-sheet", [], null, null, null, View_ContactUsSheetComponent_0, RenderType_ContactUsSheetComponent)), i1.ɵdid(1, 114688, null, 0, i2.ContactUsSheetComponent, [i3.MatBottomSheetRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactUsSheetComponentNgFactory = i1.ɵccf("app-contact-us-sheet", i2.ContactUsSheetComponent, View_ContactUsSheetComponent_Host_0, {}, {}, []);
export { ContactUsSheetComponentNgFactory as ContactUsSheetComponentNgFactory };
