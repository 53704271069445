import { Component, OnInit, HostListener, ChangeDetectorRef } from "@angular/core";
import { AuthService } from "../shared/auth.service";
import { ApiService } from "../shared/api.service";
import { Angulartics2 } from "angulartics2";
import { MediaMatcher } from "@angular/cdk/layout";
import { EventEmitterService } from "../shared/event-emitter.service";
import { environment } from "../../environments/environment";
import { BuildInfo } from "../build-info";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-secured",
  templateUrl: "./secured.component.html",
  styleUrls: ["./secured.component.scss"],
})
export class SecuredComponent implements OnInit {
  mobileQuery: MediaQueryList;
  public _mobileQueryListener: () => void;
  public subsciption: any;
  public profile: any = {};
  public isAdmin: boolean = false;
  public slackTimeZone = "";
  public role: string = "member";
  public planId: string;
  public planCode: string;
  public teamId: string;
  public userId: string;
  public plans: any[] = [];
  public version: string;
  public gitHash: string;
  public teamName: string;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private angulartics2: Angulartics2,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private _eventEmiter: EventEmitterService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.angulartics2.eventTrack.next({
      action: "myAction",
      properties: {
        category: "myCategory",
        label: "myLabel",
      },
    });
  }

  async ngOnInit() {
    let that = this;
    console.log("########  GET USER INFO EVERYTIME ");
    console.log(environment);

    localStorage.removeItem("profileloaded");
    this.version = environment.version;
    this.gitHash = BuildInfo.buildNo;

    let data = await this.authService.getUserInfo().toPromise();
    console.log("received data");

    this.profile = data;
    console.log("profile", this.profile);
    if (!data.ok) {
      this.logout();
    }
    this.profile = data;
    localStorage.setItem("userInfo", JSON.stringify(data));
    this.isAdmin = data.user.is_admin;
    localStorage.setItem("teamId", data.user.team_id);
    this.teamId = localStorage.getItem("teamId");
    this.userId = localStorage.getItem("userId");
    this.slackTimeZone = data.user.tz_label + " (UTC" + data.user.tz_offset / 3600 + ")";

    let userInfo = await that.apiService.getBotUserInfo().toPromise();
    localStorage.setItem("teamInfo", JSON.stringify(userInfo.teamInfo));
    if (userInfo && userInfo.teamInfo) {
      this.teamName = userInfo.teamInfo.teamName;
    }

    var appRoles = userInfo.teamInfo["appRoles"];

    if (appRoles) {
      for (var key in appRoles) {
        if (appRoles[key].indexOf(userInfo.userId) != -1 && that.role !== "admin") {
          that.role = key;
        }
      }
    } else if (userInfo.teamInfo.userId == userInfo.userId) {
      that.role = "admin";
    }
    localStorage.setItem("role", that.role);
    if (userInfo.inActive == true) {
      that.apiService.toast("Your account has been locked. Please contact your supervisor.", "error");
      this.logout();
    } else if (userInfo.teamInfo.suspended == true) {
      that.apiService.toast("Your team has been suspended from OfficeBot. Please contact customer support.", "error");
      this.logout();
    }

    try {
      const r = await this.authService.getMembers().toPromise();
      if (r.ok) {
        let members = r.members
          .filter((user) => {
            return !user.is_bot && user.name != "slackbot";
          })
          .map((item) => {
            return {
              id: item.id,
              name: item.profile.display_name ? item.profile.display_name : item.name,
              displayName: item.profile.display_name,
              image_48: item.profile.image_48,
              tz_offset: item.tz_offset,
            };
          });

        localStorage.setItem("members", JSON.stringify(members));
      }
    } catch (error) {
    } finally {
      console.log("setting profileloaded");
      localStorage.setItem("profileloaded", "y");
      this._eventEmiter.sendMessage("profileloaded");
    }
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeunloadHandler(event) {
    // localStorage.removeItem("userInfo");
    // localStorage.removeItem("userId");
    // localStorage.removeItem("teamId");
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("bot_token");
  }

  logout = function () {
    this.authService.logout();
  };
}
