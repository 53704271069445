<div>
  <button mat-button [matMenuTriggerFor]="emojiMenu" class="emoji-button">
    {{ renderIconFromCode(selectedEmoji) }}
  </button>
  <mat-menu #emojiMenu="matMenu" class="emoji-menu">
    <button mat-menu-item *ngFor="let emoji of emojiList" (click)="selectEmoji(emoji.icon)">
      <span class="emoji-option">{{ emoji.icon }} {{ emoji.name }}</span>
    </button>
  </mat-menu>
</div>
