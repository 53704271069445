import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { NgxPageScrollModule } from "ngx-page-scroll";
import { Angulartics2Module } from "angulartics2";
//import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager } from "angulartics2/gtm";
import { environment } from "../environments/environment";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import {
  MatButtonModule,
  MatCheckboxModule,
  MatMenuModule,
  MatIconModule,
  MatFormFieldModule,
  MatRadioModule,
  MatSelectModule,
  MatDialogModule,
  MatInputModule,
  MatSnackBarModule,
  MatCardModule,
  MatTableModule,
  MatSidenavModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatProgressBarModule,
  MatTooltipModule,
  MatToolbarModule,
  MatSlideToggleModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatBottomSheetModule,
  MatSliderModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonToggleModule,
} from "@angular/material";

import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarMomentDateFormatter,
  DateAdapter,
  MOMENT,
} from "angular-calendar";
//import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as moment from "moment-timezone";
import { adapterFactory } from "angular-calendar/date-adapters/moment";
import { FlatpickrModule } from "angularx-flatpickr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { LoginComponent } from "./login/login.component";
import { SecuredComponent } from "./secured/secured.component";
import { DashboardComponent } from "./secured/dashboard/dashboard.component";
import { SchedulerComponent } from "./secured/scheduler/scheduler.component";
import { AuthService } from "./shared/auth.service";
import { TokenInterceptor } from "./shared/interceptor.service";
import { SafeGuardService } from "./shared/safegurad.service";
import { EventsComponent } from "./secured/events/events.component";
import { ApiService } from "./shared/api.service";
import { ChartsModule } from "ng2-charts";
import { TeamEventsComponent } from "./secured/team-events/team-events.component";
import { SubscriptionComponent } from "./secured/subscription/subscription.component";
import { NgxStripeModule } from "ngx-stripe";
import { ConfirmDialogComponent } from "./shared/confirm-dialog/confirm-dialog.component";
import { PaymentHistoryDialogComponent } from "./secured/payment-history-dialog/payment-history-dialog.component";
import { LandingComponent } from "./landing/landing.component";
import { PricingComponent } from "./pricing/pricing.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { SettingsComponent } from "./secured/settings/settings.component";
import { OfficesComponent } from "./secured/offices/offices.component";
import { DemosComponent } from "./demos/demos.component";
import { ImageZoomModule } from "angular2-image-zoom";
import { ContactUsSheetComponent } from "./contact-us-sheet/contact-us-sheet.component";
import { ModifyLeaveComponent } from "./shared/modify-leave/modify-leave.component";
import { RosterComponent } from "./secured/roster/roster.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { RosterDialogComponent } from "./shared/roster-dialog/roster-dialog.component";
import { RosterSettingsComponent } from "./shared/roster-settings/roster-settings.component";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ShiftComponent } from "./shift/shift.component";
import { EventEmitterService } from "./shared/event-emitter.service";
import { PrivacyComponent } from "./privacy/privacy.component";
import { NgcCookieConsentModule, NgcCookieConsentConfig } from "ngx-cookieconsent";
import { DepartmentsComponent } from "./secured/departments/departments.component";
import { OverlayContainer, OverlayModule } from "@angular/cdk/overlay";
import { LegalComponent } from "./legal/legal.component";
import { LeaveComponent } from "./leave/leave.component";
import { SignupComponent } from "./signup/signup.component";
import { EmojiPickerComponent } from "./shared/emoji-picker/emoji-picker.component";
import { SubsciptionResolver } from "./shared/subscription.resolver";
import { EditNotificationDialogComponent } from "./shared/edit-notification-dialog/edit-notification-dialog.component";
import { HolidaysComponent } from "./secured/holidays/holidays.component";
import { A11yModule } from "@angular/cdk/a11y";

import { LayoutModule } from "@angular/cdk/layout";

const appRoutes: Routes = [
  { path: "", component: LandingComponent },
  { path: "pricing", component: PricingComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "legal", component: LegalComponent },
  { path: "demos", component: DemosComponent },
  { path: "login", component: LoginComponent },
  { path: "signup", component: SignupComponent },
  { path: "shift", component: ShiftComponent },
  {
    path: "secured",
    component: SecuredComponent,
    canActivate: [SafeGuardService],
    resolve: {
      subscription: SubsciptionResolver,
    },
    children: [
      { path: "", component: DashboardComponent },
      { path: "dashboard", component: DashboardComponent },
      // {path: 'scheduler', component: SchedulerComponent},
      { path: "events", component: EventsComponent },
      { path: "team-events", component: TeamEventsComponent },
      { path: "subscription", component: SubscriptionComponent },
      { path: "settings", component: SettingsComponent },
      { path: "notifications", component: OfficesComponent },
      { path: "roster", component: RosterComponent },
      { path: "departments", component: DepartmentsComponent },
      { path: "holidays", component: HolidaysComponent },
    ],
  },
];

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain, // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: "bottom-left",
  theme: "classic",
  palette: {},
  type: "info",
  content: {
    message:
      "Ready to let the digital cookies 🍪 work their magic? By clicking 'Dig In', you're agreeing to sprinkle some tech sweetness into your browsing experience!",
    dismiss: "Dig In!",
    deny: "Refuse cookies",
    link: "Learn more",
    href: "https://officebot.info#cookie-policy",
    policy: "Cookie Policy",
  },
};

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [
    AppComponent,
    HeaderComponent,
    LandingComponent,
    LoginComponent,
    SecuredComponent,
    DashboardComponent,
    SchedulerComponent,
    EventsComponent,
    TeamEventsComponent,
    SubscriptionComponent,
    ConfirmDialogComponent,
    PaymentHistoryDialogComponent,
    FooterComponent,
    SettingsComponent,
    OfficesComponent,
    DemosComponent,
    ContactUsSheetComponent,
    ModifyLeaveComponent,
    RosterComponent,
    RosterDialogComponent,
    RosterSettingsComponent,
    ShiftComponent,
    PrivacyComponent,
    DepartmentsComponent,
    LegalComponent,
    LeaveComponent,
    SignupComponent,
    PricingComponent,
    EmojiPickerComponent,
    EditNotificationDialogComponent,
    HolidaysComponent,
  ],
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: false }),
    FlatpickrModule.forRoot(),
    Angulartics2Module.forRoot(),
    CalendarModule.forRoot(
      {
        provide: DateAdapter,
        useFactory: momentAdapterFactory,
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CalendarMomentDateFormatter,
        },
      }
    ),
    NgxStripeModule.forRoot(environment.stripeKey),
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatListModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatCheckboxModule,
    HttpClientModule,
    MatDialogModule,
    MatTableModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatExpansionModule,
    ChartsModule,
    MatTooltipModule,
    NgxPageScrollModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTabsModule,
    NgxMatSelectSearchModule,
    MatBottomSheetModule,
    DragDropModule,
    MatSliderModule,
    MatChipsModule,
    CurrencyMaskModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    OverlayModule,
    A11yModule,
    LayoutModule,
  ],
  providers: [
    AuthService,
    SafeGuardService,
    ApiService,
    EventEmitterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: MOMENT,
      useValue: moment,
    },
  ],
  entryComponents: [
    ConfirmDialogComponent,
    PaymentHistoryDialogComponent,
    ContactUsSheetComponent,
    ModifyLeaveComponent,
    RosterDialogComponent,
    RosterSettingsComponent,
    EditNotificationDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    //overlayContainer.getContainerElement().classList.add("my-dark-theme");
  }
}
