var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FocusMonitor } from "@angular/cdk/a11y";
import { OnInit, TemplateRef, ChangeDetectorRef, } from "@angular/core";
import { MatMenuTrigger, MatSort, MatTableDataSource } from "@angular/material";
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "src/app/shared/api.service";
import { AuthService } from "src/app/shared/auth.service";
import * as moment from "moment-timezone";
var HolidaysComponent = /** @class */ (function () {
    function HolidaysComponent(dialog, apiService, authService, focusMonitor, cdr) {
        this.dialog = dialog;
        this.apiService = apiService;
        this.authService = authService;
        this.focusMonitor = focusMonitor;
        this.cdr = cdr;
        this.displayedColumns = ["name", "date", "actions"];
        this.newHoliday = { holidayName: "", fromDate: new Date() };
        this.fromTemplate = false;
        this.currentHoliday = { holidayName: "", fromDate: new Date() };
        this.isEditMode = false;
        this.editIndex = -1;
    }
    HolidaysComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var startOfCurrentYear, endOfNextYear, teamHolidays;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        startOfCurrentYear = moment().startOf("year").format("YYYY-MM-DD");
                        endOfNextYear = moment()
                            .add(1, "year")
                            .endOf("year")
                            .format("YYYY-MM-DD");
                        return [4 /*yield*/, this.apiService
                                .getTeamHolidaysWithRange(startOfCurrentYear, endOfNextYear)
                                .toPromise()];
                    case 1:
                        teamHolidays = _a.sent();
                        teamHolidays = teamHolidays.sort(function (m, n) {
                            return m.fromDate - n.fromDate;
                        });
                        teamHolidays.forEach(function (m) {
                            m.fromDate = _this.apiService.createDateAsUTC(new Date(m.fromDate));
                            m.toDate = _this.apiService.createDateAsUTC(new Date(m.toDate));
                        });
                        this.dataSource = new MatTableDataSource(teamHolidays);
                        return [2 /*return*/];
                }
            });
        });
    };
    HolidaysComponent.prototype.openHolidayDialog = function (holiday, index) {
        if (holiday && index !== undefined) {
            this.currentHoliday = __assign({}, holiday);
            this.isEditMode = true;
            this.editIndex = index;
        }
        else {
            this.currentHoliday = { holidayName: "", fromDate: new Date() };
            this.isEditMode = false;
            this.editIndex = -1;
        }
        this.dialog.open(this.holidayDialog);
    };
    HolidaysComponent.prototype.createOneHoliday = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService
                            .createOneHoliday(this.currentHoliday)
                            .toPromise()];
                    case 1:
                        res = _a.sent();
                        res.fromDate = this.apiService.createDateAsUTC(new Date(res.fromDate));
                        res.toDate = this.apiService.createDateAsUTC(new Date(res.toDate));
                        data = this.dataSource.data;
                        data.push(res);
                        data = data.sort(function (m, n) {
                            return m.fromDate - n.fromDate;
                        });
                        this.dataSource.data = data.slice();
                        this.currentHoliday = { holidayName: "", fromDate: new Date() };
                        this.apiService.toast("New holiday was created.", "success");
                        return [2 /*return*/];
                }
            });
        });
    };
    HolidaysComponent.prototype.saveHoliday = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.isEditMode) {
                    // this.teamHolidays[this.editIndex] = {...this.currentHoliday};
                    console.log("updating....", this.editIndex, this.currentHoliday);
                    this.updateHoliday(this.currentHoliday, this.editIndex);
                }
                else {
                    // this.teamHolidays.push({...this.currentHoliday});
                    console.log("creating....", this.currentHoliday);
                    this.createOneHoliday();
                }
                this.dialog.closeAll();
                return [2 /*return*/];
            });
        });
    };
    HolidaysComponent.prototype.updateHoliday = function (holiday, index) {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                console.log("updating....");
                if (holiday.id) {
                    this.apiService.updateOneHoliday(holiday).toPromise();
                    delete holiday.editing;
                    this.apiService.toast("Holiday was updated.", "success");
                    data = this.dataSource.data;
                    data[index] = holiday;
                    data = data.sort(function (m, n) {
                        return m.fromDate - n.fromDate;
                    });
                    this.dataSource.data = data;
                }
                else {
                    delete holiday.editing;
                }
                return [2 /*return*/];
            });
        });
    };
    HolidaysComponent.prototype.deleteHoliday = function (holiday, index) {
        return __awaiter(this, void 0, void 0, function () {
            var data, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = this.dataSource.data;
                        if (!!holiday.id) return [3 /*break*/, 1];
                        data.splice(index, 1);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.apiService
                            .deleteOneHolidays(holiday.id)
                            .toPromise()];
                    case 2:
                        res = _a.sent();
                        this.apiService.toast("Holiday was deleted.", "success");
                        data.splice(index, 1);
                        _a.label = 3;
                    case 3:
                        this.dataSource.data = data.slice();
                        return [2 /*return*/];
                }
            });
        });
    };
    HolidaysComponent.prototype.copyFromTemplate = function (country) {
        return __awaiter(this, void 0, void 0, function () {
            var r;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log(country);
                        return [4 /*yield*/, this.apiService.getCountryHolidays(country).toPromise()];
                    case 1:
                        r = _a.sent();
                        this.dataSource.data = r.slice();
                        this.fromTemplate = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    // async saveFromTemplate() {
    //   // this.teamHolidays.forEach((m)=>{
    //   //    this.apiService.createOneHoliday(m);
    //   // })
    //   for (let m of this.teamHolidays) {
    //     m.fromTemplate = true;
    //     let response = await this.apiService.createOneHoliday(m).toPromise();
    //   }
    //   this.apiService.toast("Holidays created for your team.", "success");
    //   this.fromTemplate = false;
    //   this.teamHolidays = await this.apiService.getTeamHolidays().toPromise();
    //   this.teamHolidays = this.teamHolidays.sort((m, n) => {
    //     return m.fromDate.getTime() - n.fromDate.getTime();
    //   });
    //   this.teamHolidays.forEach((m) => {
    //     m.fromDate = this.apiService.createDateAsUTC(new Date(m.fromDate));
    //     m.toDate = this.apiService.createDateAsUTC(new Date(m.toDate));
    //   });
    // }
    HolidaysComponent.prototype.getAvatarColor = function (name) {
        // Simple hash function
        var hash = name.split("").reduce(function (acc, char) {
            return char.charCodeAt(0) + ((acc << 5) - acc);
        }, 0);
        // Convert hash to RGB values
        var r = (hash & 0xff0000) >> 16;
        var g = (hash & 0x00ff00) >> 8;
        var b = hash & 0x0000ff;
        // Ensure the color is not too light (for visibility on white background)
        var minBrightness = 50;
        var adjustedR = Math.max(r, minBrightness);
        var adjustedG = Math.max(g, minBrightness);
        var adjustedB = Math.max(b, minBrightness);
        // Convert to hex and return
        return "#" + ((adjustedR << 16) | (adjustedG << 8) | adjustedB)
            .toString(16)
            .padStart(6, "0");
    };
    HolidaysComponent.prototype.getInitial = function (name) {
        return name.charAt(0).toUpperCase();
    };
    return HolidaysComponent;
}());
export { HolidaysComponent };
