import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit, HostBinding, AfterViewInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material";

import { Observable } from "rxjs";
import { map, publishReplay, refCount } from "rxjs/operators";

enum VisibilityState {
  Visible = "visible",
  Hidden = "hidden",
}

enum Direction {
  Up = "Up",
  Down = "Down",
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements AfterViewInit {
  private isVisible = true;

  @ViewChild("sidenav") sidenav!: MatSidenav;

  isHandset$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isHandset$ = this.breakpointObserver.observe([Breakpoints.Handset]).pipe(
      map((result) => result.matches),
      publishReplay(1),
      refCount()
    );
  }

  ngAfterViewInit() {
    // const scroll$ = fromEvent(window, "scroll").pipe(
    //   throttleTime(10),
    //   map(() => window.pageYOffset),
    //   pairwise(),
    //   map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
    //   distinctUntilChanged(),
    //   share()
    // );
    // const goingUp$ = scroll$.pipe(filter((direction) => direction === Direction.Up));
    // const goingDown$ = scroll$.pipe(filter((direction) => direction === Direction.Down));
    // goingUp$.subscribe(() => (this.isVisible = true));
    // goingDown$.subscribe(() => (this.isVisible = false));
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }
}
