<mat-progress-bar
  *ngIf="authService.getProgressIndicator()"
  mode="indeterminate"
  color="primary"
  class="top-progress-bar"
></mat-progress-bar>

<div class="calendar-container">
  <!-- Header Card -->
  <mat-card class="header-card">
    <div class="calendar-header" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div class="title-section">
        <h2 class="mat-h2 m-0">Team's Event Calendar</h2>
        <p class="mat-subtitle-1 text-muted">{{ viewDate | calendarDate : view + "ViewTitle" : "en" }}</p>
      </div>

      <div class="controls-section" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="1rem">
        <mat-form-field appearance="outline" class="member-select">
          <mat-label>Filter by Team Member</mat-label>
          <mat-select [(ngModel)]="selectedUserId" (ngModelChange)="(filterEvents)">
            <!-- Custom trigger to show selected value -->
            <mat-select-trigger>
              <mat-icon *ngIf="selectedUserId === '-1'">group</mat-icon>
              <mat-icon *ngIf="selectedUserId !== '-1'">person</mat-icon>
              <span>{{ getSelectedMemberName(selectedUserId) }}</span>
            </mat-select-trigger>

            <!-- Options remain the same -->
            <mat-option value="-1">
              <mat-icon>group</mat-icon>
              <span>Everyone</span>
            </mat-option>
            <mat-option *ngFor="let member of members" [value]="member.userId">
              <mat-icon>person</mat-icon>
              <span>{{ member.displayName ? member.displayName : member.name }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="navigation-buttons" fxLayout="row" fxLayoutGap="0.5rem">
          <button
            mat-icon-button
            color="primary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="activeDayIsOpen = false"
            (click)="prev()"
            matTooltip="Previous Month"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-flat-button color="accent" mwlCalendarToday [(viewDate)]="viewDate">
            <mat-icon>today</mat-icon>
            <span>Today</span>
          </button>
          <button
            mat-icon-button
            color="primary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (click)="next()"
            (viewDateChange)="activeDayIsOpen = false"
            matTooltip="Next Month"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card>

  <!-- Calendar View -->
  <mat-card class="calendar-card">
    <mwl-calendar-month-view
      [viewDate]="viewDate"
      [events]="filterEvents()"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
      [cellTemplate]="customCellTemplate"
      class="calendar-view"
    >
    </mwl-calendar-month-view>
  </mat-card>

  <!-- Event Creation Card (Admin Only) -->
  <mat-card class="event-creation-card" >
    <mat-card-header>
      <mat-card-title>
        <h3 class="mat-subheading-2 text-left">Create New Event</h3>
      </mat-card-title>
      <mat-card-subtitle>Schedule events for team members</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <form #eventForm="ngForm" class="event-form" fxLayout="column" fxLayoutGap="1rem">
        <!-- Team Member Selection -->

        <!-- Event Duration Type -->
        <div class="duration-selection">
          <mat-button-toggle-group [(ngModel)]="event.duration" name="duration" required>
            <mat-button-toggle value="single">
              <mat-icon>event</mat-icon>
              Single Day
            </mat-button-toggle>
            <mat-button-toggle value="multiple">
              <mat-icon>date_range</mat-icon>
              Multiple Days
            </mat-button-toggle>
            <mat-button-toggle value="recurring" *ngIf="event.userId !== '-1'">
              <mat-icon>repeat</mat-icon>
              Recurring
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <!-- Event Details -->
        <div fxLayout="row wrap" fxLayoutGap="1rem grid">
          <mat-form-field appearance="outline" class="member-select" fxFlex="calc(25% - 1rem)" fxFlex.lt-md="100%">
            <mat-label>Select a team member</mat-label>
            <mat-select [(ngModel)]="event.userId" name="userId" required>
              <!-- Custom trigger to show selected value -->
              <mat-select-trigger>
                <mat-icon *ngIf="event.userId !== '-1'">person</mat-icon>
                <span>{{ getSelectedMemberNameForCreateEvent(event.userId) }}</span>
              </mat-select-trigger>

              <!-- Options remain the same -->

              <mat-option *ngFor="let member of membersForEvents" [value]="member.userId">
                <mat-icon>person</mat-icon>
                <span>{{ member.displayName ? member.displayName : member.name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Event Type -->
          <mat-form-field appearance="outline" fxFlex="calc(25% - 1rem)" fxFlex.lt-md="100%">
            <mat-label>Event Type</mat-label>
            <mat-select [(ngModel)]="event.type" name="type" required>
              <mat-option *ngFor="let eventType of appliedEventTypes" [value]="eventType">
                {{ eventType }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Recurring Days Selection -->
          <mat-form-field
            appearance="outline"
            fxFlex="calc(25% - 1rem)"
            fxFlex.lt-md="100%"
            *ngIf="event.duration === 'recurring'"
          >
            <mat-label>Recurring Days</mat-label>
            <mat-select [(ngModel)]="event.weekDays" name="weekDays" multiple required>
              <mat-option *ngFor="let weekDay of daysOfWeek" [value]="weekDay.tag">
                {{ weekDay.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Date Selection -->
          <mat-form-field appearance="outline" fxFlex="calc(25% - 1rem)" fxFlex.lt-md="100%">
            <mat-label>{{ event.duration === "multiple" ? "Start Date" : "Date" }}</mat-label>
            <input matInput [matDatepicker]="fromPicker" [(ngModel)]="event.fromDate" name="fromDate" required />
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
          </mat-form-field>

          <!-- End Date -->
          <mat-form-field
            appearance="outline"
            fxFlex="calc(25% - 1rem)"
            fxFlex.lt-md="100%"
            *ngIf="event.duration === 'multiple' || event.duration === 'recurring'"
          >
            <mat-label>End Date</mat-label>
            <input
              matInput
              [matDatepicker]="toPicker"
              [(ngModel)]="event.toDate"
              name="toDate"
              [min]="event.fromDate"
              [disabled]="!event.fromDate"
              required
            />
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
          </mat-form-field>

          <!-- Comment -->
          <mat-form-field appearance="outline" fxFlex="calc(25% - 1rem)" fxFlex.lt-md="100%">
            <mat-label>Comment</mat-label>
            <input matInput [(ngModel)]="event.comment" name="comment" placeholder="Optional notes" />
            <mat-icon matSuffix>comment</mat-icon>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>

    <mat-card-actions align="center">
      <button mat-button (click)="eventForm.resetForm()">Clear</button>
      <button
        mat-raised-button
        color="primary"
        (click)="createEvent($event)"
        [disabled]="!eventForm.form.valid || !validateInput() || authService.getProgressIndicator()"
      >
        <mat-icon>add</mat-icon>
        Create Event
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<!-- Custom Cell Template -->
<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="custom-cell">
    <div class="cell-header" [class.today]="day.isToday">
      <span class="day-number">{{ day.date | calendarDate : "monthViewDayNumber" : locale }}</span>
      <span class="badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
    </div>
    <div class="event-count" *ngIf="day.events.length > 0">
      {{ day.events.length }} {{ day.events.length === 1 ? "event" : "events" }}
    </div>
  </div>
</ng-template>
