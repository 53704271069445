<div class="confimation-dialog">

    <h4 mat-dialog-title>Billing Details</h4>

    <div mat-dialog-content  >
        <div *ngIf="subcriptionProcessing" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
            <mat-spinner color="accent"></mat-spinner>
          </div>

        <div class="billings" *ngIf="!subcriptionProcessing" fxLayout="row"  fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">

          
          <table >
            <thead>
              <tr>
                <th>DATE</th>
                <th>PLAN</th>
                <th>CARD</th>
                <th>STATUS</th>
                <th >AMOUNT</th>
                <th >REFUND</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tnx of transactions">
                <td>{{tnx.created*1000|date:'mediumDate'}}</td>
                <td>{{tnx.statement_descriptor}}</td>
                <td>Card Ending {{tnx.source.last4}}</td>
                <td>{{tnx.paid? 'Complete':'In Complete'}}</td>
                <td >{{tnx.amount*.01|currency: 'USD'}}</td>
                <td *ngIf="tnx.amount_refunded >0">{{tnx.amount_refunded*.01|currency: 'USD'}}</td>
                
              </tr>
            </tbody>
          </table>
            
           
        </div>  
        
    </div>
    
    <div mat-dialog-actions>
    
      <button mat-raised-button (click)="onNoClick()">Close</button>
      
    </div>

</div>  
