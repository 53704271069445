<!-- header.component.html -->
<mat-toolbar class="sticky-header">
  <mat-toolbar-row>
    <!-- Logo and Brand -->
    <div class="brand-container">
      <a routerLink="">
        <img class="main-icon" src="./assets/appstack-new-logo-purple.png" alt="Officebot Logo" />
      </a>
      <span class="title" routerLink="">Officebot</span>
    </div>

    <!-- Desktop Menu -->
    <div class="desktop-menu" *ngIf="!(isHandset$ | async)">
      <nav>
        <ul class="nav-links">
          <li><a routerLink="/pricing">Pricing</a></li>
          <li><a href="https://officebot.tawk.help" target="_blank">Help Center</a></li>
        </ul>
      </nav>

      <div class="auth-container">
        <a mat-button routerLink="/login">Sign-In</a>
      </div>
    </div>

    <!-- Mobile Menu Button -->
    <button mat-icon-button *ngIf="isHandset$ | async" (click)="sidenav.toggle()" class="menu-button">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<!-- Mobile Sidenav -->
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="over" position="end" class="mobile-sidenav">
    <mat-nav-list>
      <a mat-list-item routerLink="/pricing">Pricing</a>
      <a mat-list-item href="https://officebot.tawk.help" target="_blank">Help Center</a>
      <mat-divider></mat-divider>
      <a mat-list-item routerLink="/login">Sign-In</a>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
