<section class="footer mat-typography" color="accent">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>Write to us at <i>hello@appstacksolutions.com</i></div>

    <div class="footer-links">
      <a
        href="https://appstacksolutions.github.io/officebot-legal/terms.html"
        target="_blank"
        >Terms of Service</a
      >
      |
      <a
        href="https://appstacksolutions.github.io/officebot-legal/privacy.html"
        target="_blank"
        >Privacy Notice</a
      >
    </div>

    <div class="social-media-icons">
      <a
        href="https://www.facebook.com/profile.php?id=100065000916447"
        target="_blank"
      >
        <i class="fa fa-facebook"></i>
      </a>
      <!-- <a href="https://www.twitter.com/AppstackSolutions" target="_blank">
        <i class="fa fa-twitter"></i>
      </a> -->
      <a
        href="https://www.youtube.com/@appstacksolutions/videos"
        target="_blank"
      >
        <i class="fa fa-youtube"></i>
      </a>
      <a href="https://www.linkedin.com/showcase/getofficebot" target="_blank">
        <i class="fa fa-linkedin"></i>
      </a>
      <!-- <a href="https://www.instagram.com/AppstackSolutions" target="_blank">
        <i class="fa fa-instagram"></i>
      </a> -->
    </div>

    <div>&copy; Appstack Solutions LLC, CT, USA 2023-2028</div>
  </div>
</section>
