<mat-progress-bar *ngIf="authService.getProgressIndicator()" mode="indeterminate" color="primary"></mat-progress-bar>
<div class="holidays-container">
  <!-- <h1 class="mat-h1">Holidays</h1> -->

  <button
    mat-raised-button
    color="primary"
    class="add-holiday-btn"
    (click)="openHolidayDialog()"
    [disabled]="!authService.isOfficebottAdmin()"
  >
    <mat-icon>add</mat-icon>
    Add New Holiday
  </button>

  <mat-card>
    <mat-card-content>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let holiday">
            <div class="holiday-name">
              <div class="holiday-avatar" [style.background-color]="getAvatarColor(holiday.holidayName)">
                {{ getInitial(holiday.holidayName) }}
              </div>
              {{ holiday.holidayName }}
            </div>
          </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>On</th>
          <td mat-cell *matCellDef="let holiday">{{ holiday.fromDate | date : "mediumDate" }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let holiday; let i = index">
            <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!authService.isOfficebottAdmin()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="openHolidayDialog(holiday, i)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteHoliday(holiday, i)">
                <mat-icon color="warn">delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>

<!-- Holiday Dialog (Create/Update) -->
<ng-template #holidayDialog>
  <h2 mat-dialog-title>{{ isEditMode ? "Edit Holiday" : "Add New Holiday" }}</h2>
  <mat-dialog-content>
    <form #holidayForm="ngForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Holiday Name</mat-label>
        <input matInput [(ngModel)]="currentHoliday.holidayName" name="holidayName" required />
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="currentHoliday.fromDate" name="fromDate" required />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!holidayForm.form.valid" (click)="saveHoliday()">
      {{ isEditMode ? "Update" : "Add" }} Holiday
    </button>
  </mat-dialog-actions>
</ng-template>
