<mat-sidenav-container>
  <mat-sidenav
    #drawer
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
    [opened]="!mobileQuery.matches"
    fixedTopGap="80"
    role="navigation"
    class="sidenav"
  >
    <div class="logo">
      <span><img class="main-icon" src="./assets/logowithtext.png" /></span>
    </div>
    <mat-nav-list>
      <a
        mat-list-item
        routerLink="/secured/dashboard"
        routerLinkActive="active-list-item"
        angulartics2On="click"
        angularticsAction="Dashboard"
        angularticsLabel="Dashboard"
        angularticsValue="Dashboard"
        [class.disabled]="planCode == 'NONE'"
      >
        <mat-icon>dashboard</mat-icon>
        Dashboard
      </a>

      <a
        mat-list-item
        routerLink="/secured/team-events"
        routerLinkActive="active-list-item"
        angulartics2On="click"
        angularticsAction="TeamEvent"
        angularticsLabel="TeamEvent"
        angularticsValue="TeamEvent"
        [class.disabled]="planCode == 'NONE'"
      >
        <mat-icon>date_range</mat-icon> Calendar
      </a>

      <a
        mat-list-item
        routerLink="/secured/notifications"
        routerLinkActive="active-list-item"
        [class.disabled]="planCode == 'NONE'"
      >
        <mat-icon>notifications_active</mat-icon> Notifications
      </a>
      <!-- add menu for holidays -->
      <a
        mat-list-item
        routerLink="/secured/holidays"
        routerLinkActive="active-list-item"
        [class.disabled]="planCode == 'NONE'"
      >
        <mat-icon>celebration</mat-icon> Holidays
      </a>

      <a
        *ngIf="role !== 'member'"
        mat-list-item
        routerLink="/secured/departments"
        routerLinkActive="active-list-item"
        [class.disabled]="planCode == 'NONE'"
      >
        <mat-icon>apartment</mat-icon>Departments
      </a>

      <a
        *ngIf="role !== 'member'"
        [class.disabled]="planCode == 'NONE'"
        mat-list-item
        routerLink="/secured/roster"
        routerLinkActive="active-list-item"
      >
        <mat-icon>alarm</mat-icon> Shift Planner
      </a>
    </mat-nav-list>
    <mat-nav-list class="buttom-nav">
      <a
      *ngIf="role !== 'member'"
        mat-list-item
        routerLink="/secured/settings"
        routerLinkActive="active-list-item"
        [class.disabled]="planCode == 'NONE'"
      >
        <mat-icon>settings</mat-icon> Settings
      </a>
      <a *ngIf="role !== 'member'" mat-list-item routerLink="/secured/subscription" routerLinkActive="active-list-item">
        <mat-icon>receipt</mat-icon> Subscriptions
      </a>
      <a mat-list-item (click)="logout()" angulartics2On="click" angularticsAction="Logout">
        <mat-icon>exit_to_app</mat-icon> Help Center
      </a>
      <a mat-list-item (click)="logout()" angulartics2On="click" angularticsAction="Logout">
        <mat-icon>exit_to_app</mat-icon> Logout
      </a>
      <br />
      <div class="build-div" *ngIf="teamName">
        <p>{{ teamName }}</p>
      </div>

      <div class="build-div">
        <p>Build {{ version }} - {{ gitHash }}</p>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <mat-toolbar-row>
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" color="secondary">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span></span>
        <span class="title">OfficeBot Web Console</span>
        <span class="fill-remaining-space"></span>
        <p class="user-name" *ngIf="profile.user">
          Welcome,
          {{ profile.user.profile.display_name ? profile.user.profile.display_name : profile.user.profile.first_name }}
        </p>
      </mat-toolbar-row>
      <mat-toolbar-row class="no-subscription" *ngIf="planCode == 'NONE'">
        <p class="text-center">
          No active subscription detected. Ready to unlock the full potential of your workspace?
        </p>
        <p>Upgrade now and watch your team's productivity soar! ✨</p></mat-toolbar-row
      >
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
