<app-header></app-header>
<div class="legal-page">
  <h1>Terms of Service and Privacy Policy</h1>

  <div class="section">
    <h2>1. Introduction</h2>
    <p>
      Welcome to Officebot ("we," "our," or "us"). By using our leave and shift management service, you agree to these
      Terms of Service and Privacy Policy. Please read them carefully.
    </p>
  </div>

  <div class="section">
    <h2>2. Service Description</h2>
    <p>
      Officebot is a cloud-based leave and shift management solution that integrates with Slack, helping teams
      efficiently manage time off, work-from-home arrangements, and shift schedules.
    </p>
  </div>

  <div class="section">
    <h2>3. User Accounts</h2>
    <p>
      3.1. You must use your Slack account to use our service. You are responsible for maintaining the confidentiality
      of your account information.
    </p>

    <p>3.2. You are solely responsible for all activities that occur under your Slack account.</p>
  </div>

  <div class="section">
    <h2>4. User Data</h2>
    <p>4.1. You retain all rights to the data you input into Officebot.</p>
    <p>
      4.2. You grant us a license to use, modify, and distribute your data solely to provide and improve our service.
    </p>
    <p>4.3. We will not sell your data to third parties.</p>
  </div>

  <div class="section">
    <h2>5. Privacy</h2>
    <p>5.1. We collect and process personal data as necessary to provide our service.</p>
    <p>5.2. Types of data we collect include:</p>
    <ul>
      <li>Account information (name, email, company name)</li>
      <li>Leave and shift schedule information</li>
      <li>Usage data (how you interact with our service)</li>
    </ul>
    <p>5.3. We use your data to:</p>
    <ul>
      <li>Provide and improve our service</li>
      <li>Send you important notifications about your account or the service</li>
      <li>Respond to your inquiries and support requests</li>
    </ul>
    <p>5.4. We may share your data with:</p>
    <ul>
      <li>Service providers who assist us in providing the service</li>
      <li>Law enforcement when required by law</li>
    </ul>
    <p>5.5. We implement industry-standard security measures to protect your data.</p>
  </div>

  <div class="section">
    <h2>6. Subscriptions and Payments</h2>
    <p>6.1. We offer various subscription plans with different features and pricing.</p>
    <p>6.2. You agree to pay the fees associated with your chosen subscription plan.</p>
    <p>
      6.3. We may change our fees upon notice. Your continued use of the service after a fee change constitutes your
      agreement to pay the changed amount.
    </p>
  </div>

  <div class="section">
    <h2>7. Cancellation and Termination</h2>
    <p>7.1. You may cancel your subscription at any time through your account settings.</p>
    <p>7.2. We may terminate or suspend your access to Officebot if you violate these Terms.</p>
  </div>

  <div class="section">
    <h2>8. Modifications to the Service</h2>
    <p>We reserve the right to modify or discontinue the service at any time.</p>
  </div>

  <div class="section">
    <h2>9. Disclaimer of Warranties</h2>
    <p>The service is provided "as is" without warranty of any kind.</p>
  </div>

  <div class="section">
    <h2>10. Limitation of Liability</h2>
    <p>
      To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special,
      consequential, or punitive damages.
    </p>
  </div>

  <div class="section">
    <h2>11. Governing Law</h2>
    <p>These Terms shall be governed by the laws of State of Connecticut.</p>
  </div>

  <div class="section">
    <h2>12. Changes to These Terms</h2>
    <p>
      We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this
      page.
    </p>
  </div>

  <div class="section">
    <h2>13. Contact Us</h2>
    <p>If you have any questions about these Terms, please contact us at hello@appstacksolutions.com</p>
  </div>

  <p>Last updated: Sep 30th, 2024</p>
</div>
