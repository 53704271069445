var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import * as moment from "moment-timezone";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/shared/api.service";
import { AuthService } from "src/app/shared/auth.service";
import { MatDialog } from "@angular/material";
import * as cloneDeep from "lodash/cloneDeep";
import { RosterDialogComponent } from "src/app/shared/roster-dialog/roster-dialog.component";
import { moveItemInArray, transferArrayItem, } from "@angular/cdk/drag-drop";
import { RosterSettingsComponent } from "src/app/shared/roster-settings/roster-settings.component";
import { getCurrencySymbol } from "@angular/common";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { LeaveColorMap, hasShiftAccess, stringToColour, } from "src/app/shared/utility";
var RosterComponent = /** @class */ (function () {
    function RosterComponent(route, router, apiService, authService, dialog) {
        this.route = route;
        this.router = router;
        this.apiService = apiService;
        this.authService = authService;
        this.dialog = dialog;
        this.daysOfTheWeek = [];
        this.members = [];
        this.membersMap = {};
        this.rosterSettings = {};
        this.loading = true;
        this.shifts = [];
        this.connectedTo = [];
        this.shiftMap = {};
        this.counter = 0;
        this.teamInfo = {};
        this.userInfo = {};
        this.shiftTypes = ["Register", "Delivery", "Kitchen"];
        this.dropListData = [];
        this.leaveMap = {};
        this.botUsers = [];
        this.allBotUsers = [];
        this.leaves = [];
        this.options = { prefix: "$" };
        this.officeMap = {};
        this.buttonText = "Publish";
        this.statuses = ["active", "published", "adjustment", "closed"];
        this.planId = "BRONZE";
        this.planCode = "NONE";
        this.colorMap = LeaveColorMap;
        this.holidayMap = {};
        // getEvent(userId:string, mDate:moment):any{
        //   let event;
        //   //console.log('target moment', mDate.format('LL'));
        //   //if(userId ==='U5HKU75R7' && mDate.format('LL')=='April 17, 2019'){
        //     let filtered = this.shifts.filter((m)=>{
        //       //console.log(m['start'].valueOf(),mDate.startOf('day').valueOf());
        //       //console.log(m['start'].format('LL'), mDate.startOf('day').format('LL'));
        //       return m.userId == userId && m['start'].valueOf()>= mDate.startOf('day').valueOf()
        //       && m['end'].valueOf()<= mDate.endOf('day').valueOf();
        //     });
        //     if(filtered.length>0){
        //       event = filtered[0];
        //       //console.log('event moment', event['start'].format('LL'));
        //     }
        //   //}
        //   return event;
        // }
        this.stringToColour = function (str) {
            return stringToColour(str);
        };
        this.disablePublish = false;
    }
    RosterComponent.prototype.refreshByOffice = function ($event) {
        var _this = this;
        console.log("selected office Id", this.officeId);
        if (this.officeId !== "-1") {
            this.botUsers = this.allBotUsers.filter(function (m) {
                return m.officeId === _this.officeId;
            });
        }
        else {
            this.botUsers = this.allBotUsers;
        }
        console.log("roster", this.roster);
        var selected = this.roster.offices.filter(function (m) {
            return m === _this.officeId;
        });
        console.log("selected", selected);
        if (selected.length > 0) {
            this.buttonText = "Re-Publish";
        }
        else {
            this.buttonText = "Publish";
        }
    };
    RosterComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, strWeekDay, nowTime, day, allOffices;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (localStorage.getItem("planId")) {
                            this.planId = localStorage.getItem("planId").toUpperCase();
                        }
                        if (localStorage.getItem("planCode")) {
                            this.planCode = localStorage.getItem("planCode").toUpperCase();
                        }
                        if (localStorage.getItem("teamInfo")) {
                            this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
                            if (this.teamInfo.roster) {
                                this.options.prefix = getCurrencySymbol(this.teamInfo.roster.currency, "narrow");
                                this.shiftTypes = this.teamInfo.roster.positions;
                            }
                        }
                        _a = this;
                        return [4 /*yield*/, this.apiService.getAllBotUsers().toPromise()];
                    case 1:
                        _a.botUsers = _b.sent();
                        this.botUsers = this.botUsers.filter(function (m) {
                            return m.inActive !== true;
                        });
                        console.log("$$$ Bot users", this.botUsers);
                        this.members = JSON.parse(localStorage.getItem("members"));
                        this.members.map(function (m) {
                            _this.membersMap[m.id] = m;
                        });
                        console.log("membersMap", this.membersMap);
                        this.botUsers.map(function (m) {
                            console.log(_this.membersMap[m.userId]);
                            m.image_48 = _this.membersMap[m.userId].image_48;
                            //override display name from the officebot database
                            //this.membersMap[m.userId].displayName = m.displayName;
                            if (_this.teamInfo.roster) {
                                m.wageRate = m.wageRate || _this.teamInfo.roster.wageRate;
                            }
                        });
                        this.allBotUsers = this.botUsers;
                        strWeekDay = this.teamInfo.startOfWeek || "Monday";
                        console.log("teamInfo", this.teamInfo);
                        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
                        nowTime = moment(new Date().getTime() + this.userInfo.user.tz_offset * 1000);
                        this.startOfWeek = moment(new Date().getTime() + this.userInfo.user.tz_offset * 1000)
                            .day(strWeekDay)
                            .startOf("day");
                        if (nowTime.valueOf() < this.startOfWeek.valueOf()) {
                            this.startOfWeek = this.startOfWeek.subtract(7, "days");
                            this.endOfWeek = moment(this.startOfWeek).add(6, "days");
                        }
                        else {
                            this.endOfWeek = moment(this.startOfWeek).add(6, "days");
                        }
                        console.log("$$$ this.startOfWeek", this.startOfWeek);
                        day = this.startOfWeek;
                        while (day <= this.endOfWeek) {
                            //this.daysOfTheWeek.push(day.toDate();
                            this.daysOfTheWeek.push(day);
                            day = day.clone().add(1, "d");
                        }
                        console.log(this.daysOfTheWeek);
                        return [4 /*yield*/, this.apiService.getAllOffices().toPromise()];
                    case 2:
                        allOffices = _b.sent();
                        this.offices = allOffices.filter(function (m) {
                            return m.type === "office";
                        });
                        this.offices.unshift({ id: "-1", officeName: "All" });
                        if (this.offices.length > 0) {
                            this.officeId = this.offices[0].id;
                        }
                        this.offices.map(function (m) {
                            _this.officeMap[m.id] = m.officeName;
                        });
                        this.loadShifts();
                        this.loadLeaves();
                        this.loadHolidays();
                        return [2 /*return*/];
                }
            });
        });
    };
    RosterComponent.prototype.updateWage = function (member) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                //this.apiService.toast("updating wage","success");
                delete member.editing;
                this.apiService.updateUserApprovers([member]).toPromise();
                console.log("updated wage");
                return [2 /*return*/];
            });
        });
    };
    RosterComponent.prototype.getRole = function () {
        var role = "member";
        if (!this.teamInfo || !this.userInfo || !this.userInfo.user) {
            return role;
        }
        var appRoles = this.teamInfo["appRoles"];
        if (appRoles) {
            for (var key in appRoles) {
                if (appRoles[key].indexOf(this.userInfo.user.id) != -1 &&
                    role !== "admin") {
                    role = key;
                }
            }
        }
        else if (this.teamInfo.userId == this.userInfo.user.id) {
            role = "admin";
        }
        return role;
    };
    RosterComponent.prototype.hasShiftAccess = function () {
        return hasShiftAccess(this.planCode);
    };
    RosterComponent.prototype.loadShifts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, selected;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.buildConnector();
                        return [4 /*yield*/, this.apiService
                                .getRoster(this.startOfWeek.format("YYYY-MM-DD"), this.endOfWeek.format("YYYY-MM-DD"))
                                .toPromise()];
                    case 1:
                        data = _a.sent();
                        console.log(data);
                        this.roster = data.roster;
                        if (data.roster.rStatus === "published") {
                            this.extraPublishStatus =
                                "This roster has been published for following offices: ";
                            data.roster.offices.forEach(function (m) {
                                _this.extraPublishStatus += _this.officeMap[m];
                            });
                            selected = this.roster.offices.filter(function (m) {
                                return m === _this.officeId;
                            });
                            if (selected.length > 0) {
                                this.buttonText = "Re-Publish";
                            }
                            else {
                                this.buttonText = "Publish";
                            }
                        }
                        this.shifts = data.shifts;
                        this.shifts.forEach(function (element) {
                            element["start"] = moment(element.fromDate).add(-1 * element.tz_offset, "seconds");
                            element["end"] = moment(element.toDate).add(-1 * element.tz_offset, "seconds");
                            element["fromDate"] = moment(element.fromDate)
                                .add(-1 * element.tz_offset, "seconds")
                                .toDate();
                            element["toDate"] = moment(element.toDate)
                                .add(-1 * element.tz_offset, "seconds")
                                .toDate();
                            if (element["start"].format("DD") === element["end"].format("DD")) {
                                element["multiple"] = false;
                            }
                            else {
                                element["multiple"] = true;
                            }
                            var id = element["start"].format("DDMMYYYY") + element.userId;
                            // if(!this.shiftMap[id]){
                            //   this.shiftMap[id]=[];
                            // }
                            _this.shiftMap[id].push(element);
                        });
                        console.log("shifts", this.shiftMap);
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    RosterComponent.prototype.buildConnector = function () {
        var _this = this;
        this.members.forEach(function (member) {
            _this.daysOfTheWeek.forEach(function (day) {
                var id = day.format("DDMMYYYY") + member.id;
                _this.shiftMap[id] = [{ fake: true, shiftType: "fake" }];
                if (!_this.connectedTo.includes(id)) {
                    _this.connectedTo.push(id);
                }
            });
        });
    };
    RosterComponent.prototype.loadHolidays = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.apiService
                                .getTeamHolidaysWithRange(this.startOfWeek.format("YYYY-MM-DD"), this.endOfWeek.format("YYYY-MM-DD"))
                                .toPromise()];
                    case 1:
                        _a.holidays = _b.sent();
                        this.holidays.forEach(function (element) {
                            element["fromDate"] = moment(element.fromDate).add(-1 * element["tz_offset"], "seconds");
                            element["toDate"] = moment(element.toDate).add(-1 * element["tz_offset"], "seconds");
                            var id = moment(element["fromDate"]).format("DD");
                            if (!_this.holidayMap[id]) {
                                _this.holidayMap[id] = [];
                            }
                            _this.holidayMap[id].push(element);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    RosterComponent.prototype.loadLeaves = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log("leaves", this.leaveMap);
                        _a = this;
                        return [4 /*yield*/, this.apiService
                                .getTeamEventsRange(this.startOfWeek.format("YYYY-MM-DD"), this.endOfWeek.format("YYYY-MM-DD"))
                                .toPromise()];
                    case 1:
                        _a.leaves = _b.sent();
                        this.leaves.forEach(function (element) {
                            element["fromDate"] = moment(element.fromDate).add(-1 * element["tz_offset"], "seconds");
                            element["toDate"] = moment(element.toDate).add(-1 * element["tz_offset"], "seconds");
                            var id = moment(element["fromDate"]).format("DD") + element.userId;
                            if (!_this.leaveMap[id]) {
                                _this.leaveMap[id] = [];
                            }
                            _this.leaveMap[id].push(element);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    RosterComponent.prototype.prev = function () {
        if (this.counter === -3) {
            this.apiService.toast("You can only go back 4 weeks.", "error");
            return;
        }
        this.counter--;
        this.startOfWeek = this.startOfWeek.subtract(7, "days");
        this.endOfWeek = moment(this.startOfWeek).add(6, "days");
        var day = this.startOfWeek;
        this.daysOfTheWeek = [];
        while (day <= this.endOfWeek) {
            //this.daysOfTheWeek.push(day.toDate();
            this.daysOfTheWeek.push(day);
            day = day.clone().add(1, "d");
        }
        this.loadShifts();
    };
    RosterComponent.prototype.next = function () {
        if (this.counter === 4) {
            this.apiService.toast("You can only plan 4 weeks in advance.", "error");
            return;
        }
        this.counter++;
        this.startOfWeek = this.startOfWeek.add(7, "days");
        this.endOfWeek = moment(this.startOfWeek).add(6, "days");
        var day = this.startOfWeek;
        this.daysOfTheWeek = [];
        while (day <= this.endOfWeek) {
            //this.daysOfTheWeek.push(day.toDate();
            this.daysOfTheWeek.push(day);
            day = day.clone().add(1, "d");
        }
        this.loadShifts();
    };
    RosterComponent.prototype.getShiftCellId = function (userId, mDate) {
        var id = mDate.format("DDMMYYYY") + userId;
        return id;
    };
    RosterComponent.prototype.getConnector = function () {
        return this.connectedTo;
    };
    RosterComponent.prototype.getShiftCell = function (userId, mDate) {
        var cells = this.shiftMap[this.getShiftCellId(userId, mDate)];
        //console.log(" cell array",cells);
        return cells;
    };
    RosterComponent.prototype.getLeaveCell = function (userId, mDate) {
        var id = mDate.format("DD") + userId;
        return this.leaveMap[id];
    };
    RosterComponent.prototype.getHolidayCell = function (mDate) {
        var id = mDate.format("DD");
        return this.holidayMap[id];
    };
    RosterComponent.prototype.clickedOnCell = function ($event, text) {
        $event.stopPropagation();
        this.apiService.toast(text, "success");
    };
    RosterComponent.prototype.dragging = function ($event) {
        console.log("dragging", $event);
        //$event.stopPropagation();
    };
    RosterComponent.prototype.drop = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var slot, id, day, month, year, userId_1, botUser, minutes, persisted;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(event.previousContainer === event.container)) return [3 /*break*/, 1];
                        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
                        return [3 /*break*/, 3];
                    case 1:
                        //console.log('transfer array item');
                        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
                        slot = event.container.data[event.currentIndex];
                        console.log("before slot", slot);
                        id = event.container.id;
                        day = id.substring(0, 2);
                        month = parseInt(id.substr(2, 2)) - 1;
                        year = id.substr(4, 4);
                        userId_1 = id.substring(8);
                        botUser = this.botUsers.filter(function (m) {
                            return m.userId === userId_1;
                        })[0];
                        slot["wageRate"] = botUser.wageRate;
                        slot["start"] = slot["start"].set({
                            date: day,
                            month: month,
                            year: year,
                        });
                        slot["end"] = slot["end"].set({ date: day, month: month, year: year });
                        slot["fromDate"] = slot["start"].toDate();
                        slot["toDate"] = slot["end"].toDate();
                        slot["userId"] = userId_1;
                        minutes = moment
                            .utc(slot["end"].diff(slot["start"], "minutes"))
                            .valueOf();
                        minutes = Math.round(minutes / 60);
                        slot["estWage"] = minutes * slot.wageRate;
                        return [4 /*yield*/, this.apiService.updateShift(slot).toPromise()];
                    case 2:
                        persisted = _a.sent();
                        console.log("shift updated");
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RosterComponent.prototype.getDiff = function (slot) {
        return moment.utc(slot.end.diff(slot.start)).format("HH:mm");
    };
    RosterComponent.prototype.delete = function ($event, slot, member, mDate, index) {
        return __awaiter(this, void 0, void 0, function () {
            var arr, shiftIndex;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        $event.stopPropagation();
                        if (this.roster.rStatus === "closed") {
                            return [2 /*return*/];
                        }
                        arr = this.getShiftCell(member.userId, mDate);
                        shiftIndex = this.shifts.findIndex(function (m) { return m.id === slot.id; });
                        if (!slot.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.apiService.deleteOneShift(slot.id).toPromise()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (shiftIndex !== -1) {
                            this.shifts.splice(shiftIndex, 1);
                        }
                        arr.splice(index, 1);
                        return [2 /*return*/];
                }
            });
        });
    };
    RosterComponent.prototype.getTotalHour = function (member) {
        var filtered = this.shifts.filter(function (m) {
            return m.userId === member.userId;
        });
        var minutes = 0;
        filtered.forEach(function (m) {
            if (m["end"] && m["start"]) {
                minutes += moment.utc(m["end"].diff(m["start"], "minutes")).valueOf();
            }
        });
        return Math.ceil(minutes / 60);
    };
    RosterComponent.prototype.getTotalWage = function (member) {
        var filtered = this.shifts.filter(function (m) {
            return m.userId === member.userId;
        });
        var total = 0;
        filtered.forEach(function (m) {
            if (m["end"] && m["start"]) {
                var minutes = moment
                    .utc(m["end"].diff(m["start"], "minutes"))
                    .valueOf();
                minutes = Math.round(minutes / 60);
                total += minutes * m.wageRate;
            }
        });
        return total;
    };
    RosterComponent.prototype.downloadAsExcel = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.apiService.toast("This feature is under development", "warning");
                return [2 /*return*/];
            });
        });
    };
    RosterComponent.prototype.downloadAsPDF = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            var that, totalHours, totalCost, currenySymbol, item, doc, col, rows, key, temp, data, detailDoc, detailHeader, detailData;
            var _this = this;
            return __generator(this, function (_a) {
                that = this;
                totalHours = 0;
                totalCost = 0;
                currenySymbol = getCurrencySymbol(this.teamInfo.roster.currency, "narrow");
                item = {
                    Name: "XYZ",
                    Age: "22",
                    Gender: "Male",
                };
                doc = new jsPDF();
                col = ["Details", "Values"];
                rows = [];
                for (key in item) {
                    temp = [key, item[key]];
                    rows.push(temp);
                }
                doc.setFontSize(14);
                doc.text("Employee Cost Summary", 15, 10);
                doc.line(15, 12, 80, 12);
                doc.setFontSize(10);
                doc.text("Week Of " + this.startOfWeek.format("LL") + " to " + this.endOfWeek.format("LL"), 15, 20);
                data = [];
                //console.log(this.officeMap);
                this.botUsers.forEach(function (m) {
                    var row = [];
                    //console.log(m);
                    row.push(_this.membersMap[m.userId].name);
                    row.push(_this.officeMap[m.officeId]);
                    var employeeHours = _this.getTotalHour(m);
                    row.push(employeeHours);
                    totalHours += employeeHours;
                    var employeeWage = _this.getTotalWage(m);
                    totalCost += employeeWage;
                    row.push(currenySymbol + " " + employeeWage);
                    data.push(row);
                });
                doc.text("Total Hours: " + totalHours, 15, 25);
                doc.text("Total Cost: " + currenySymbol + " " + totalCost, 15, 30);
                autoTable(doc, {
                    theme: "grid",
                    margin: { top: 50 },
                    head: [["Name", "Office", "Total Hour", "Total Cost"]],
                    body: data,
                });
                doc.save("employee_cost_summary_" + this.startOfWeek.format("YYYYMMDD") + ".pdf");
                detailDoc = new jsPDF();
                detailDoc.setFontSize(14);
                detailDoc.text("Employee Cost Details", 15, 10);
                detailDoc.line(15, 12, 80, 12);
                detailDoc.setFontSize(10);
                detailDoc.text("Week Of " + this.startOfWeek.format("LL") + " to " + this.endOfWeek.format("LL"), 15, 20);
                detailHeader = ["Name"];
                this.daysOfTheWeek.forEach(function (m) {
                    detailHeader.push("" + m.format("MMM Do"));
                });
                detailHeader.push("Total Hour");
                detailHeader.push("Total Cost");
                detailData = [];
                this.botUsers.forEach(function (m) {
                    var row = [];
                    //console.log(m);
                    row.push(_this.membersMap[m.userId].name);
                    _this.daysOfTheWeek.forEach(function (event) {
                        console.log("DAY", "" + event.format("MMM Do"), m.userId);
                        var cellDate = "";
                        that.getShiftCell(m.userId, event).forEach(function (slot) {
                            console.log("" + event.format("MMM Do"), m.userId, "slot", slot);
                            if (slot.fake !== true) {
                                var diff = moment.utc(slot.end.diff(slot.start)).format("HH:mm");
                                cellDate += diff + "h\n" + currenySymbol + " " + slot.wageRate + "/h\n";
                                //row.push(cellDate);
                            }
                            else {
                                //row.push('\n');
                                cellDate += "\n";
                            }
                        });
                        row.push(cellDate);
                    });
                    var employeeHours = _this.getTotalHour(m);
                    row.push(employeeHours);
                    // totalHours += employeeHours;
                    var employeeWage = _this.getTotalWage(m);
                    // totalCost +=employeeWage;
                    row.push(currenySymbol + " " + employeeWage);
                    console.log("ROW FOR USER " + m.userId, row);
                    detailData.push(row);
                });
                detailDoc.text("Total Hours: " + totalHours, 15, 25);
                detailDoc.text("Total Cost: " + currenySymbol + " " + totalCost, 15, 30);
                autoTable(detailDoc, {
                    theme: "grid",
                    margin: { top: 50 },
                    head: [detailHeader],
                    body: detailData,
                });
                detailDoc.save("employee_cost_details_" + this.startOfWeek.format("YYYYMMDD") + ".pdf");
                return [2 /*return*/];
            });
        });
    };
    RosterComponent.prototype.openSettings = function ($event) {
        var _this = this;
        if ($event) {
            $event.stopPropagation();
        }
        var teamInfo = cloneDeep(this.teamInfo);
        teamInfo.roster = teamInfo.roster || {
            allowShiftSwap: true,
            paidBreak: true,
            breaks: [60],
            break: 60,
            currency: "USD",
        };
        teamInfo.startOfWeek = teamInfo.startOfWeek || "Monday";
        teamInfo.weekDays = teamInfo.weekDays || [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
        ];
        var dialogRef = this.dialog.open(RosterSettingsComponent, {
            width: "800px",
            data: { teamInfo: teamInfo },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result.action == "yes") {
                _this.startOfWeek = moment(new Date().getTime() + _this.userInfo.user.tz_offset * 1000)
                    .day(result.data.teamInfo.startOfWeek)
                    .startOf("day");
                _this.endOfWeek = moment(_this.startOfWeek).add(7, "days");
                var day = _this.startOfWeek;
                while (day <= _this.endOfWeek) {
                    //this.daysOfTheWeek.push(day.toDate();
                    _this.daysOfTheWeek.push(day);
                    day = day.clone().add(1, "d");
                }
                _this.teamInfo = result.data.teamInfo;
                _this.options.prefix = getCurrencySymbol(_this.teamInfo.roster.currency, "narrow");
                console.log(JSON.stringify(_this.teamInfo, null, 2));
                _this.apiService.updateRosterInfo(_this.teamInfo).subscribe(function (data) {
                    //this.apiService.toast("Roster settings updated successfully.","success");
                    localStorage.setItem("teamInfo", JSON.stringify(_this.teamInfo));
                    _this.shiftTypes = _this.teamInfo.roster.positions;
                    _this.loadShifts();
                    _this.loadLeaves();
                    //window.location.reload();
                    _this.apiService.toast("Settings were updated ", "success");
                }, function (error) {
                    _this.apiService.toast("Unable to update settings ", "error");
                }, function () {
                    //console.log("api completed");
                });
            }
            else {
                console.log("dismissed");
            }
        });
    };
    RosterComponent.prototype.openShift = function ($event, slot, member, day) {
        return __awaiter(this, void 0, void 0, function () {
            var that, dialogRef;
            var _this = this;
            return __generator(this, function (_a) {
                $event.stopPropagation();
                console.log("Opening shift", slot);
                if (this.roster.rStatus === "closed") {
                    return [2 /*return*/];
                }
                if (slot.fake === true) {
                    this.createShift($event, member, day);
                    return [2 /*return*/];
                }
                that = this;
                dialogRef = this.dialog.open(RosterDialogComponent, {
                    //width: "800px",
                    data: {
                        slot: slot,
                        days: this.daysOfTheWeek,
                        member: member,
                        shiftTypes: this.shiftTypes,
                        currency: this.options.prefix,
                    },
                });
                dialogRef.afterClosed().subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
                    var slot_1, fromOffset, toOffset, diff, minutes_1, minutes, id, persisted;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(result && result.action == "yes")) return [3 /*break*/, 2];
                                console.log(result);
                                slot_1 = result.data.slot;
                                fromOffset = slot_1["start"].format("HH:mm").split(":");
                                toOffset = slot_1["end"].format("HH:mm").split(":");
                                diff = slot_1.end.diff(slot_1.start);
                                if (diff < 0) {
                                    minutes_1 = 24 * 60 + diff / 60000;
                                    slot_1["end"] = moment(slot_1["start"]).add(minutes_1, "minutes");
                                    slot_1["multiple"] = true;
                                }
                                else {
                                    slot_1["multiple"] = false;
                                    slot_1["end"] = moment(slot_1["start"]).add(diff / 60000, "minutes");
                                }
                                slot_1["fromDate"] = slot_1["start"].toDate();
                                slot_1["toDate"] = slot_1["end"].toDate();
                                minutes = moment
                                    .utc(slot_1["end"].diff(slot_1["start"], "minutes"))
                                    .valueOf();
                                minutes = Math.round(minutes / 60);
                                slot_1["estWage"] = minutes * slot_1.wageRate;
                                id = slot_1["start"].format("DDMMYYYY") + slot_1.userId;
                                if (!this.shiftMap[id]) {
                                    this.shiftMap[id] = [];
                                }
                                return [4 /*yield*/, this.apiService.updateShift(slot_1).toPromise()];
                            case 1:
                                persisted = _a.sent();
                                console.log("successfully updated shift");
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    RosterComponent.prototype.createShift = function ($event, member, day) {
        return __awaiter(this, void 0, void 0, function () {
            var mStart, mEnd, slot, that, dialogRef;
            var _this = this;
            return __generator(this, function (_a) {
                console.log("member", member);
                $event.stopPropagation();
                if (this.roster.rStatus === "closed") {
                    return [2 /*return*/];
                }
                mStart = moment(day).startOf("day").add(8, "hours");
                mEnd = moment(day).startOf("day").add(16, "hours");
                member.wageRate = member.wageRate || this.teamInfo.roster.wageRate;
                slot = {
                    userId: member.userId,
                    tz_offset: member.tz_offset,
                    start: mStart,
                    end: mEnd,
                    fromDate: mStart.toDate(),
                    toDate: mEnd.toDate(),
                    paidBreak: true,
                    breakTime: 60,
                    wageRate: member.wageRate,
                };
                that = this;
                dialogRef = this.dialog.open(RosterDialogComponent, {
                    //width: "400px",
                    data: {
                        slot: slot,
                        days: this.daysOfTheWeek,
                        member: member,
                        shiftTypes: this.shiftTypes,
                        currency: this.options.prefix,
                    },
                });
                dialogRef.afterClosed().subscribe(function (result) {
                    //console.log('The dialog was closed ', result);
                    if (result && result.action == "yes") {
                        console.log(result);
                        var slot_2 = result.data.slot;
                        // let id = slot['start'].format('DD')+slot.userId;
                        var fromOffset_1 = slot_2["start"].format("HH:mm").split(":");
                        var toOffset = slot_2["end"].format("HH:mm").split(":");
                        var diff_1 = slot_2.end.diff(slot_2.start);
                        console.log(diff_1);
                        // console.log(fromOffset,toOffset);
                        // if(!this.shiftMap[id]){
                        //   this.shiftMap[id]=[];
                        // }
                        // this.shiftMap[id].push(slot);
                        // console.log(this.shiftMap);r
                        result.repeated.forEach(function (m) { return __awaiter(_this, void 0, void 0, function () {
                            var dupSlot, minutes_2, minutes, id, persisted;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!m.checked) return [3 /*break*/, 2];
                                        dupSlot = cloneDeep(slot_2);
                                        dupSlot["id"] = "random";
                                        dupSlot["rosterId"] = this.roster.id;
                                        dupSlot["start"] = moment(m.day).set({
                                            h: fromOffset_1[0],
                                            m: fromOffset_1[1],
                                        });
                                        if (diff_1 < 0) {
                                            minutes_2 = 24 * 60 + diff_1 / 60000;
                                            dupSlot["end"] = moment(dupSlot["start"]).add(minutes_2, "minutes");
                                            dupSlot["multiple"] = true;
                                        }
                                        else {
                                            dupSlot["multiple"] = false;
                                            dupSlot["end"] = moment(dupSlot["start"]).add(diff_1 / 60000, "minutes");
                                        }
                                        dupSlot["fromDate"] = dupSlot["start"].toDate();
                                        dupSlot["toDate"] = dupSlot["end"].toDate();
                                        minutes = moment
                                            .utc(dupSlot["end"].diff(dupSlot["start"], "minutes"))
                                            .valueOf();
                                        minutes = Math.round(minutes / 60);
                                        dupSlot["estWage"] = minutes * dupSlot.wageRate;
                                        id = dupSlot["start"].format("DDMMYYYY") + dupSlot.userId;
                                        if (!this.shiftMap[id]) {
                                            this.shiftMap[id] = [];
                                        }
                                        return [4 /*yield*/, this.apiService
                                                .createShift(dupSlot)
                                                .toPromise()];
                                    case 1:
                                        persisted = _a.sent();
                                        dupSlot["id"] = persisted["id"];
                                        this.shiftMap[id].push(dupSlot);
                                        this.shifts.push(dupSlot);
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    RosterComponent.prototype.publish = function () {
        return __awaiter(this, void 0, void 0, function () {
            var roster, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        roster = {
                            rosterId: this.roster.id,
                            rStatus: "published",
                            users: this.botUsers.map(function (m) {
                                return m.userId;
                            }),
                            fromDate: this.startOfWeek.format("ddd Do MMM"),
                            toDate: this.endOfWeek.format("ddd Do MMM"),
                        };
                        roster["offices"] = this.roster.offices || [];
                        if (!roster["offices"].includes(this.officeId)) {
                            roster["offices"].push(this.officeId);
                        }
                        this.disablePublish = true;
                        return [4 /*yield*/, this.apiService.publishRoster(roster).toPromise()];
                    case 1:
                        result = _a.sent();
                        this.apiService.toast("Roster published", "success");
                        this.roster.rStatus = roster.rStatus;
                        this.disablePublish = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    RosterComponent.prototype.release = function () {
        return __awaiter(this, void 0, void 0, function () {
            var roster, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        roster = {
                            rosterId: this.roster.id,
                            rStatus: "closed",
                        };
                        this.disablePublish = true;
                        return [4 /*yield*/, this.apiService.releaseRoster(roster).toPromise()];
                    case 1:
                        result = _a.sent();
                        this.disablePublish = false;
                        this.apiService.toast("Roster is marked as closed. You cannot make any changes.", "success");
                        this.roster.rStatus = roster.rStatus;
                        return [2 /*return*/];
                }
            });
        });
    };
    return RosterComponent;
}());
export { RosterComponent };
