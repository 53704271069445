import { environment } from "../environments/environment";
//import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as moment from "moment-timezone";
import { adapterFactory } from "angular-calendar/date-adapters/moment";
import { Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { SecuredComponent } from "./secured/secured.component";
import { DashboardComponent } from "./secured/dashboard/dashboard.component";
import { SafeGuardService } from "./shared/safegurad.service";
import { EventsComponent } from "./secured/events/events.component";
import { TeamEventsComponent } from "./secured/team-events/team-events.component";
import { SubscriptionComponent } from "./secured/subscription/subscription.component";
import { LandingComponent } from "./landing/landing.component";
import { PricingComponent } from "./pricing/pricing.component";
import { SettingsComponent } from "./secured/settings/settings.component";
import { OfficesComponent } from "./secured/offices/offices.component";
import { DemosComponent } from "./demos/demos.component";
import { RosterComponent } from "./secured/roster/roster.component";
import { ShiftComponent } from "./shift/shift.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { NgcCookieConsentConfig } from "ngx-cookieconsent";
import { DepartmentsComponent } from "./secured/departments/departments.component";
import { OverlayContainer } from "@angular/cdk/overlay";
import { LegalComponent } from "./legal/legal.component";
import { SignupComponent } from "./signup/signup.component";
import { SubsciptionResolver } from "./shared/subscription.resolver";
import { HolidaysComponent } from "./secured/holidays/holidays.component";
var appRoutes = [
    { path: "", component: LandingComponent },
    { path: "pricing", component: PricingComponent },
    { path: "privacy", component: PrivacyComponent },
    { path: "legal", component: LegalComponent },
    { path: "demos", component: DemosComponent },
    { path: "login", component: LoginComponent },
    { path: "signup", component: SignupComponent },
    { path: "shift", component: ShiftComponent },
    {
        path: "secured",
        component: SecuredComponent,
        canActivate: [SafeGuardService],
        resolve: {
            subscription: SubsciptionResolver,
        },
        children: [
            { path: "", component: DashboardComponent },
            { path: "dashboard", component: DashboardComponent },
            // {path: 'scheduler', component: SchedulerComponent},
            { path: "events", component: EventsComponent },
            { path: "team-events", component: TeamEventsComponent },
            { path: "subscription", component: SubscriptionComponent },
            { path: "settings", component: SettingsComponent },
            { path: "notifications", component: OfficesComponent },
            { path: "roster", component: RosterComponent },
            { path: "departments", component: DepartmentsComponent },
            { path: "holidays", component: HolidaysComponent },
        ],
    },
];
var cookieConfig = {
    cookie: {
        domain: environment.cookieDomain,
    },
    position: "bottom-left",
    theme: "classic",
    palette: {},
    type: "info",
    content: {
        message: "Ready to let the digital cookies 🍪 work their magic? By clicking 'Dig In', you're agreeing to sprinkle some tech sweetness into your browsing experience!",
        dismiss: "Dig In!",
        deny: "Refuse cookies",
        link: "Learn more",
        href: "https://officebot.info#cookie-policy",
        policy: "Cookie Policy",
    },
};
export function momentAdapterFactory() {
    return adapterFactory(moment);
}
var ɵ0 = moment;
var AppModule = /** @class */ (function () {
    function AppModule(overlayContainer) {
        //overlayContainer.getContainerElement().classList.add("my-dark-theme");
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
