import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../shared/auth.service";
import { ApiService } from "../../shared/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { EventEmitterService } from "src/app/shared/event-emitter.service";
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from "chart.js";
import { LeaveColorMap, hasShiftAccess, stringToColour } from "src/app/shared/utility";
import * as moment from "moment-timezone";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  subsciption: any;
  private members: any[] = [];
  private memberMap: any = {};
  public events: any[] = [];
  private dashByMonths = {};
  public teamInfo: any;
  public userInfo: any = {};
  public loadedProfileMap: boolean = false;
  public loadedEvents: boolean = false;
  public loadedShifts: boolean = false;

  public doughnutChartLabels: string[] = ["Used", "Remaining"];

  public doughnutChartType: string = "doughnut";
  public doughnutColors: string[] = ["red"];

  public doughnutDataset: any[] = [
    {
      data: [40, 60],
      backgroundColor: ["blue", "lightgrey"],
      hoverOffset: 4,
    },
    {
      data: [30, 70],
      backgroundColor: ["yellow", "lightgrey"],
      hoverOffset: 4,
    },
  ];

  // Pie
  public pieChartOptions: ChartConfiguration["options"] = {
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      datalabels: {
        formatter: (value, ctx) => {
          if (ctx.chart.data.labels) {
            return ctx.chart.data.labels[ctx.dataIndex];
          }
          return "";
        },
      },
    },
  };
  public pieChartData = {
    labels: [],
    datasets: [],
  };

  public barChartOptions: ChartConfiguration["options"] = {
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
            drawOnChartArea: false,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };
  public barChartLabels: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  public colorMap: any = LeaveColorMap;
  public barChartType: string = "bar";
  public barChartLegend: boolean = false;

  public barChartData: any[] = [];
  public loadingChart: boolean = true;
  public barColors: string[] = ["red", "green", "blue"];

  public startOfWeek: any;
  public endOfWeek: any;
  roster: any;
  planId: string = "BRONZE";
  planCode: string = "NONE";
  workingHours: number = 0;
  paidBreak: number = 0;
  unpaidBreak: number = 0;
  shiftType2HourMap = {};
  yourTotalPrevEvents = 0;
  loadingHolidays: boolean;
  holidays: any;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private apiService: ApiService,
    public authService: AuthService,
    private _eventEmiter: EventEmitterService
  ) {}

  async ngOnDestroy() {}

  async ngOnInit() {
    this.subsciption = this.route.parent.snapshot.data["subscription"];
    console.log("this.subsciption", this.subsciption);
    if (this.subsciption.planCode) {
      this.planCode = this.subsciption.planCode.toUpperCase();
      this.planId = this.subsciption.planCode.toUpperCase();
      localStorage.setItem("planCode", this.planCode);
      localStorage.setItem("planId", this.planCode);
    }

    if (this.planCode == "NONE") {
      this.router.navigate(["/secured/subscription"]);
      return;
    }

    console.log("1. dashboard ng init called");
    this._eventEmiter.emitter.subscribe(async (data) => {
      if (data == "profileloaded") {
        await this.loadDash();
      }
    });

    if (localStorage.getItem("profileloaded")) {
      console.log("dashloadedonce");

      this.loadDash();
    }
  }

  hasShiftAccess(): boolean {
    //console.log("evaluting plancode", this.planCode);
    return hasShiftAccess(this.planCode);
  }

  async loadDash() {
    let that = this;

    if (localStorage.getItem("members")) {
      this.members = JSON.parse(localStorage.getItem("members"));
      this.members.forEach((item) => {
        this.memberMap[item.id] = item;
        console.log(item);
      });
    } else {
      console.log("$$$ Member maps not in local storage");
    }

    await this.loadTeamEvents();
    this.loadedProfileMap = true;

    console.log("3. teamInfo");
    if (localStorage.getItem("teamInfo")) {
      this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
      this.barChartData = [];
      const leaveTypes = this.apiService.getTeamEventTypes(this.teamInfo);
      for (let i = 0; i < leaveTypes.length; i++) {
        this.barChartData.push({
          backgroundColor: "red",
          label: leaveTypes[i],
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        });
      }

      console.log("loading chart");
      let graphData = await this.apiService.getDashByMonths().toPromise();
      //console.log(this.barChartData);

      let now = new Date();
      let currentMonth = now.getMonth() + 1;
      let prev3month = currentMonth < 3 ? currentMonth + 12 - 3 : currentMonth - 3;
      console.log("3 months", prev3month, currentMonth);
      let slicer = [prev3month, currentMonth];
      this.barChartLabels = graphData.months;
      this.barChartLabels = this.barChartLabels.slice(slicer[0], slicer[1]);
      graphData.data.forEach((item) => {
        item.data = item.data.slice(slicer[0], slicer[1]);

        var indx = this.barChartData
          .map((e) => {
            return e.label;
          })
          .indexOf(item.label);

        const used = item.data.reduce((total, num) => total + num, 0);
        this.yourTotalPrevEvents += used;
        console.log("item", item.label, used);
        // item.label =
        //   item.label +
        //   "(" +
        //   item.data.reduce((total, num) => total + num, 0) +
        //   ")";
        //console.log("color", item.label, this.colorMap[item.label]);
        item.backgroundColor = this.stringToColour(item.label); //this.colorMap[item.label];
        this.barChartData[indx] = item;
      });
      this.loadingChart = false;
      // console.log("bar chart data ", this.barChartData);
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      console.log("could not load chart data");
    }

    await this.loadHolidays();
    if (this.hasShiftAccess()) {
      await this.loadShiftInfo();
    }
  }

  stringToColour = function (str) {
    return stringToColour(str);
  };

  private async loadHolidays() {
    this.loadingHolidays = false;
    this.holidays = await this.apiService.getTeamHolidays().toPromise();
    this.loadingHolidays = true;
  }
  private async loadShiftInfo() {
    let nowTime = moment(new Date().getTime() + this.userInfo.user.tz_offset * 1000);
    let strWeekDay = this.teamInfo.startOfWeek || "Monday";

    this.startOfWeek = moment(new Date().getTime() + this.userInfo.user.tz_offset * 1000)
      .day(strWeekDay)
      .startOf("day");

    if (nowTime.valueOf() < this.startOfWeek.valueOf()) {
      this.startOfWeek = this.startOfWeek.subtract(7, "days");
      this.endOfWeek = moment(this.startOfWeek).add(6, "days");
    } else {
      this.endOfWeek = moment(this.startOfWeek).add(6, "days");
    }

    console.log("start of week", this.startOfWeek);

    let data = await this.apiService
      .getRoster(this.startOfWeek.format("YYYY-MM-DD"), this.endOfWeek.format("YYYY-MM-DD"), "view")
      .toPromise();
    if (data.roster && ["published"].includes(data.roster.rStatus) && data.shifts) {
      data.shifts.forEach((element) => {
        element["fromDate"] = moment(element.fromDate)
          .add(-1 * element.tz_offset, "seconds")
          .toDate();

        element["toDate"] = moment(element.toDate)
          .add(-1 * element.tz_offset, "seconds")
          .toDate();

        element["duration"] = Math.round((element["toDate"] - element["fromDate"]) / 3600000);

        console.log("element.duration", element.duration);
        if (element.userId === this.userInfo.user.id) {
          console.log("adding working hour for ", element.userId);
          this.workingHours += element.duration;
        }

        this.shiftType2HourMap[element.shiftType] = (this.shiftType2HourMap[element.shiftType] || 0) + element.duration;
      });

      this.roster = data;
      const shiftTypes = Object.keys(this.shiftType2HourMap);
      const pieData = [];
      const pieDataBgColor = [];
      this.pieChartData.labels = shiftTypes;
      shiftTypes.forEach((shiftType) => {
        pieData.push(this.shiftType2HourMap[shiftType]);
        pieDataBgColor.push(this.stringToColour(shiftType));
      });

      this.pieChartData.datasets.push({
        data: pieData,
        backgroundColor: pieDataBgColor,
      });
      console.log("pieChartData", this.pieChartData);
      console.log("roster data", data);
      console.log("workingHours", this.workingHours);
      console.log("shiftType2HourMap", this.shiftType2HourMap);
      //populate shift distribution pie chart
      // this.pieChartData = {
      //   labels: ["Used", "Remaining"],
      //   datasets: [
      //     {
      //       data: [
      //         this.workingHours,
      //         this.userInfo.user.shiftLimit - this.workingHours,
      //       ],
      //       backgroundColor: ["red", "lightgrey"],
      //     },
      //   ],
      // };
    }
  }
  private async loadTeamEvents() {
    this.events = [];
    //const from = moment().startOf("month").format("YYYY-MM-DD");
    const from = moment().format("YYYY-MM-DD");
    const to = moment().add(15, "days").format("YYYY-MM-DD");
    let data = await this.apiService.getTeamEventsRange(from, to).toPromise();
    console.log("team events", data);
    this.events = data.map((element) => {
      let event = {
        id: element.id,
        userId: element.userId,
        start: this.apiService.createDateAsUTC(new Date(element.fromDate)),
        end: this.apiService.createDateAsUTC(new Date(element.toDate)),
        title: element.type,
        duration: element.duration,
      };

      if (this.memberMap[element.userId]) {
        if (
          this.memberMap[element.userId].image_48 === undefined ||
          this.memberMap[element.userId].image_48 === null ||
          this.memberMap[element.userId].image_48 === "null"
        ) {
          event["image_48"] = "assets/favicon.png";
        } else {
          event["image_48"] = this.memberMap[element.userId].image_48;
        }
        console.log("image", event["image_48"]);
      } else {
        event["image_48"] = "assets/favicon.png";
      }
      return event;
    });

    this.events.sort(function (a: any, b: any) {
      return a.start - b.start;
    });
    this.events = this.events.slice(0, 10);
    this.loadedEvents = true;
    console.log("events loaded", this.memberMap);
  }

  getAvatarColor(name: string): string {
    // Simple hash function
    const hash = name.split("").reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);

    // Convert hash to RGB values
    const r = (hash & 0xff0000) >> 16;
    const g = (hash & 0x00ff00) >> 8;
    const b = hash & 0x0000ff;

    // Ensure the color is not too light (for visibility on white background)
    const minBrightness = 50;
    const adjustedR = Math.max(r, minBrightness);
    const adjustedG = Math.max(g, minBrightness);
    const adjustedB = Math.max(b, minBrightness);

    // Convert to hex and return
    return `#${((adjustedR << 16) | (adjustedG << 8) | adjustedB).toString(16).padStart(6, "0")}`;
  }
  getInitial(name: string): string {
    return name.charAt(0).toUpperCase();
  }
}
