<mat-progress-bar
  *ngIf="authService.getProgressIndicator()"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>

<mat-card>
  <h4 class="header mat-subheading-2">Manage Offices</h4>

  <p class="text-primary" *ngIf="planId !== 'gold'">
    Upgrade your plan to manage office and department
  </p>

  <p style="color: gray" class="text-primary" *ngIf="offices.length == 0">
    You have not created any office yet. Let's create your first Office location
    below.
  </p>

  <div fxLayout="row" fxLayoutWrap fxLayoutAlign="center">
    <div fxFlex="70%">
      <mat-form-field appearance="outline" color="accent">
        <input
          matInput
          type="text"
          [(ngModel)]="officename"
          placeholder="Enter Office Name"
        />
        <button
          style="margin-bottom: 25px"
          color="primary"
          mat-button
          matSuffix
          mat-raised-button
          aria-label="search"
          (click)="create(officename, 'office')"
          [disabled]="!officename || planId !== 'gold'"
        >
          + Add New Office
        </button>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row">
    <table class="office-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Timezone</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let m of offices; let indx = index">
          <td>
            <div class="cirle">
              {{ getInitial(m.officeName) }}
            </div>
            <span *ngIf="!m.editing">{{ m.officeName }}</span>
            <mat-form-field *ngIf="m.editing" appearance="outline" fxFlex="80%">
              <mat-label>Office Name</mat-label>
              <input
                matInput
                placeholder="Office Name"
                [(ngModel)]="m.officeName"
              />
            </mat-form-field>
          </td>
          <td>
            <span *ngIf="!m.editing">{{ m.tz_label }}</span>
            <mat-form-field *ngIf="m.editing" appearance="outline">
              <mat-label>Timezone</mat-label>
              <mat-select placeholder="Timezone" [(ngModel)]="m.tz_label">
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Filter..."
                    noEntriesFoundLabel="No result"
                    [(ngModel)]="m.tz_filter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let tz of getTimezones(m.tz_filter)"
                  [value]="tz.name"
                >
                  {{ tz.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- <mat-form-field *ngIf="m.editing"  >
                     
                      <input type="text" placeholder="Pick one" aria-label="Number" matInput [(ngModel)]="m.tz_offset" [matAutocomplete]="auto">
                      <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor="let tz of timezones" [value]="tz.offset">
                              {{tz.name}}
                          </mat-option>
                      </mat-autocomplete>
                  </mat-form-field> -->
          </td>
          <td>
            <div class="buttons">
              <button
                *ngIf="!m.editing"
                mat-icon-button
                color="accent"
                (click)="m.editing = true"
              >
                <mat-icon aria-label="Example icon-button with a heart icon"
                  >edit</mat-icon
                >
              </button>
              <button mat-icon-button color="warn" (click)="delete(m, indx)">
                <mat-icon aria-label="Example icon-button with a heart icon"
                  >delete</mat-icon
                >
              </button>
              <button
                *ngIf="m.editing"
                mat-icon-button
                color="accent"
                (click)="update(m)"
              >
                <mat-icon aria-label="Example icon-button with a heart icon"
                  >save</mat-icon
                >
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-card>

<mat-card>
  <h4 class="header mat-subheading-2">Manage Departments</h4>
  <p style="color: gray" *ngIf="departments.length == 0">
    You have not created any department yet. Let's create your first department
    such as HR, IT, Engineering
  </p>
  <div fxLayout="row" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
    <div fxFlex="70%">
      <mat-form-field appearance="outline" color="accent">
        <input
          matInput
          type="text"
          [(ngModel)]="departmentname"
          placeholder="Enter Department Name"
        />
        <button
          style="margin-bottom: 25px"
          color="primary"
          mat-button
          matSuffix
          mat-raised-button
          aria-label="search"
          (click)="create(departmentname, 'department')"
          [disabled]="!departmentname || planId !== 'gold'"
        >
          + Add New Department
        </button>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row">
    <table class="office-table">
      <thead>
        <tr>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let m of departments; let indx = index">
          <td>
            <div class="cirle">
              {{ getInitial(m.officeName) }}
            </div>
            <span *ngIf="!m.editing">{{ m.officeName }}</span>
            <mat-form-field *ngIf="m.editing" appearance="outline" fxFlex="80%">
              <mat-label>Office Name</mat-label>
              <input
                matInput
                placeholder="Office Name"
                [(ngModel)]="m.officeName"
                (blur)="update(m)"
              />
            </mat-form-field>
          </td>
          <td>
            <div class="buttons">
              <button mat-icon-button color="accent" (click)="m.editing = true">
                <mat-icon aria-label="Example icon-button with a heart icon"
                  >edit</mat-icon
                >
              </button>
              <button mat-icon-button color="warn" (click)="delete(m, indx)">
                <mat-icon aria-label="Example icon-button with a heart icon"
                  >delete</mat-icon
                >
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-card>
