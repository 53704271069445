import { Component, OnInit } from "@angular/core";
import * as moment from "moment-timezone";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/shared/api.service";
import { AuthService } from "src/app/shared/auth.service";
import { CalendarMomentDateFormatter } from "angular-calendar";
import { MatDialog } from "@angular/material";
import * as cloneDeep from "lodash/cloneDeep";
import { RosterDialogComponent } from "src/app/shared/roster-dialog/roster-dialog.component";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { count } from "rxjs/operators";
import { RosterSettingsComponent } from "src/app/shared/roster-settings/roster-settings.component";
import { async } from "q";
import { getCurrencySymbol } from "@angular/common";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  LeaveColorMap,
  hasShiftAccess,
  stringToColour,
} from "src/app/shared/utility";

@Component({
  selector: "app-roster",
  templateUrl: "./roster.component.html",
  styleUrls: ["./roster.component.scss"],
})
export class RosterComponent implements OnInit {
  daysOfTheWeek: any[] = [];
  members: any[] = [];
  membersMap: any = {};
  offices: any[];
  startOfWeek: any;
  endOfWeek: any;
  officeId: string;
  rosterSettings: any = {};
  loading: boolean = true;
  shifts = [];
  connectedTo = [];
  shiftMap: any = {};
  counter: number = 0;
  teamInfo: any = {};
  userInfo: any = {};
  shiftTypes: string[] = ["Register", "Delivery", "Kitchen"];
  dropListData = [];
  leaveMap: any = {};
  botUsers: any[] = [];
  allBotUsers: any[] = [];
  leaves = [];
  options = { prefix: "$" };
  roster: any;
  extraPublishStatus: string;
  officeMap: any = {};
  buttonText: string = "Publish";
  statuses: string[] = ["active", "published", "adjustment", "closed"];
  planId: string = "BRONZE";
  planCode: string = "NONE";
  public colorMap: any = LeaveColorMap;
  holidays: any;
  holidayMap: any = {};

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public apiService: ApiService,
    public authService: AuthService,
    public dialog: MatDialog
  ) {}

  refreshByOffice($event) {
    console.log("selected office Id", this.officeId);
    if (this.officeId !== "-1") {
      this.botUsers = this.allBotUsers.filter((m) => {
        return m.officeId === this.officeId;
      });
    } else {
      this.botUsers = this.allBotUsers;
    }
    console.log("roster", this.roster);
    let selected = this.roster.offices.filter((m) => {
      return m === this.officeId;
    });
    console.log("selected", selected);
    if (selected.length > 0) {
      this.buttonText = "Re-Publish";
    } else {
      this.buttonText = "Publish";
    }
  }
  async ngOnInit() {
    if (localStorage.getItem("planId")) {
      this.planId = localStorage.getItem("planId").toUpperCase();
    }
    if (localStorage.getItem("planCode")) {
      this.planCode = localStorage.getItem("planCode").toUpperCase();
    }
    if (localStorage.getItem("teamInfo")) {
      this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
      if (this.teamInfo.roster) {
        this.options.prefix = getCurrencySymbol(
          this.teamInfo.roster.currency,
          "narrow"
        );
        this.shiftTypes = this.teamInfo.roster.positions;
      }
    }

    this.botUsers = await this.apiService.getAllBotUsers().toPromise();
    this.botUsers = this.botUsers.filter((m) => {
      return m.inActive !== true;
    });

    console.log("$$$ Bot users", this.botUsers);

    this.members = JSON.parse(localStorage.getItem("members"));

    this.members.map((m) => {
      this.membersMap[m.id] = m;
    });
    console.log("membersMap", this.membersMap);
    this.botUsers.map((m) => {
      console.log(this.membersMap[m.userId]);
      m.image_48 = this.membersMap[m.userId].image_48;
      //override display name from the officebot database
      //this.membersMap[m.userId].displayName = m.displayName;
      if (this.teamInfo.roster) {
        m.wageRate = m.wageRate || this.teamInfo.roster.wageRate;
      }
    });
    this.allBotUsers = this.botUsers;

    //this.startOfWeek = moment().startOf('isoWeek');

    let strWeekDay = this.teamInfo.startOfWeek || "Monday";

    console.log("teamInfo", this.teamInfo);

    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let nowTime = moment(
      new Date().getTime() + this.userInfo.user.tz_offset * 1000
    );
    this.startOfWeek = moment(
      new Date().getTime() + this.userInfo.user.tz_offset * 1000
    )
      .day(strWeekDay)
      .startOf("day");
    if (nowTime.valueOf() < this.startOfWeek.valueOf()) {
      this.startOfWeek = this.startOfWeek.subtract(7, "days");
      this.endOfWeek = moment(this.startOfWeek).add(6, "days");
    } else {
      this.endOfWeek = moment(this.startOfWeek).add(6, "days");
    }
    console.log("$$$ this.startOfWeek", this.startOfWeek);

    var day = this.startOfWeek;

    while (day <= this.endOfWeek) {
      //this.daysOfTheWeek.push(day.toDate();
      this.daysOfTheWeek.push(day);
      day = day.clone().add(1, "d");
    }

    console.log(this.daysOfTheWeek);
    let allOffices = await this.apiService.getAllOffices().toPromise();
    this.offices = allOffices.filter((m) => {
      return m.type === "office";
    });
    this.offices.unshift({ id: "-1", officeName: "All" });
    if (this.offices.length > 0) {
      this.officeId = this.offices[0].id;
    }
    this.offices.map((m) => {
      this.officeMap[m.id] = m.officeName;
    });

    this.loadShifts();
    this.loadLeaves();
    this.loadHolidays();
  }

  async updateWage(member: any) {
    //this.apiService.toast("updating wage","success");
    delete member.editing;
    this.apiService.updateUserApprovers([member]).toPromise();
    console.log("updated wage");
  }
  getRole(): string {
    let role = "member";
    if (!this.teamInfo || !this.userInfo || !this.userInfo.user) {
      return role;
    }
    var appRoles = this.teamInfo["appRoles"];

    if (appRoles) {
      for (var key in appRoles) {
        if (
          appRoles[key].indexOf(this.userInfo.user.id) != -1 &&
          role !== "admin"
        ) {
          role = key;
        }
      }
    } else if (this.teamInfo.userId == this.userInfo.user.id) {
      role = "admin";
    }
    return role;
  }

  hasShiftAccess(): boolean {
    return hasShiftAccess(this.planCode);
  }

  async loadShifts() {
    this.buildConnector();

    let data = await this.apiService
      .getRoster(
        this.startOfWeek.format("YYYY-MM-DD"),
        this.endOfWeek.format("YYYY-MM-DD")
      )
      .toPromise();
    console.log(data);
    this.roster = data.roster;
    if (data.roster.rStatus === "published") {
      this.extraPublishStatus =
        "This roster has been published for following offices: ";
      data.roster.offices.forEach((m) => {
        this.extraPublishStatus += this.officeMap[m];
      });

      let selected = this.roster.offices.filter((m) => {
        return m === this.officeId;
      });
      if (selected.length > 0) {
        this.buttonText = "Re-Publish";
      } else {
        this.buttonText = "Publish";
      }
    }

    this.shifts = data.shifts;

    this.shifts.forEach((element) => {
      element["start"] = moment(element.fromDate).add(
        -1 * element.tz_offset,
        "seconds"
      );
      element["end"] = moment(element.toDate).add(
        -1 * element.tz_offset,
        "seconds"
      );
      element["fromDate"] = moment(element.fromDate)
        .add(-1 * element.tz_offset, "seconds")
        .toDate();
      element["toDate"] = moment(element.toDate)
        .add(-1 * element.tz_offset, "seconds")
        .toDate();
      if (element["start"].format("DD") === element["end"].format("DD")) {
        element["multiple"] = false;
      } else {
        element["multiple"] = true;
      }
      let id = element["start"].format("DDMMYYYY") + element.userId;
      // if(!this.shiftMap[id]){
      //   this.shiftMap[id]=[];
      // }
      this.shiftMap[id].push(element);
    });
    console.log("shifts", this.shiftMap);

    this.loading = false;
  }

  buildConnector() {
    this.members.forEach((member: any) => {
      this.daysOfTheWeek.forEach((day: any) => {
        let id = day.format("DDMMYYYY") + member.id;
        this.shiftMap[id] = [{ fake: true, shiftType: "fake" }];

        if (!this.connectedTo.includes(id)) {
          this.connectedTo.push(id);
        }
      });
    });
  }

  async loadHolidays() {
    this.holidays = await this.apiService
      .getTeamHolidaysWithRange(
        this.startOfWeek.format("YYYY-MM-DD"),
        this.endOfWeek.format("YYYY-MM-DD")
      )
      .toPromise();
    this.holidays.forEach((element) => {
      element["fromDate"] = moment(element.fromDate).add(
        -1 * element["tz_offset"],
        "seconds"
      );
      element["toDate"] = moment(element.toDate).add(
        -1 * element["tz_offset"],
        "seconds"
      );

      let id = moment(element["fromDate"]).format("DD");
      if (!this.holidayMap[id]) {
        this.holidayMap[id] = [];
      }
      this.holidayMap[id].push(element);
    });
  }

  async loadLeaves() {
    console.log("leaves", this.leaveMap);
    this.leaves = await this.apiService
      .getTeamEventsRange(
        this.startOfWeek.format("YYYY-MM-DD"),
        this.endOfWeek.format("YYYY-MM-DD")
      )
      .toPromise();
    this.leaves.forEach((element) => {
      element["fromDate"] = moment(element.fromDate).add(
        -1 * element["tz_offset"],
        "seconds"
      );
      element["toDate"] = moment(element.toDate).add(
        -1 * element["tz_offset"],
        "seconds"
      );

      let id = moment(element["fromDate"]).format("DD") + element.userId;
      if (!this.leaveMap[id]) {
        this.leaveMap[id] = [];
      }
      this.leaveMap[id].push(element);
    });
  }

  prev(): void {
    if (this.counter === -3) {
      this.apiService.toast("You can only go back 4 weeks.", "error");
      return;
    }
    this.counter--;
    this.startOfWeek = this.startOfWeek.subtract(7, "days");
    this.endOfWeek = moment(this.startOfWeek).add(6, "days");
    var day = this.startOfWeek;
    this.daysOfTheWeek = [];
    while (day <= this.endOfWeek) {
      //this.daysOfTheWeek.push(day.toDate();
      this.daysOfTheWeek.push(day);
      day = day.clone().add(1, "d");
    }
    this.loadShifts();
  }

  next(): void {
    if (this.counter === 4) {
      this.apiService.toast("You can only plan 4 weeks in advance.", "error");
      return;
    }
    this.counter++;
    this.startOfWeek = this.startOfWeek.add(7, "days");
    this.endOfWeek = moment(this.startOfWeek).add(6, "days");
    var day = this.startOfWeek;
    this.daysOfTheWeek = [];
    while (day <= this.endOfWeek) {
      //this.daysOfTheWeek.push(day.toDate();
      this.daysOfTheWeek.push(day);
      day = day.clone().add(1, "d");
    }
    this.loadShifts();
  }

  getShiftCellId(userId: string, mDate: any): any {
    let id = mDate.format("DDMMYYYY") + userId;

    return id;
  }

  getConnector() {
    return this.connectedTo;
  }
  getShiftCell(userId: string, mDate: any): any {
    let cells = this.shiftMap[this.getShiftCellId(userId, mDate)];
    //console.log(" cell array",cells);
    return cells;
  }

  getLeaveCell(userId: string, mDate: any): any {
    let id = mDate.format("DD") + userId;
    return this.leaveMap[id];
  }

  getHolidayCell(mDate: any): any {
    let id = mDate.format("DD");
    return this.holidayMap[id];
  }

  clickedOnCell($event: any, text: string) {
    $event.stopPropagation();
    this.apiService.toast(text, "success");
  }

  dragging($event) {
    console.log("dragging", $event);
    //$event.stopPropagation();
  }
  async drop(event: CdkDragDrop<any[]>) {
    //console.log("dropped",event);
    //$event.stopPropagation();
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      //console.log('transfer array item');
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      let slot = event.container.data[event.currentIndex];
      console.log("before slot", slot);
      let id = event.container.id;
      let day = id.substring(0, 2);
      let month = parseInt(id.substr(2, 2)) - 1;
      let year = id.substr(4, 4);
      let userId = id.substring(8);
      let botUser = this.botUsers.filter((m) => {
        return m.userId === userId;
      })[0];
      slot["wageRate"] = botUser.wageRate;
      slot["start"] = slot["start"].set({
        date: day,
        month: month,
        year: year,
      });
      slot["end"] = slot["end"].set({ date: day, month: month, year: year });
      slot["fromDate"] = slot["start"].toDate();
      slot["toDate"] = slot["end"].toDate();
      slot["userId"] = userId;
      var minutes = moment
        .utc(slot["end"].diff(slot["start"], "minutes"))
        .valueOf();
      minutes = Math.round(minutes / 60);
      slot["estWage"] = minutes * slot.wageRate;
      //console.log("ID and Data",id, day ,month,year,userId);
      //console.log('after slot', slot);
      let persisted = await this.apiService.updateShift(slot).toPromise();
      console.log("shift updated");
    }
  }
  getDiff(slot: any) {
    return moment.utc(slot.end.diff(slot.start)).format("HH:mm");
  }

  async delete($event: any, slot: any, member: any, mDate: any, index: number) {
    $event.stopPropagation();
    if (this.roster.rStatus === "closed") {
      return;
    }
    let arr = this.getShiftCell(member.userId, mDate);
    let shiftIndex = this.shifts.findIndex((m) => m.id === slot.id);
    if (slot.id) {
      await this.apiService.deleteOneShift(slot.id).toPromise();
    }
    if (shiftIndex !== -1) {
      this.shifts.splice(shiftIndex, 1);
    }
    arr.splice(index, 1);
  }

  getTotalHour(member: any): number {
    let filtered = this.shifts.filter((m) => {
      return m.userId === member.userId;
    });
    let minutes = 0;
    filtered.forEach((m) => {
      if (m["end"] && m["start"]) {
        minutes += moment.utc(m["end"].diff(m["start"], "minutes")).valueOf();
      }
    });
    return Math.ceil(minutes / 60);
  }

  getTotalWage(member: any): number {
    let filtered = this.shifts.filter((m) => {
      return m.userId === member.userId;
    });
    let total = 0;
    filtered.forEach((m) => {
      if (m["end"] && m["start"]) {
        let minutes = moment
          .utc(m["end"].diff(m["start"], "minutes"))
          .valueOf();
        minutes = Math.round(minutes / 60);
        total += minutes * m.wageRate;
      }
    });
    return total;
  }

  // getEvent(userId:string, mDate:moment):any{
  //   let event;
  //   //console.log('target moment', mDate.format('LL'));
  //   //if(userId ==='U5HKU75R7' && mDate.format('LL')=='April 17, 2019'){
  //     let filtered = this.shifts.filter((m)=>{
  //       //console.log(m['start'].valueOf(),mDate.startOf('day').valueOf());
  //       //console.log(m['start'].format('LL'), mDate.startOf('day').format('LL'));
  //       return m.userId == userId && m['start'].valueOf()>= mDate.startOf('day').valueOf()
  //       && m['end'].valueOf()<= mDate.endOf('day').valueOf();
  //     });

  //     if(filtered.length>0){
  //       event = filtered[0];
  //       //console.log('event moment', event['start'].format('LL'));

  //     }
  //   //}

  //   return event;
  // }

  stringToColour = function (str) {
    return stringToColour(str);
  };

  async downloadAsExcel($event: any) {
    this.apiService.toast("This feature is under development", "warning");
  }

  async downloadAsPDF($event: any) {
    let that = this;
    //this.apiService.toast("This feature is under development","warning");
    var totalHours = 0;
    var totalCost = 0;
    var currenySymbol = getCurrencySymbol(
      this.teamInfo.roster.currency,
      "narrow"
    );
    var item = {
      Name: "XYZ",
      Age: "22",
      Gender: "Male",
    };
    var doc = new jsPDF();
    var col = ["Details", "Values"];
    var rows = [];

    for (var key in item) {
      var temp = [key, item[key]];
      rows.push(temp);
    }

    doc.setFontSize(14);
    doc.text("Employee Cost Summary", 15, 10);
    doc.line(15, 12, 80, 12);
    doc.setFontSize(10);
    doc.text(
      `Week Of ${this.startOfWeek.format("LL")} to ${this.endOfWeek.format(
        "LL"
      )}`,
      15,
      20
    );
    //generate report data
    let data = [];
    //console.log(this.officeMap);
    this.botUsers.forEach((m) => {
      let row = [];
      //console.log(m);

      row.push(this.membersMap[m.userId].name);
      row.push(this.officeMap[m.officeId]);
      var employeeHours = this.getTotalHour(m);
      row.push(employeeHours);
      totalHours += employeeHours;
      var employeeWage = this.getTotalWage(m);
      totalCost += employeeWage;
      row.push(currenySymbol + " " + employeeWage);
      data.push(row);
    });

    doc.text(`Total Hours: ${totalHours}`, 15, 25);
    doc.text(`Total Cost: ${currenySymbol} ${totalCost}`, 15, 30);
    autoTable(doc, {
      theme: "grid",
      margin: { top: 50 },
      head: [["Name", "Office", "Total Hour", "Total Cost"]],
      body: data,
    });
    doc.save(
      `employee_cost_summary_${this.startOfWeek.format("YYYYMMDD")}.pdf`
    );

    //generate detail report
    var detailDoc = new jsPDF();
    detailDoc.setFontSize(14);
    detailDoc.text("Employee Cost Details", 15, 10);
    detailDoc.line(15, 12, 80, 12);
    detailDoc.setFontSize(10);
    detailDoc.text(
      `Week Of ${this.startOfWeek.format("LL")} to ${this.endOfWeek.format(
        "LL"
      )}`,
      15,
      20
    );
    var detailHeader = ["Name"];

    this.daysOfTheWeek.forEach((m) => {
      detailHeader.push(`${m.format("MMM Do")}`);
    });
    detailHeader.push("Total Hour");
    detailHeader.push("Total Cost");

    let detailData = [];
    this.botUsers.forEach((m) => {
      let row = [];
      //console.log(m);

      row.push(this.membersMap[m.userId].name);

      this.daysOfTheWeek.forEach((event) => {
        console.log("DAY", `${event.format("MMM Do")}`, m.userId);
        var cellDate = "";
        that.getShiftCell(m.userId, event).forEach((slot) => {
          console.log(`${event.format("MMM Do")}`, m.userId, "slot", slot);

          if (slot.fake !== true) {
            var diff = moment.utc(slot.end.diff(slot.start)).format("HH:mm");
            cellDate += `${diff}h\n${currenySymbol} ${slot.wageRate}/h\n`;
            //row.push(cellDate);
          } else {
            //row.push('\n');
            cellDate += "\n";
          }
        });
        row.push(cellDate);
      });

      var employeeHours = this.getTotalHour(m);
      row.push(employeeHours);
      // totalHours += employeeHours;
      var employeeWage = this.getTotalWage(m);
      // totalCost +=employeeWage;
      row.push(currenySymbol + " " + employeeWage);
      console.log("ROW FOR USER " + m.userId, row);
      detailData.push(row);
    });

    detailDoc.text(`Total Hours: ${totalHours}`, 15, 25);
    detailDoc.text(`Total Cost: ${currenySymbol} ${totalCost}`, 15, 30);

    autoTable(detailDoc, {
      theme: "grid",
      margin: { top: 50 },
      head: [detailHeader],
      body: detailData,
    });

    detailDoc.save(
      `employee_cost_details_${this.startOfWeek.format("YYYYMMDD")}.pdf`
    );
  }

  openSettings($event: any): void {
    if ($event) {
      $event.stopPropagation();
    }

    let teamInfo = cloneDeep(this.teamInfo);
    teamInfo.roster = teamInfo.roster || {
      allowShiftSwap: true,
      paidBreak: true,
      breaks: [60],
      break: 60,
      currency: "USD",
    };
    teamInfo.startOfWeek = teamInfo.startOfWeek || "Monday";
    teamInfo.weekDays = teamInfo.weekDays || [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
    ];
    const dialogRef = this.dialog.open(RosterSettingsComponent, {
      width: "800px",

      data: { teamInfo: teamInfo },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.action == "yes") {
        this.startOfWeek = moment(
          new Date().getTime() + this.userInfo.user.tz_offset * 1000
        )
          .day(result.data.teamInfo.startOfWeek)
          .startOf("day");

        this.endOfWeek = moment(this.startOfWeek).add(7, "days");

        var day = this.startOfWeek;

        while (day <= this.endOfWeek) {
          //this.daysOfTheWeek.push(day.toDate();
          this.daysOfTheWeek.push(day);
          day = day.clone().add(1, "d");
        }
        this.teamInfo = result.data.teamInfo;
        this.options.prefix = getCurrencySymbol(
          this.teamInfo.roster.currency,
          "narrow"
        );
        console.log(JSON.stringify(this.teamInfo, null, 2));
        this.apiService.updateRosterInfo(this.teamInfo).subscribe(
          (data) => {
            //this.apiService.toast("Roster settings updated successfully.","success");
            localStorage.setItem("teamInfo", JSON.stringify(this.teamInfo));
            this.shiftTypes = this.teamInfo.roster.positions;
            this.loadShifts();
            this.loadLeaves();
            //window.location.reload();
            this.apiService.toast("Settings were updated ", "success");
          },
          (error) => {
            this.apiService.toast("Unable to update settings ", "error");
          },
          () => {
            //console.log("api completed");
          }
        );
      } else {
        console.log("dismissed");
      }
    });
  }

  async openShift($event: any, slot: any, member: any, day: any) {
    $event.stopPropagation();
    console.log("Opening shift", slot);

    if (this.roster.rStatus === "closed") {
      return;
    }
    if (slot.fake === true) {
      this.createShift($event, member, day);
      return;
    }
    let that = this;
    const dialogRef = this.dialog.open(RosterDialogComponent, {
      //width: "800px",

      data: {
        slot: slot,
        days: this.daysOfTheWeek,
        member: member,
        shiftTypes: this.shiftTypes,
        currency: this.options.prefix,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      //console.log('The dialog was closed ', result);
      if (result && result.action == "yes") {
        console.log(result);
        let slot = result.data.slot;
        //let id = slot['start'].format('DD')+slot.userId;

        // let id = slot['start'].format('DD')+slot.userId;
        let fromOffset = slot["start"].format("HH:mm").split(":");
        let toOffset = slot["end"].format("HH:mm").split(":");
        let diff = slot.end.diff(slot.start);

        if (diff < 0) {
          let minutes = 24 * 60 + diff / 60000;
          slot["end"] = moment(slot["start"]).add(minutes, "minutes");
          slot["multiple"] = true;
        } else {
          slot["multiple"] = false;
          slot["end"] = moment(slot["start"]).add(diff / 60000, "minutes");
        }

        slot["fromDate"] = slot["start"].toDate();
        slot["toDate"] = slot["end"].toDate();

        var minutes = moment
          .utc(slot["end"].diff(slot["start"], "minutes"))
          .valueOf();
        minutes = Math.round(minutes / 60);
        slot["estWage"] = minutes * slot.wageRate;

        let id = slot["start"].format("DDMMYYYY") + slot.userId;
        if (!this.shiftMap[id]) {
          this.shiftMap[id] = [];
        }
        let persisted = await this.apiService.updateShift(slot).toPromise();
        console.log("successfully updated shift");
        //this.shiftMap[id].push(dupSlot);
        //console.log(dupSlot);
      }
    });
  }

  async createShift($event: any, member: any, day: any) {
    console.log("member", member);
    $event.stopPropagation();

    if (this.roster.rStatus === "closed") {
      return;
    }

    let mStart = moment(day).startOf("day").add(8, "hours");
    let mEnd = moment(day).startOf("day").add(16, "hours");
    member.wageRate = member.wageRate || this.teamInfo.roster.wageRate;
    let slot = {
      userId: member.userId,
      tz_offset: member.tz_offset,
      start: mStart,
      end: mEnd,
      fromDate: mStart.toDate(),
      toDate: mEnd.toDate(),
      paidBreak: true,
      breakTime: 60,
      wageRate: member.wageRate,
    };
    let that = this;
    const dialogRef = this.dialog.open(RosterDialogComponent, {
      //width: "400px",

      data: {
        slot: slot,
        days: this.daysOfTheWeek,
        member: member,
        shiftTypes: this.shiftTypes,
        currency: this.options.prefix,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed ', result);
      if (result && result.action == "yes") {
        console.log(result);
        let slot = result.data.slot;
        // let id = slot['start'].format('DD')+slot.userId;
        let fromOffset = slot["start"].format("HH:mm").split(":");
        let toOffset = slot["end"].format("HH:mm").split(":");
        let diff = slot.end.diff(slot.start);
        console.log(diff);
        // console.log(fromOffset,toOffset);
        // if(!this.shiftMap[id]){
        //   this.shiftMap[id]=[];
        // }
        // this.shiftMap[id].push(slot);
        // console.log(this.shiftMap);r
        result.repeated.forEach(async (m) => {
          if (m.checked) {
            let dupSlot = cloneDeep(slot);
            dupSlot["id"] = "random";
            dupSlot["rosterId"] = this.roster.id;
            dupSlot["start"] = moment(m.day).set({
              h: fromOffset[0],
              m: fromOffset[1],
            });
            if (diff < 0) {
              let minutes = 24 * 60 + diff / 60000;
              dupSlot["end"] = moment(dupSlot["start"]).add(minutes, "minutes");
              dupSlot["multiple"] = true;
            } else {
              dupSlot["multiple"] = false;
              dupSlot["end"] = moment(dupSlot["start"]).add(
                diff / 60000,
                "minutes"
              );
            }

            dupSlot["fromDate"] = dupSlot["start"].toDate();
            dupSlot["toDate"] = dupSlot["end"].toDate();

            var minutes = moment
              .utc(dupSlot["end"].diff(dupSlot["start"], "minutes"))
              .valueOf();
            minutes = Math.round(minutes / 60);
            dupSlot["estWage"] = minutes * dupSlot.wageRate;

            let id = dupSlot["start"].format("DDMMYYYY") + dupSlot.userId;
            if (!this.shiftMap[id]) {
              this.shiftMap[id] = [];
            }
            let persisted = await this.apiService
              .createShift(dupSlot)
              .toPromise();
            dupSlot["id"] = persisted["id"];
            this.shiftMap[id].push(dupSlot);
            this.shifts.push(dupSlot);
            //console.log(dupSlot);
            // this.apiService.createShift(dupSlot)
            // .subscribe((data)=>{
            //   console.log("api successful", data);
            // },
            // (error)=>{
            // },
            // ()=>{
            // })
          }
        });
      }
    });
  }

  disablePublish: boolean = false;
  async publish() {
    let roster = {
      rosterId: this.roster.id,
      rStatus: "published",
      users: this.botUsers.map((m) => {
        return m.userId;
      }),
      fromDate: this.startOfWeek.format("ddd Do MMM"),
      toDate: this.endOfWeek.format("ddd Do MMM"),
    };
    roster["offices"] = this.roster.offices || [];
    if (!roster["offices"].includes(this.officeId)) {
      roster["offices"].push(this.officeId);
    }

    this.disablePublish = true;
    let result = await this.apiService.publishRoster(roster).toPromise();
    this.apiService.toast("Roster published", "success");
    this.roster.rStatus = roster.rStatus;
    this.disablePublish = false;
  }

  async release() {
    let roster = {
      rosterId: this.roster.id,
      rStatus: "closed",
    };

    this.disablePublish = true;
    let result = await this.apiService.releaseRoster(roster).toPromise();
    this.disablePublish = false;
    this.apiService.toast(
      "Roster is marked as closed. You cannot make any changes.",
      "success"
    );
    this.roster.rStatus = roster.rStatus;
  }
}
