const allowedCommentPattern = /[a-zA-Z0-9_,-]/; // Allowed characters: alphanumeric, underscore, comma, and hyphen
export const allowedLeaveTypePattern = /[a-zA-Z0-9_]/; // Allowed characters: alphanumeric, underscore, comma, and hyphen

export const getTranslatedPlanCode = (planCode) => {
  if (planCode == "silver") {
    return "Basic";
  } else if (planCode == "gold") {
    return "Standard";
  } else if (planCode == "3") {
    return "Premium";
  } else {
    return planCode;
  }
};

export const validateCommentInput = (event: KeyboardEvent) => {
  const inputChar = String.fromCharCode(event.charCode);
  if (!allowedCommentPattern.test(inputChar)) {
    event.preventDefault();
  }
};

export const stringToColour = (str) => {
  if (str === undefined || str === null) {
    return "#ededed";
  }
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert hash to RGB values
  let r = (hash & 0xff0000) >> 16;
  let g = (hash & 0x00ff00) >> 8;
  let b = hash & 0x0000ff;

  // Ensure color isn't too bright by reducing brightness if necessary
  const maxBrightness = 100; // Adjust this value to control maximum brightness
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  if (brightness > maxBrightness) {
    const reduction = maxBrightness / brightness;
    r = Math.floor(r * reduction);
    g = Math.floor(g * reduction);
    b = Math.floor(b * reduction);
  }

  // Convert RGB to hex
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

export const LeaveColorMap: any = {
  PTO: "#E91E63",
  REMOTE: "#00c853",
  FLEX: "#2196F3",
  WFH: "#673AB7",
  WELLNESS: "#FF9800",
  SICK: "#FDD835",
};

export const hasShiftAccess = (planCode: string): boolean => {
  return ["GOLD", "STANDARD", "PREMIUM", "TRIAL"].includes(planCode);
};

export const leavTypeObjectToArray = (obj: any) => {
  return Object.entries(obj).map(([name, props]) => ({
    name,
    ...props,
    emoji: ":date:",
  }));
};
