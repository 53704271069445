<div class="roster-settings">
    <div mat-dialog-content  >
        <h4 class="page-card-title">Roster Settings</h4>
      <div fxLayout="column"  fxLayoutWrap fxLayoutGap="5%" fxLayoutAlign="center">
          

          <div fxLayout="column" fxFlex="50%">
            
                <mat-slide-toggle class="toggle" color="primary" [(ngModel)]="data.teamInfo.roster.allowShiftSwap">Allow Shift Swap</mat-slide-toggle>
                <mat-slide-toggle   class="toggle" color="primary" [(ngModel)]="data.teamInfo.roster.paidBreak">Include paid break (you can override during shift creation)</mat-slide-toggle>
                <mat-form-field  color="primary">
                    <mat-chip-list #chipList>
                      <mat-chip *ngFor="let pos of data.teamInfo.roster.positions" [selectable]="selectable"
                               [removable]="removable" (removed)="remove(pos)">
                        {{pos}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip>
                      <input placeholder="Add positions..."
                             [matChipInputFor]="chipList"
                             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                             [matChipInputAddOnBlur]="addOnBlur"
                             (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                  </mat-form-field>

                  <mat-form-field  >
                      <mat-select   [(ngModel)]="data.teamInfo.roster.currency">
                          <mat-option>
                              <ngx-mat-select-search placeholderLabel="Filter..." noEntriesFoundLabel="No result" [(ngModel)]="filter"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let m of _filterCurrencyCodes(filter)" [value]="m" >
                              {{m}} {{getCurrSymbol(m)}}
                          </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field >
                      <input  class="currency" [options]="getOptions()" matInput placeholder="Deafult wage per hour" currencyMask [(ngModel)]="data.teamInfo.roster.wageRate">
                  </mat-form-field>

                  <mat-form-field  color="warn">
                      <mat-chip-list #chipList2>
                        <mat-chip *ngFor="let brk of data.teamInfo.roster.breaks" [selectable]="selectable"
                                 [removable]="removable" (removed)="removeBreak(brk)">
                          {{brk}}
                          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="Add breaks in minutes"
                               [matChipInputFor]="chipList2"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputAddOnBlur]="addOnBlur"
                               (matChipInputTokenEnd)="addBreak($event)">
                      </mat-chip-list>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Default break</mat-label>
                        <mat-select [(ngModel)]="data.teamInfo.roster.break">
                          <mat-option *ngFor="let brk of data.teamInfo.roster.breaks" [value]="brk">
                            {{brk}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    
                      <mat-form-field>
                          <mat-label>Start of the week</mat-label>
                          <mat-select [(ngModel)]="data.teamInfo.startOfWeek">
                            <mat-option *ngFor="let d of weekdays" [value]="d">
                              {{d}}
                            </mat-option>
                          </mat-select>
                      </mat-form-field>
                      <div fxLayout="row">
                          <mat-checkbox  style="margin-right:16px" [checked]="checked(d)" color="primary" *ngFor="let d of weekdays" (change)="valueChanged(d,$event)">{{d.substring(0,1)}}</mat-checkbox> 
                      </div>
                       
          </div>
         
      </div>  
      
    </div>
    
    <div mat-dialog-actions>
    <button [disabled]="invalidForm()" mat-raised-button color="primary" (click)="onYesClick()">Save</button>
    <button mat-raised-button (click)="onNoClick()">Dismiss</button>
    
    </div>
    </div>
  