import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, SecurityContext } from "@angular/core";
import { ApiService } from "../../shared/api.service";
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from "date-fns";
import { Subject } from "rxjs";

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  DAYS_OF_WEEK,
} from "angular-calendar";

import * as moment from "moment-timezone";
import { AuthService } from "../../shared/auth.service";
import { ConfirmDialogComponent } from "../../shared/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { validateCommentInput } from "src/app/shared/utility";

// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
moment.updateLocale("en", {
  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0,
  },
});

const colors: any = {
  red: {
    primary: "rgba(218, 21, 6, 1)",
    secondary: "rgba(218, 21, 6, 0.5)",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.css"],
})
export class EventsComponent implements OnInit {
  allowedCommentPattern = /[a-zA-Z0-9_,-]/; // Allowed characters: alphanumeric, underscore, comma, and hyphen

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: `<i  style="color:red" class="fa fa-fw fa-trash red-icon" ></i>`,
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.deleteEvent(event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  teamInfo: any = {};
  userInfo: any = {};
  planId: string = "BRONZE";

  event: any = { duration: "single" };

  activeDayIsOpen: boolean = false;

  eventTypes: any[] = [
    { tag: "pto", description: "PTO" },
    { tag: "wfh", description: "WFH" },
    { tag: "FLEX", description: "FLEX" },
    { tag: "WELLNESS", description: "WELLNESS" },
    { tag: "REMOTE", description: "REMOTE" },
    { tag: "SICK", description: "SICK" },
  ];
  appliedEventTypes: string[] = [];
  daysOfWeek: any[] = [
    { tag: "mon", description: "Monday" },
    { tag: "tue", description: "Tuesday" },
    { tag: "wed", description: "Wednesday" },
    { tag: "thu", description: "Thursday" },
    { tag: "fri", description: "Friday" },
    { tag: "sat", description: "Saturday" },
    { tag: "sun", description: "Sunday" },
  ];

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer
  ) {
    if (localStorage.getItem("teamInfo")) {
      this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
      // this.appliedEventTypes = apiService.getAppliedLeaveTypes(
      //   this.teamInfo.quota
      // );
      this.appliedEventTypes = apiService.getTeamEventTypes(this.teamInfo);
    }
    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
    if (localStorage.getItem("planId")) {
      this.planId = localStorage.getItem("planId").toUpperCase();
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent("Dropped or resized", event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  addEvent(): void {
    this.events.push({
      title: "New event",
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
      color: colors.red,
      draggable: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
    });
    this.refresh.next();
  }

  createEvent(): void {
    this.event.tz_offset = JSON.parse(localStorage.getItem("userInfo")).user.tz_offset;
    this.event.teamId = localStorage.getItem("teamId");
    this.event.userId = localStorage.getItem("userId");

    console.log(localStorage.getItem("planId"), this.teamInfo.autoApprove);
    if (
      localStorage.getItem("planId") === "gold" &&
      this.teamInfo.autoApprove !== undefined &&
      this.teamInfo.autoApprove === false
    ) {
      this.event.askApproval = true;
      this.event.botToken = localStorage.getItem("B_T");
      if (this.userInfo.approver) {
        this.event.approverId = this.userInfo.approver;
      } else {
        this.event.approverId = this.teamInfo.userId;
      }
    } else {
      this.event.askApproval = false;
    }

    this.event.targetUsers = [this.event.userId];

    console.log(this.event);

    this.apiService.postMyEvents(this.event).subscribe(
      (data) => {
        if (this.event.askApproval) {
          this.apiService.toast("Your request has been sent for approval.", "success");
        } else {
          this.apiService.toast("Your event has been created succeefully.", "success");
        }

        this.loadEvents();
      },
      (error) => {},
      () => {
        console.log("api completed");
      }
    );
  }

  deleteEvent(event): void {
    // console.log(this.apiService.createDateAsUTC(new Date()).getTime(),event.start.getTime());
    // if(this.apiService.createDateAsUTC(new Date()).getTime() > event.start.getTime()){
    //   this.apiService.toast("You cannot delete an event which started in the past.","error");
    //   return;
    // }
    //console.log('deleting', event.start.getTime(), this.apiService.createDateAsUTC(new Date()).getTime());
    let that = this;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: {
        message: "You are going to delete your event. Do you want to continue?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed ', result);
      if (result && result.action == "yes") {
        that.apiService.deleteMyEvents(event).subscribe(
          (data) => {
            that.apiService.toast("Your event has been deleted successfully.", "success");
            let index = that.events.findIndex((item) => item.id == event.id);
            that.events.splice(index, 1);
            that.refresh.next();
          },
          (error) => {},
          () => {
            console.log("api completed");
          }
        );
      }
    });
  }
  ngOnInit() {
    this.loadEvents();
  }

  loadEvents(): void {
    let that = this;
    that.events = [];
    this.apiService.getMyEvents().subscribe(
      (data) => {
        data.forEach((element) => {
          //console.log(moment.tz(element.fromDate, "Europe/London"));
          //console.log(moment.tz(element.toDate, "Europe/London"));

          let event = {
            id: element.id,
            start: this.apiService.createDateAsUTC(new Date(element.fromDate)),
            end: this.apiService.createDateAsUTC(new Date(element.toDate)),
            title: element.type,
            color: element.type == "PTO" ? colors.red : colors.blue,
            allDay: true,
            draggable: false,
          };
          if (element.shouldDelete === true) {
            event["actions"] = that.actions;
          }
          if (element.comment) {
            element.comment = element.comment.replace(/[^a-zA-Z0-9_,]/g, " ");
            console.log("replaced comment", element.comment);
            if (element.comment.charAt(0) == "“") {
              event["title"] += ": " + element.comment.substring(1, element.comment.length - 1);
            } else {
              event["title"] += ": " + element.comment;
            }
          }
          //console.log(event);
          that.events.push(event);
        });
        this.refresh.next();
      },
      (error) => {},
      () => {
        console.log("api completed");
        // this.authService.setProgressIndicator(false);
      }
    );
  }

  validateCommentInput = (event: KeyboardEvent) => {
    validateCommentInput(event);
  };

  validateInput = function (): boolean {
    let valid = true;
    if (
      !this.event.type ||
      !this.event.fromDate ||
      (this.event.duration == "multiple" && !this.event.toDate) ||
      (this.event.duration == "recurring" && !this.event.toDate)
    ) {
      valid = false;
    }
    if (this.event.duration == "recurring" && !(this.event.weekDays && this.event.weekDays.length > 0)) {
      valid = false;
    }
    return valid;
  };
}
