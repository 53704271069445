import { Injectable } from "@angular/core";

import { Observable } from "rxjs/Observable";
import { AuthService } from "./auth.service";
import "rxjs/add/operator/do";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

@Injectable()
export class SafeGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.isAuthenticated()) {
      const userRole = localStorage.getItem("role");
      console.log("SafeGuardService#canActivate called");
      console.log("userRole", userRole);
      console.log(route.routeConfig);
      console.log(state.url);
      if (
        userRole === "member" &&
        [
          "/secured/roster",
          "/secured/settings",
          "/secured/subscription",
        ].includes(state.url)
      ) {
        this.router.navigate(["/secured/dashboard"]);
        return false;
      }

      return true;
    }
    //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    this.router.navigate(["/login"]);
    return false;
  }
}
