import { Component, OnInit } from "@angular/core";
import { MatBottomSheet } from "@angular/material";
import { ContactUsSheetComponent } from "../contact-us-sheet/contact-us-sheet.component";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor(private bottomSheet: MatBottomSheet) {}

  ngOnInit() {}

  openContact(): void {
    this.bottomSheet.open(ContactUsSheetComponent);
  }
}
