import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class SubsciptionResolver implements Resolve<any> {
  constructor(private apiService: ApiService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    console.log("SubsciptionResolver#resolve called");
    const start = new Date().getTime();
    //store the value in localstorage and call api only if it's not found in localstorage

    let planCode = "NONE";

    try {
      planCode = localStorage.getItem("planCode");
      if (planCode) {
        console.log("planCode found in localstorage");
        return {
          planCode: planCode,
        };
      } else {
        console.log("planCode not found in localstorage");
        const r = await this.apiService.getPlanCode().toPromise();
        console.log("response received from getPlanCode", r);
        if (r.planCode) {
          planCode = r.planCode.toUpperCase();
          localStorage.setItem("planCode", planCode);
          localStorage.setItem("planId", planCode);
          return {
            planCode: planCode,
          };
        }
      }
    } catch (error) {
      console.log(error);
      return {
        planCode: planCode,
      };
    } finally {
      const end = new Date().getTime();
      console.log("SubsciptionResolver#resolve took", end - start, "ms");
    }
  }
}
