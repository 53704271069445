import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../shared/auth.service";
import { ApiService } from "../shared/api.service";
import { environment } from "src/environments/environment";
// import {
//   trigger,
//   state,
//   style,
//   animate,
//   transition,
// } from "@angular/animations";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  // animations: [
  //   trigger("openClose", [
  //     state(
  //       "open",
  //       style({
  //         height: "100px",
  //         opacity: 1,
  //       })
  //     ),
  //     state(
  //       "closed",
  //       style({
  //         height: "100px",
  //         opacity: 0,
  //       })
  //     ),
  //     transition("open => closed", [animate("1s")]),
  //     transition("closed => open", [animate("0.5s")]),
  //   ]),
  // ],
})
export class LoginComponent implements OnInit {
  public isOpen: boolean = false;

  public carousel = [
    {
      image: "assets/w1.jpg",
      image2: "assets/p1.png",
      title: "Manage your team settings",
      subTitle:
        "Manage your team settings through web console. Manage leave entitlements, user roles, user termination everything from one place.",
    },
    {
      image: "assets/w3.jpg",
      image2: "assets/p2.png",
      title: "Get more done",
      subTitle:
        "We understand sometimes we feel better to have companion web application for your Slack Bot. You requested and we built it. View leaves in a very simple calendar view.",
    },
  ];
  public displayCounter = 0;
  public slider;

  public features: any[] = [
    {
      image: "assets/p12.png",
      title: "Leave entitlement",
      subTitle:
        "As an Admin or HR user setup your team's leave entitlement quota, leave accurals, year end etc.",
    },
    {
      image: "assets/w14.png",
      title: "Admin Control Panel",
      subTitle:
        "Control behavior of OfficeBot for your workspace such as auto leave approval, restricted view outside own team, disallow new team members to join OfficeBot and many more.",
    },
    {
      image: "assets/w15.png",
      title: "Manage users",
      subTitle:
        "Allocate members to appropriate office and department, manage their leave approvers, inactivate member when they leave your organization.",
    },
    {
      image: "assets/w13.png",
      title: "Animated Dashboard",
      subTitle:
        "Animated dashaboard to see your current year leave consumption.",
    },
    {
      image: "assets/w11.png",
      title: "Visual Calendar",
      subTitle: "Check everyone's schedule in a cool visual calendar.",
    },
    {
      image: "assets/ics2.png",
      title: "Export iCalendar (ics)",
      subTitle:
        "Export ics files for entire organization or specific offices and import them to Google calendar or Outlook. Or any Calendar app suports ics file format.",
    },
  ];

  public redirectUrl: string = environment.redirectUlr;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    console.log("inside login component");
    this.route.params.subscribe((params) => {
      console.log(this.route.snapshot.params);

      let access_token = params["access_token"];
      let bot_token = params["bot_token"];
      let userId = params["userId"];
      let install = params["install"];
      let web_token = params["web_token"];
      console.log("web token supplied ? ", web_token);

      //if web_token is not found, that means someone trying to impersonet and should be redirected to login
      if (!web_token) {
        this.authService.logout();
      }

      if (access_token && !userId) {
        this.apiService.toast(
          "Howdy! OfficeBot could not find your profile. Please add OfficeBot to your slack workspace.",
          "error"
        );
        return;
      }

      // console.log("values from params", access_token);

      // if(access_token){
      //   localStorage.setItem("S_T",params['access_token']);
      // }
      // if(bot_token){
      //   localStorage.setItem("B_T",params['bot_token']);
      // }
      if (userId) {
        localStorage.setItem("userId", params["userId"]);
      }
      if (web_token) {
        localStorage.setItem("web_token", web_token);
      }

      // if (install == "y") {
      //   this.router.navigate(["/secured/settings", { install: "y" }]);
      //   return;
      // } else if (this.authService.isAuthenticated()) {
      //   this.router.navigate(["/secured/dashboard"]);
      // }
      this.router.navigate(["/secured/dashboard"]);
    });

    //carousel animation
    let that = this;
    that.isOpen = true;
    that.slider = that.carousel[this.displayCounter];
    setInterval(() => {
      that.isOpen = true;
      that.slider = that.carousel[that.displayCounter];

      if (that.displayCounter >= that.carousel.length - 1) {
        that.displayCounter = 0;
      } else {
        that.displayCounter++;
      }
      setTimeout(() => {
        that.isOpen = false;
      }, 4000);
    }, 5000);
  }

  getVin = function () {
    console.log("calling get vin");
    this.authService.getRandomVin();
  };
}
