var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { StripeService, StripeCardComponent } from "ngx-stripe";
import { ApiService } from "../../shared/api.service";
import { AuthService } from "../../shared/auth.service";
import { PageScrollService } from "ngx-page-scroll";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "../../shared/confirm-dialog/confirm-dialog.component";
import { getTranslatedPlanCode } from "src/app/shared/utility";
/** Error when invalid control is dirty, touched, or submitted. */
var MyErrorStateMatcher = /** @class */ (function () {
    function MyErrorStateMatcher() {
    }
    MyErrorStateMatcher.prototype.isErrorState = function (control, form) {
        var isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    };
    return MyErrorStateMatcher;
}());
export { MyErrorStateMatcher };
var SubscriptionComponent = /** @class */ (function () {
    function SubscriptionComponent(apiService, authService, fb, stripeService, pageScrollService, dialog, document) {
        var _this = this;
        this.apiService = apiService;
        this.authService = authService;
        this.fb = fb;
        this.stripeService = stripeService;
        this.pageScrollService = pageScrollService;
        this.dialog = dialog;
        this.document = document;
        this.cardOptions = {
            style: {
                base: {
                    "iconColor": "#666EE8",
                    "color": "#31325F",
                    "lineHeight": "40px",
                    "fontWeight": 300,
                    "fontSize": "18px",
                    "::placeholder": {
                        color: "#CFD7E0",
                    },
                },
            },
        };
        this.elementsOptions = {
            locale: "en",
        };
        this.matcher = new MyErrorStateMatcher();
        this.showPaymentMethod = false;
        this.showCardEntry = false;
        this.showCardEdit = false;
        this.plansMap = {};
        this.termsChecked = false;
        this.subcriptionProcessing = false;
        this.panelOpenState = false;
        this.transactions = [];
        this.botUsers = [];
        this.forecastedBilling = 0;
        this.activePlanCode = "TRIAL";
        this.getPlans = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.plans = [
                    {
                        code: "basic",
                    },
                    {
                        code: "standard",
                    },
                ];
                return [2 /*return*/];
            });
        }); };
        this.getTranslatedPlanCode = function (code) {
            return getTranslatedPlanCode(code);
        };
        this.fetchSubscription = function () {
            return __awaiter(this, void 0, void 0, function () {
                var r;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiService.fetchSubscription().toPromise()];
                        case 1:
                            r = _a.sent();
                            console.log("sub summary", r);
                            this.showPaymentMethod = true;
                            this.subSummary = r;
                            this.cards = r.paymentMethod || [];
                            if (r.status === "active") {
                                this.activeSubscription = r.plan;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        };
        this.swicthOrSubscribe = function (planCode) { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.subcriptionProcessing = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.apiService
                                .cancelSubscription({
                                command: "change",
                                planCode: planCode,
                            })
                                .toPromise()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.refreshSubscription()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_1 = _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        this.subcriptionProcessing = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.showTransactionHistory = function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, this.apiService.getTransHistory().toPromise()];
                        case 1:
                            _a.transactions = _b.sent();
                            console.log("transactions", this.transactions);
                            return [2 /*return*/];
                    }
                });
            });
        };
        this.cancelSubscription = function () {
            return __awaiter(this, void 0, void 0, function () {
                var that, dialogRef;
                var _this = this;
                return __generator(this, function (_a) {
                    that = this;
                    dialogRef = this.dialog.open(ConfirmDialogComponent, {
                        width: "auto",
                        data: {
                            yesLabel: "Yes, cancel",
                            content: "<div style=\"font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #e0e0e0; border-radius: 5px;\">\n    <h2 style=\"color: #ff6600; margin-top: 0;\">Whoa there! Before you hit that cancel button, let's chat about your Officebot experience:</h2>\n    \n    <ul style=\"list-style-type: none; padding-left: 0;\">\n        <li style=\"margin-bottom: 10px;\">\n            <strong>\uD83D\uDCC8 Loving some features but not others?</strong> We're all ears! Tell us how we can improve.\n        </li>\n        <li style=\"margin-bottom: 10px;\">\n            <strong>\uD83C\uDD98 Facing any challenges?</strong> Our support team is ready to swoop in and help.\n        </li>\n        <li style=\"margin-bottom: 10px;\">\n            <strong>\uD83D\uDCB0 Budget concerns?</strong> Let's explore our flexible plans that might better suit your needs.\n        </li>\n    </ul>\n\n    <p><strong>Remember, cancelling means:</strong></p>\n    <ul>\n        <li>Losing access to Officebot's time-saving magic</li>\n        <li>Saying goodbye to all your data after 7 days</li>\n    </ul>\n\n    <p style=\"font-style: italic; color: #666;\">Why not give us a chance to make things awesome for you? If you're still set on cancelling, we'll understand \u2013 but we'd hate to see you go!</p>\n\n    <p style=\"font-weight: bold; margin-top: 20px;\">Ready to cancel, or want to chat about options?</p>\n    \n    \n</div>",
                        },
                    });
                    dialogRef.afterClosed().subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
                        var error_2;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(result && result.action == "yes")) return [3 /*break*/, 6];
                                    console.log("ready to cancel subscription");
                                    this.subcriptionProcessing = true;
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 4, 5, 6]);
                                    return [4 /*yield*/, this.apiService
                                            .cancelSubscription({
                                            command: "cancel",
                                        })
                                            .toPromise()];
                                case 2:
                                    _a.sent();
                                    return [4 /*yield*/, this.refreshSubscription()];
                                case 3:
                                    _a.sent();
                                    return [3 /*break*/, 6];
                                case 4:
                                    error_2 = _a.sent();
                                    return [3 /*break*/, 6];
                                case 5:
                                    this.subcriptionProcessing = false;
                                    return [7 /*endfinally*/];
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); });
                    return [2 /*return*/];
                });
            });
        };
    }
    SubscriptionComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.stripeFormGroup = this.fb.group({
                            name: ["", [Validators.required]],
                            email: ["", [Validators.required, Validators.email]],
                            termsChecked: ["", [Validators.required]],
                        });
                        this.newCardFormGroup = this.fb.group({
                            name: ["", [Validators.required]],
                        });
                        return [4 /*yield*/, this.fetchSubscription()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.showTransactionHistory()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getPlans()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // subscribe(selectedPlan: any) {
    //   console.log("subscribe to ", selectedPlan);
    //   this.showCardEntry = true;
    //   this.selectedNewPlan = selectedPlan;
    //   let that = this;
    //   let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, "#card");
    //   setTimeout(function () {
    //     that.pageScrollService.start(pageScrollInstance);
    //   }, 500);
    // }
    // switchSubscription(plan) {
    //   console.log("switchSubscription to ", plan);
    //   let that = this;
    //   this.subcriptionProcessing = true;
    //   let entity = {
    //     planId: plan.planId,
    //     code: plan.code,
    //     userPlanId: plan.userPlanId,
    //   };
    //   that.apiService.createFirstSubscription(entity).subscribe(
    //     (data) => {
    //       that.apiService.toast("Your subscription was created successfully.", "success");
    //       let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, "#top");
    //       setTimeout(function () {
    //         that.pageScrollService.start(pageScrollInstance);
    //       }, 500);
    //     },
    //     (error) => {
    //       that.subcriptionProcessing = false;
    //       that.apiService.toast("Your subscription was not created. Try again.", "error");
    //     },
    //     () => {
    //       that.subcriptionProcessing = false;
    //       console.log("api completed");
    //     }
    //   );
    // }
    // postSubscriptionFirst() {
    //   const name = this.stripeFormGroup.get("name").value;
    //   const email = this.stripeFormGroup.get("email").value;
    //   let that = this;
    //   this.subcriptionProcessing = true;
    //   this.stripeService.createToken(this.card.getCard(), { name }).subscribe((result) => {
    //     if (result.token) {
    //       // Use the token to create a charge or a customer
    //       // https://stripe.com/docs/charges
    //       console.log(result.token.id);
    //       let entity = {
    //         token: result.token.id,
    //         planId: this.selectedNewPlan.planId,
    //         userPlanId: this.selectedNewPlan.userPlanId,
    //         name: name,
    //         email: email,
    //         code: this.selectedNewPlan.code,
    //       };
    //       that.apiService.createFirstSubscription(entity).subscribe(
    //         (data) => {
    //           that.apiService.toast(
    //             "Your subscription was created successfully. If you still see a message on your Slack OfficBot to upgrade to paid plan, please ignore. That message will disappear eventually.",
    //             "success"
    //           );
    //           that.showCardEntry = false;
    //           let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, "#top");
    //           setTimeout(function () {
    //             that.pageScrollService.start(pageScrollInstance);
    //           }, 500);
    //         },
    //         (error) => {
    //           that.subcriptionProcessing = false;
    //           that.apiService.toast("Your subscription was not created. Try again.", "error");
    //         },
    //         () => {
    //           that.subcriptionProcessing = false;
    //           console.log("api completed");
    //         }
    //       );
    //     } else if (result.error) {
    //       // Error creating the token
    //       console.log(result.error.message);
    //       this.subcriptionProcessing = false;
    //       this.apiService.toast(result.error.message, "error");
    //     }
    //   });
    // }
    SubscriptionComponent.prototype.refreshSubscription = function () {
        return __awaiter(this, void 0, void 0, function () {
            var r;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchSubscription()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.apiService.getPlanCodeWithReload().toPromise()];
                    case 2:
                        r = _a.sent();
                        console.log("plan code response", r);
                        if (r.planCode) {
                            console.log("resetting plancode to ", r.planCode.toUpperCase());
                            localStorage.setItem("planCode", r.planCode.toUpperCase());
                            localStorage.setItem("planId", r.planCode.toUpperCase());
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SubscriptionComponent.prototype.updateCard = function () {
        return __awaiter(this, void 0, void 0, function () {
            var name;
            var _this = this;
            return __generator(this, function (_a) {
                name = this.stripeFormGroup.get("name").value;
                console.log("updaing card", name);
                this.subcriptionProcessing = true;
                this.stripeService.createToken(this.card.getCard(), { name: name }).subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
                    var entity, error_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                console.log("result", result);
                                if (!result.token) return [3 /*break*/, 7];
                                entity = {
                                    customerId: this.subSummary.customerId,
                                    token: result.token.id,
                                };
                                console.log(entity);
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 4, 5, 6]);
                                return [4 /*yield*/, this.apiService.postCards(entity).toPromise()];
                            case 2:
                                _a.sent();
                                return [4 /*yield*/, this.refreshSubscription()];
                            case 3:
                                _a.sent();
                                this.showCardEdit = false;
                                this.apiService.toast("Card added successfully!", "success");
                                return [3 /*break*/, 6];
                            case 4:
                                error_3 = _a.sent();
                                console.log(error_3);
                                return [3 /*break*/, 6];
                            case 5:
                                this.subcriptionProcessing = false;
                                return [7 /*endfinally*/];
                            case 6: return [3 /*break*/, 8];
                            case 7:
                                if (result.error) {
                                    // Error creating the token
                                    console.log(result.error.message);
                                    this.subcriptionProcessing = false;
                                    this.apiService.toast(result.error.message, "error");
                                }
                                _a.label = 8;
                            case 8: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    return SubscriptionComponent;
}());
export { SubscriptionComponent };
