<div>
  <mat-progress-bar
    *ngIf="authService.getProgressIndicator()"
    mode="indeterminate"
    color="accent"
  ></mat-progress-bar>
  <mat-card
    *ngIf="getRole() === 'admin' || fromInstall"
    class="team-setting-card"
  >
    <mat-card-header style="display: flex; justify-content: center">
      <mat-card-title class="header mat-subheading-2">
        Workspace Settings
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div
        [fxLayout]="'column'"
        fxLayoutGap="0.5%"
        fxLayout.xs="column"
        fxLayoutAlign="center center"
      >
        <div>
          <table class="table-card">
            <tr>
              <td>
                <mat-slide-toggle
                  color="accent"
                  [(ngModel)]="teamInfo.allowNewMember"
                  >Allow New Member</mat-slide-toggle
                >
              </td>
              <td>
                <button
                  mat-icon-button
                  matTooltip="If you set it false new team members will not be able to interact with the bot."
                >
                  <mat-icon color="accent">info</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <mat-slide-toggle
                  color="accent"
                  [(ngModel)]="teamInfo.autoApprove"
                  >Auto Leave Approve</mat-slide-toggle
                >
              </td>
              <td>
                <button
                  mat-icon-button
                  matTooltip="If you set it false, all leave request will go to admin or manager for approval. Go to Manage Users section below to change approval for individual member."
                >
                  <mat-icon color="accent">info</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <mat-slide-toggle
                  color="accent"
                  [(ngModel)]="teamInfo.allowLeaveSummary"
                  >Allow leave summary</mat-slide-toggle
                >
              </td>
              <td>
                <button
                  mat-icon-button
                  matTooltip="If you set it false only admin, hr and member himself/herself can view leave summry"
                >
                  <mat-icon color="accent">info</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <mat-slide-toggle
                  color="accent"
                  [(ngModel)]="teamInfo.restrictOutsideView"
                  >Restrict to view outside office events</mat-slide-toggle
                >
              </td>
              <td>
                <button
                  mat-icon-button
                  matTooltip="If set to true and you have members assign to different offices, user will be able to see events only within that office. Admin & HR will see all events."
                >
                  <mat-icon color="accent">info</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field appearance="outline" style="margin-top: 20px">
                  <mat-select
                    placeholder="Year End"
                    [(ngModel)]="teamInfo.yearEnd"
                  >
                    <mat-option *ngFor="let m of yearEndMonths" [value]="m">
                      {{ m }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <button
                  mat-icon-button
                  matTooltip="End of the month considered as financial year end for your team. Any accural leave will carry forward to next year."
                >
                  <mat-icon color="accent">info</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Start of the week</mat-label>
                  <mat-select [(ngModel)]="teamInfo.startOfWeek">
                    <mat-option *ngFor="let d of weekdays" [value]="d">
                      {{ d }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td>
                <div fxLayout="row">
                  <mat-checkbox
                    style="margin-right: 16px"
                    [checked]="checked(d)"
                    color="accent"
                    *ngFor="let d of weekdays"
                    (change)="valueChanged(d, $event)"
                    >{{ d.substring(0, 1) }}</mat-checkbox
                  >
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="leave-types-container">
          <h4 class="header mat-subheading-2">Manage Leave Types</h4>
          <mat-card
            *ngFor="
              let item of teamInfo.leaveTypes;
              let isLast = last;
              let i = index
            "
            class="leave-type-card"
          >
            <mat-card-content>
              <div class="leave-type-row">
                <app-emoji-picker
                  [selectedEmoji]="item.emoji || '📅'"
                  (emojiSelected)="updateEmoji(i, $event)"
                >
                </app-emoji-picker>

                <mat-form-field appearance="outline">
                  <mat-label>Leave Type</mat-label>
                  <input
                    matInput
                    [(ngModel)]="item.name"
                    placeholder="Enter leave type"
                    (keypress)="validateLeaveTypeInput($event)"
                  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Max Allowed</mat-label>
                  <mat-select [(ngModel)]="item.count">
                    <mat-option *ngFor="let m of leaveCounter" [value]="m">
                      {{ m == -1 ? "Unlimited" : m }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Carry-forward Limit</mat-label>
                  <mat-select [(ngModel)]="item.cap">
                    <mat-option
                      *ngFor="let m of leaveCounter.slice(1, item.count + 2)"
                      [value]="m"
                    >
                      {{ m == -1 ? "Unlimited" : m }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-slide-toggle
                  color="accent"
                  [(ngModel)]="item.accural"
                  matTooltip="Turn on to carry forward leaves to next year. You must set carry-forward limit as well."
                >
                  Carry-forward
                </mat-slide-toggle>
                <button
                  mat-icon-button
                  color="warn"
                  (click)="deleteLeaveType(i)"
                  matTooltip="Delete leave type"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </mat-card-content>
          </mat-card>
          <p class="new-type-message">
            You can customize with up to 10 leave types. 🚀 Instantly create
            events with your new leave types on the web app. 🤖 Our AI buddy
            needs about 8 hours to learn your new leave type for Slack chats.
            Need help? Drop us a line at hello@appstacksolutions.com
          </p>
          <button
            mat-fab
            color="accent"
            *ngIf="teamInfo.leaveTypes && teamInfo.leaveTypes.length <= 10"
            (click)="addNewLeaveType()"
            matTooltip="Add another leave type"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="leave-types-container"></div>
      <div class="manage-roles-container">
        <h4 class="header mat-subheading-2">Manage Roles</h4>

        <div fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="start start">
          <div fxFlex="100" fxFlex.gt-sm="calc(50% - 10px)">
            <h4>Admin Users</h4>
            <mat-form-field class="chip-list-field" appearance="outline">
              <mat-chip-list #adminList>
                <mat-chip
                  *ngFor="let m of adminMembers; let indx = index"
                  [removable]="removable"
                  (removed)="remove(m, indx, 'admin')"
                >
                  {{ m.name }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Add Admin Users"
                  #adminInput
                  [formControl]="adminCtrl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="adminList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                />
              </mat-chip-list>
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="selected($event, 'admin')"
              >
                <mat-option
                  *ngFor="let m of filteredAdminUsers | async"
                  [value]="m"
                >
                  {{ m.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div fxFlex="100" fxFlex.gt-sm="calc(50% - 10px)">
            <h4>HR Users</h4>
            <mat-form-field class="chip-list-field" appearance="outline">
              <mat-chip-list #hrList>
                <mat-chip
                  *ngFor="let m of hrMembers; let indx = index"
                  [removable]="removable"
                  (removed)="remove(m, indx, 'hr')"
                >
                  {{ m.name }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Add HR Users"
                  #hrInput
                  [formControl]="hrCtrl"
                  [matAutocomplete]="autohr"
                  [matChipInputFor]="hrList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                />
              </mat-chip-list>
              <mat-autocomplete
                #autohr="matAutocomplete"
                (optionSelected)="selected($event, 'hr')"
              >
                <mat-option
                  *ngFor="let m of filteredHRUsers | async"
                  [value]="m"
                >
                  {{ m.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div class="action-buttons">
          <button
            mat-raised-button
            color="primary"
            (click)="saveAdminSetting($event)"
            [disabled]="authService.getProgressIndicator() || isAdmin !== true"
          >
            Save
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card
    *ngIf="getRole() === 'admin' || getRole() === 'hr' || fromInstall"
    class="manage-users-card"
  >
    <mat-card-header style="display: flex; justify-content: center">
      <mat-card-title class="header mat-subheading-2">
        Manage Users
        <button mat-icon-button (click)="loadBotUsers()">
          <mat-icon>refresh</mat-icon>
        </button>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <p class="text-left">
        Below is the list of users who interacted with OfficeBot so far not
        everyone in your team. Visit this page later when you need to change
        approver of a member and make someone inactive.
      </p>
      <p class="text-left">
        NOTE: Your billing depends on number of active Bot users. If you
        inactivate a user, user will lose access to OfficeBot immediately but
        active user count will reduce in next billing period.
      </p>
      <div
        style="display: flex; justify-content: left; margin: 10px 0px 10px 0px"
      >
        <mat-slide-toggle
          color="accent"
          [(ngModel)]="showactive"
          (change)="filterActive(showactive)"
        >
          Show Only Active Users
        </mat-slide-toggle>
      </div>

      <table mat-table [dataSource]="botUsersFiltered" class="mat-elevation-z8">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let user">
            <img
              [src]="membersMap[user.userId]?.image_48 || 'assets/outline.png'"
              alt="User avatar"
              class="user-avatar"
            />
            {{ user.name }}
          </td>
        </ng-container>

        <!-- Active/Inactive Column -->
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef>Active/Inactive</th>
          <td mat-cell *matCellDef="let user">
            <mat-slide-toggle color="accent" [(ngModel)]="user.active">
            </mat-slide-toggle>
          </td>
        </ng-container>

        <!-- Timezone Column -->
        <ng-container matColumnDef="timezone">
          <th mat-header-cell *matHeaderCellDef>Timezone</th>
          <td mat-cell *matCellDef="let user">
            <mat-form-field appearance="outline">
              <mat-select [(ngModel)]="user.tz">
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Filter..."
                    noEntriesFoundLabel="No result"
                    [(ngModel)]="user.tz_filter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let tz of getTimezones(user.tz_filter)"
                  [value]="tz.name"
                  (click)="user.tz_offset = tz.offset"
                >
                  {{ tz.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Leave Approver Column -->
        <ng-container matColumnDef="approver">
          <th mat-header-cell *matHeaderCellDef>Leave Approver</th>
          <td mat-cell *matCellDef="let user">
            <mat-form-field appearance="outline">
              <mat-select [disabled]="!user.active" [(ngModel)]="user.approver">
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Filter..."
                    noEntriesFoundLabel="No result"
                    [(ngModel)]="user.filter"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let m of _filterBotUsers(user.filter)"
                  [value]="m.userId"
                >
                  {{ m.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Office Column (Only for GOLD plan) -->
        <ng-container matColumnDef="office">
          <th mat-header-cell *matHeaderCellDef>Office</th>
          <td mat-cell *matCellDef="let user">
            <mat-form-field appearance="outline">
              <mat-select [disabled]="!user.active" [(ngModel)]="user.officeId">
                <mat-option *ngFor="let office of offices" [value]="office.id">
                  {{ office.officeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <button
              *ngIf="user.officeId"
              mat-icon-button
              color="accent"
              (click)="user.officeId = undefined; user.deptId = undefined"
            >
              <mat-icon>highlight_off</mat-icon>
            </button> -->
          </td>
          <td></td>
        </ng-container>

        <!-- Department Column (Only for GOLD plan) -->
        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef>Department</th>
          <td mat-cell *matCellDef="let user">
            <mat-form-field appearance="outline">
              <mat-select
                [disabled]="!user.active || !user.officeId"
                [(ngModel)]="user.deptId"
              >
                <mat-option *ngFor="let dept of departments" [value]="dept.id">
                  {{ dept.officeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- <button *ngIf="user.deptId" mat-icon-button color="accent" (click)="user.deptId = undefined">
              <mat-icon>highlight_off</mat-icon>
            </button> -->
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-card-content>

    <mat-card-actions>
      <button
        mat-raised-button
        color="primary"
        (click)="saveUsersSetting($event)"
        [disabled]="authService.getProgressIndicator() || isAdmin !== true"
      >
        Save
      </button>
    </mat-card-actions>
  </mat-card>
</div>
