/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./demos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/material/card";
import * as i6 from "../header/header.component.ngfactory";
import * as i7 from "../header/header.component";
import * as i8 from "@angular/cdk/layout";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/common";
import * as i11 from "../footer/footer.component.ngfactory";
import * as i12 from "../footer/footer.component";
import * as i13 from "@angular/material/bottom-sheet";
import * as i14 from "./demos.component";
import * as i15 from "@angular/platform-browser";
var styles_DemosComponent = [i0.styles];
var RenderType_DemosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DemosComponent, data: {} });
export { RenderType_DemosComponent as RenderType_DemosComponent };
function View_DemosComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "mat-card", [["class", "video-card mat-card"], ["fxFlex.gt-xs", "25%"], ["fxFlex.xs", "50%"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 737280, null, 0, i3.FlexDirective, [i4.MediaMonitor, i1.ElementRef, [3, i3.LayoutDirective], i4.StyleUtils, i4.LAYOUT_CONFIG], { flexXs: [0, "flexXs"], flexGtXs: [1, "flexGtXs"] }, null), i1.ɵdid(2, 49152, null, 0, i5.MatCard, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i2.View_MatCardHeader_0, i2.RenderType_MatCardHeader)), i1.ɵdid(4, 49152, null, 0, i5.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i5.MatCardTitle, [], null, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, 0, 6, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i5.MatCardContent, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "video", [["controls", ""], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your browser does not support HTML5 video. "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "50%"; var currVal_1 = "25%"; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.video; _ck(_v, 11, 0, currVal_3); var currVal_4 = _v.context.$implicit.description; _ck(_v, 14, 0, currVal_4); }); }
export function View_DemosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i6.View_HeaderComponent_0, i6.RenderType_HeaderComponent)), i1.ɵdid(1, 4243456, null, 0, i7.HeaderComponent, [i8.BreakpointObserver], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "demo-page"], ["fxLayout.xs", "column"], ["fxLayoutAlign", "center"], ["fxLayoutGap", "0.5%"]], null, null, null, null, null)), i1.ɵdid(3, 737280, null, 0, i3.LayoutDirective, [i4.MediaMonitor, i1.ElementRef, i4.StyleUtils], { layout: [0, "layout"], layoutXs: [1, "layoutXs"] }, null), i1.ɵdid(4, 1785856, null, 0, i3.LayoutGapDirective, [i4.MediaMonitor, i1.ElementRef, [6, i3.LayoutDirective], i1.NgZone, i9.Directionality, i4.StyleUtils], { gap: [0, "gap"] }, null), i1.ɵdid(5, 737280, null, 0, i3.LayoutAlignDirective, [i4.MediaMonitor, i1.ElementRef, [6, i3.LayoutDirective], i4.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DemosComponent_1)), i1.ɵdid(7, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(9, 114688, null, 0, i12.FooterComponent, [i13.MatBottomSheet], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row wrap"; var currVal_1 = "column"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "0.5%"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "center"; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.videos; _ck(_v, 7, 0, currVal_4); _ck(_v, 9, 0); }, null); }
export function View_DemosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-demos", [], null, null, null, View_DemosComponent_0, RenderType_DemosComponent)), i1.ɵdid(1, 114688, null, 0, i14.DemosComponent, [i15.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DemosComponentNgFactory = i1.ɵccf("app-demos", i14.DemosComponent, View_DemosComponent_Host_0, {}, {}, []);
export { DemosComponentNgFactory as DemosComponentNgFactory };
