<app-header></app-header>
<!-- <div>
  <p style="background-color: #03a9f4;padding: 20px;color: white;">We are upgrading our system. OfficeBot may be unavailable from 7:30PM EST to 9:30PM EST today March 8,2019</p>
</div> -->

<div
  [@openClose]="isOpen ? 'open' : 'closed'"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutWrap
  fxLayoutAlign="center"
  class="message-wrapper"
>
  <div class="into-image" fxFlex="50%" fxFlex.xs="100%">
    <img *ngIf="slider" [src]="slider.image" />
  </div>
  <div
    *ngIf="slider"
    class="into-message"
    fxFlex="50%"
    fxFlex.xs="100%"
    style="padding-top: 50px"
  >
    <div fxLayout="column" fxLayoutAlign="left" class="side-stack">
      <!-- <img class="slack-card" [src]="slider.image2"> -->
      <h3>{{ slider.title }}</h3>
      <p>
        {{ slider.subTitle }}
      </p>
      <div fxLayout="row">
        <a
          href="https://slack.com/oauth/authorize?client_id=187674243633.272542434992&scope=bot,users:read,users:read.email&redirect_uri={{
            redirectUrl
          }}"
        >
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcset="
              https://platform.slack-edge.com/img/add_to_slack.png    1x,
              https://platform.slack-edge.com/img/add_to_slack@2x.png 2x
            "
        /></a>
      </div>

      <!-- <p color="warn"  [@openClose]="isOpen ? 'open' : 'closed'">{{displayedMessage}}</p> -->
    </div>
  </div>
</div>

<div class="card-panel">
  <div class="card roster">
    <img src="assets/s6.png" />
  </div>

  <div
    class="card"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="left"
    [ngClass]="{ light: i % 2 == 1, dark: i % 2 == 0 }"
    *ngFor="let feature of features; index as i"
  >
    <div fxFlex="50%" fxFlex.xs="100%" *ngIf="i % 2 == 1">
      <img [src]="feature.image" />
    </div>
    <div fxFlex="50%" fxFlex.xs="100%">
      <h3>{{ feature.title }}</h3>
      <p [innerHTML]="feature.subTitle"></p>
    </div>
    <div fxFlex="50%" fxFlex.xs="100%" *ngIf="i % 2 == 0">
      <img [src]="feature.image" />
    </div>
  </div>

  <div class="card">
    <h4>Watch The Demo</h4>
    <video class="demo-video" controls>
      <source [src]="'assets/roster.mp4'" type="video/mp4" />

      Your browser does not support HTML5 video.
    </video>
  </div>
</div>

<!-- <div class="technology-panel">
    <h3 class="header">Technology powering us <i style="color:green" class="fa fa-2x fa-bolt"></i></h3>
    <h4 class="sub-header">We are 100% on cloud and serverless</h4>
    <div>
      <img src="assets/aws-logo.png"/>
      <img src="assets/serverless.png"/>
    </div>
</div> -->
