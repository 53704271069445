<app-header></app-header>

<div
  [fxLayout]="'row wrap'"
  fxLayoutGap="0.5%"
  fxLayout.xs="column"
  fxLayoutAlign="center"
  class="demo-page"
>
  <mat-card
    fxFlex.xs="50%"
    fxFlex.gt-xs="25%"
    class="video-card"
    *ngFor="let v of videos"
  >
    <mat-card-header>
      <mat-card-title>{{ v.title }}</mat-card-title>
    </mat-card-header>

    <!-- <iframe width="100%" height="315" [src]="sanitizer.bypassSecurityTrustResourceUrl(v.url)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    <mat-card-content>
      <video width="100%" controls>
        <source [src]="v.video" type="video/mp4" />

        Your browser does not support HTML5 video.
      </video>
      <p>
        {{ v.description }}
      </p>
    </mat-card-content>
    <!-- <mat-card-actions>
          <button mat-button>SHARE</button>
        </mat-card-actions> -->
  </mat-card>
</div>
<app-footer></app-footer>
