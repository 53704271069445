import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../../shared/api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-payment-history-dialog',
  templateUrl: './payment-history-dialog.component.html',
  styleUrls: ['./payment-history-dialog.component.css']
})
export class PaymentHistoryDialogComponent implements OnInit {

  transactions:any[];
  subcriptionProcessing:boolean = false;
 
  constructor(
    private apiService:ApiService,
    public dialogRef: MatDialogRef<PaymentHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close({action: "no"});
  }

  ngOnInit() {
    this.subcriptionProcessing = true;
    this.apiService.getTransHistory()
        .subscribe((data)=>{
          this.subcriptionProcessing = false;
          this.transactions = data;
            
        },
        (error:any)=>{
          this.subcriptionProcessing = false;
        
        },
        ()=>{
          console.log("get card api completed");
        })
  }

}
