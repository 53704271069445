<mat-progress-bar
  *ngIf="authService.getProgressIndicator()"
  mode="indeterminate"
  color="primary"
></mat-progress-bar>
<mat-card style="margin-top: 20px">
  <h4 class="header mat-subheading-2">Your Event Calendar</h4>
  <!-- <h5 class="header mat-subheading-2 muted">
    Only current month's past events are shown
  </h5> -->
  <!-- <p>
    {{myEvents|json}}
  </p>
  <p>
    {{events|json}}
  </p> -->

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="0.5%"
    fxLayoutAlign="center"
  >
    <button
      mat-raised-button
      color="primary"
      mwlCalendarPreviousView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="activeDayIsOpen = false"
    >
      Previous
    </button>
    <button
      mat-raised-button
      color="accent"
      mwlCalendarToday
      [(viewDate)]="viewDate"
    >
      Today
    </button>
    <button
      mat-raised-button
      color="primary"
      mwlCalendarNextView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="activeDayIsOpen = false"
    >
      Next
    </button>
  </div>
  <h3>{{ viewDate | calendarDate : view + "ViewTitle" : "en" }}</h3>

  <mwl-calendar-month-view
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [activeDayIsOpen]="activeDayIsOpen"
    (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-month-view>
</mat-card>
<mat-card appearance="outlined">
  <h4 class="header mat-subheading-2">Create New Event</h4>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="0.5%"
    fxLayoutAlign="center"
    style="margin-bottom: 20px"
  >
    <mat-radio-group [(ngModel)]="event.duration">
      <mat-radio-button value="single" color="accent" style="margin-right: 1rem"
        >Single Day</mat-radio-button
      >
      <mat-radio-button
        value="multiple"
        color="accent"
        style="margin-right: 1rem"
        >Multiple Days</mat-radio-button
      >
      <mat-radio-button
        matTooltip="Create repeatative events for a given duration."
        value="recurring"
        color="accent"
        >Recurring</mat-radio-button
      >
    </mat-radio-group>
  </div>
  <div
    *ngIf="event.duration == 'recurring'"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="0.5%"
    fxLayoutAlign="center"
  >
    <h6 class="page-card-title">
      Recurring will create event on selected days of every week from start date
      until end date.
    </h6>
  </div>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="0.5%"
    fxLayoutAlign="center"
  >
    <mat-form-field appearance="outline" fxFlex="20%" color="accent">
      <mat-select placeholder="Event Type" [(ngModel)]="event.type">
        <mat-option
          *ngFor="let eventType of appliedEventTypes"
          [value]="eventType"
        >
          {{ eventType }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      fxFlex="20%"
      *ngIf="event.duration == 'recurring'"
      color="accent"
    >
      <mat-select placeholder="Days" [(ngModel)]="event.weekDays" multiple>
        <mat-option *ngFor="let weekDay of daysOfWeek" [value]="weekDay.tag">
          {{ weekDay.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="20%" color="accent">
      <mat-label>{{ event.duration == "single" ? "On" : "From" }}</mat-label>
      <input
        matInput
        mwlFlatpickr
        [(ngModel)]="event.fromDate"
        dateFormat="Y-m-d"
        altFormat="F j, Y"
        [altInput]="true"
        placeholder="From"
      />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      fxFlex="20%"
      *ngIf="event.duration == 'multiple' || event.duration == 'recurring'"
      color="accent"
    >
      <mat-label>To</mat-label>
      <input
        matInput
        mwlFlatpickr
        [(ngModel)]="event.toDate"
        dateFormat="Y-m-d"
        altFormat="F j, Y"
        [altInput]="true"
        [minDate]="event.fromDate"
        [disabled]="!event.fromDate"
        placeholder="To"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="20%" color="accent">
      <mat-label>Comment</mat-label>
      <input
        matInput
        placeholder="Comment (optional)"
        (keypress)="validateCommentInput($event)"
        [(ngModel)]="event.comment"
      />
    </mat-form-field>
  </div>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="0.5%"
    fxLayoutAlign="center"
  >
    <button
      mat-raised-button
      color="primary"
      (click)="createEvent()"
      [disabled]="!validateInput()"
    >
      Create
    </button>
  </div>
</mat-card>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
      day.badgeTotal
    }}</span>
    <span class="cal-day-number">{{
      day.date | calendarDate : "monthViewDayNumber" : locale
    }}</span>
  </div>
  <small style="margin: 5px"
    >There are {{ day.events.length }} events on this day</small
  >
</ng-template>
