import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";

@Component({
  selector: "app-emoji-picker",
  templateUrl: "./emoji-picker.component.html",
  styleUrls: ["./emoji-picker.component.scss"],
})
export class EmojiPickerComponent implements OnInit {
  @Input() selectedEmoji: string = "📅";
  @Output() emojiSelected = new EventEmitter<string>();

  emojiList = [
    { icon: "📅", code: ":date:", name: "Calendar", type: "PTO" },
    { icon: "🏖️", code: ":beach_with_umbrella:", name: "Vacation" },
    { icon: "✈️", code: ":airplane:", name: "Paid Time Off" },
    { icon: "🤒", code: ":face_with_thermometer:", name: "Sick Leave" },
    { icon: "🏥", code: ":hospital:", name: "Medical Appointment" },
    { icon: "💻", code: ":computer:", name: "Work From Home" },
    { icon: "🏢", code: ":office:", name: "In Office" },
    { icon: "📍", code: ":round_pushpin:", name: "On-Site" },
    { icon: "⚖️", code: ":scales:", name: "Jury Duty", type: "JURY" },
    { icon: "🧳", code: ":luggage:", name: "Business Trip", type: "BIZ" },
    { icon: "👶", code: ":baby:", name: "Parental Leave" },
    { icon: "📚", code: ":books:", name: "Training" },
    { icon: "🎤", code: ":microphone:", name: "Conference", type: "CONF" },
    { icon: "🧘", code: ":person_in_lotus_position:", name: "Mental Health Day" },
    { icon: "👨‍👩‍👧‍👦", code: ":family:", name: "Family Care" },
    { icon: "💐", code: ":bouquet:", name: "Bereavement" },
    { icon: "🎉", code: ":tada:", name: "Public Holiday" },
    { icon: "🕯️", code: ":candle:", name: "Religious Holiday" },
    { icon: "📱", code: ":slack_call:", name: "On Call" },
    { icon: "⏱️", code: ":stopwatch:", name: "Comp Time", type: "COMP" },
    { icon: "◐", code: ":last_quarter_moon:", name: "Half Day" },
  ];

  constructor() {}

  ngOnInit() {}
  selectEmoji(emoji: string) {
    this.selectedEmoji = emoji;
    //this.emojiSelected.emit(emoji);
    //find code from emojiList for the given emoji icon
    let code = this.emojiList.find((e) => e.icon === emoji).code;
    this.emojiSelected.emit(code);
  }
  renderIconFromCode(code: string) {
    try {
      let emoji = this.emojiList.find((e) => e.code === code).icon;
      return emoji;
    } catch (error) {
      return this.emojiList[0].icon;
    }
  }
}
