<div class="shift-dialog-container">
  <div class="shift-dialog-header">
    <div class="user-info" *ngIf="data.member">
      <img class="user-avatar" [src]="data.member.image_48" alt="User avatar" />
      <div class="wage-rate">
        <mat-form-field appearance="outline">
          <mat-label>Wage Rate</mat-label>
          <input
            matInput
            [options]="options"
            currencyMask
            [(ngModel)]="data.slot.wageRate"
            class="wage-input"
          />
          <mat-icon matPrefix class="currency-icon">attach_money</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>

  <mat-dialog-content class="shift-dialog-content">
    <div class="shift-form">
      <!-- Shift Type -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Shift Type</mat-label>
        <mat-select [(ngModel)]="data.slot.shiftType" required>
          <mat-option
            *ngFor="let shiftType of data.shiftTypes"
            [value]="shiftType"
          >
            {{ shiftType }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Time Selection -->
      <div class="time-selection">
        <mat-form-field appearance="outline" class="time-field">
          <mat-label>Start Time</mat-label>
          <input
            matInput
            type="text"
            mwlFlatpickr
            [(ngModel)]="data.slot.fromDate"
            [convertModelValue]="true"
            altFormat="G:i K"
            dateFormat="Y-m-dTH:i"
            [altInput]="true"
            [noCalendar]="true"
            [enableTime]="true"
            [allowInput]="false"
            (ngModelChange)="setValue()"
          />
          <mat-icon matSuffix>schedule</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="time-field">
          <mat-label>End Time</mat-label>
          <input
            matInput
            type="text"
            mwlFlatpickr
            [(ngModel)]="data.slot.toDate"
            [convertModelValue]="true"
            altFormat="G:i K"
            dateFormat="Y-m-dTH:i"
            [altInput]="true"
            [noCalendar]="true"
            [enableTime]="true"
            [allowInput]="false"
            (ngModelChange)="setValue()"
          />
          <mat-icon matSuffix>schedule</mat-icon>
        </mat-form-field>
      </div>

      <!-- Break Selection -->
      <div class="break-section">
        <div class="break-header">
          <h3>
            Break Duration
            <mat-icon
              class="info-icon"
              matTooltip="Break increases productivity"
              color="primary"
              >info</mat-icon
            >
          </h3>
        </div>

        <mat-button-toggle-group
          [(ngModel)]="data.slot.breakTime"
          class="break-toggle"
        >
          <mat-button-toggle *ngFor="let m of breakSlots" [value]="m">
            {{ m }}min
          </mat-button-toggle>
        </mat-button-toggle-group>

        <mat-checkbox
          color="primary"
          [(ngModel)]="data.slot.paidBreak"
          class="paid-break-checkbox"
        >
          Paid Break
        </mat-checkbox>

        <div class="total-hours">
          <span>Total Hours:</span>
          <strong>{{ getDiff(data.slot) }}h</strong>
        </div>
      </div>

      <!-- Comment Section -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Additional Notes</mat-label>
        <input
          matInput
          [(ngModel)]="data.slot.sComment"
          (keypress)="validateCommentInput($event)"
          placeholder="Optional: Add details like lunch break timing"
        />
        <mat-icon matSuffix>comment</mat-icon>
      </mat-form-field>

      <!-- Repeat Section -->
      <div class="repeat-section" *ngIf="!data.slot.id">
        <h3>Repeats On</h3>
        <div class="weekday-toggles">
          <div class="weekday-buttons">
            <mat-button-toggle
              *ngFor="let d of repeatedOn; let i = index"
              [checked]="d.checked"
              (change)="d.checked = $event.source.checked"
            >
              {{ d.day.format("dddd").substring(0, 1) }}
            </mat-button-toggle>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()" class="cancel-button">
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!data.slot.shiftType"
      (click)="onYesClick()"
      class="submit-button"
    >
      {{ data.slot.id ? "Update" : "Generate" }}
    </button>
  </mat-dialog-actions>
</div>
