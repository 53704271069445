import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from "@angular/core";
import { ApiService } from "../../shared/api.service";
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from "date-fns";
import { Subject } from "rxjs";

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  DAYS_OF_WEEK,
} from "angular-calendar";

import * as moment from "moment-timezone";
import { AuthService } from "../../shared/auth.service";
import { Angulartics2 } from "angulartics2";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "src/app/shared/confirm-dialog/confirm-dialog.component";
import { ModifyLeaveComponent } from "src/app/shared/modify-leave/modify-leave.component";
import { title } from "process";

// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
moment.updateLocale("en", {
  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0,
  },
});

const colors: any = {
  red: {
    primary: "rgba(218, 21, 6, 1)",
    secondary: "rgba(218, 21, 6, 0.5)",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};

@Component({
  selector: "app-team-events",
  templateUrl: "./team-events.component.html",
  styleUrls: ["./team-events.component.scss"],
})
export class TeamEventsComponent implements OnInit {
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  role: string = "member";
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  currrentMonth: number = 0;
  actions: CalendarEventAction[] = [
    {
      label: `<i  style="color:red" class="fa fa-fw fa-edit blue-icon" ></i>`,
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.modifyEvent(event);
      },
    },
    {
      label: `<i  style="color:red" class="fa fa-fw fa-trash red-icon" ></i>`,
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.deleteEvent(event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  teamInfo: any = {};
  userInfo: any = {};
  planId: string = "BRONZE";

  event: any = { duration: "single" };

  activeDayIsOpen: boolean = false;

  appliedEventTypes: string[] = [];
  daysOfWeek: any[] = [
    { tag: "mon", description: "Monday" },
    { tag: "tue", description: "Tuesday" },
    { tag: "wed", description: "Wednesday" },
    { tag: "thu", description: "Thursday" },
    { tag: "fri", description: "Friday" },
    { tag: "sat", description: "Saturday" },
    { tag: "sun", description: "Sunday" },
  ];

  public members: any[] = [];
  public membersForEvents: any[] = [];
  public memberMap: any = {};
  public selectedUserId: string = "-1";

  constructor(
    private apiService: ApiService,
    public authService: AuthService,
    private angulartics2: Angulartics2,
    public dialog: MatDialog
  ) {
    if (localStorage.getItem("teamInfo")) {
      this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
      // this.appliedEventTypes = apiService.getAppliedLeaveTypes(
      //   this.teamInfo.quota
      // );
      this.appliedEventTypes = apiService.getTeamEventTypes(this.teamInfo);
    }
    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
    if (localStorage.getItem("planId")) {
      this.planId = localStorage.getItem("planId").toUpperCase();
    }
    if (localStorage.getItem("role")) {
      this.role = localStorage.getItem("role");
    }
  }

  deleteEvent(event): void {
    console.log(event.userId, this.userInfo.user.id);
    if (!this.authService.isOfficebottAdmin() && event.userId !== this.userInfo.user.id) {
      this.apiService.toast("Only admin can delete other's events", "error");
      return;
    }
    let that = this;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: {
        title: "Delete event",
        content: "You are going to delete this event. Do you want to continue?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed ', result);
      if (result && result.action == "yes") {
        that.apiService.deleteOthersEvents(event).subscribe(
          (data) => {
            that.apiService.toast("Event has been deleted successfully.", "success");
            let index = that.events.findIndex((item) => item.id == event.id);
            that.events.splice(index, 1);
            that.refresh.next();
          },
          (error) => {},
          () => {
            console.log("api completed");
          }
        );
      }
    });
  }

  modifyEvent(event): void {
    let that = this;
    const dialogRef = this.dialog.open(ModifyLeaveComponent, {
      width: "350px",
      data: { message: "Select Leave Type", eventTypes: this.appliedEventTypes },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed ', result);
      if (result && result.action == "yes") {
        console.log("Event Type", result.newEventType);
        that.apiService.modifyEvents(event.userId, event.id, result.newEventType).subscribe(
          (data) => {
            that.apiService.toast("Your event has been modified successfully.", "success");
            let index = that.events.findIndex((item) => item.id == event.id);
            that.events[index]["title"] = "@" + this.authService.getUsername(event.userId) + " " + result.newEventType;
            that.refresh.next();
          },
          (error) => {},
          () => {
            console.log("api completed");
          }
        );
      }
    });
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent("Dropped or resized", event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  addEvent(): void {
    this.events.push({
      title: "New event",
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
      color: colors.red,
      draggable: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
    });
    this.refresh.next();
  }

  async createEvent($event) {
    this.angulartics2.eventTrack.next({
      action: "EventOnBehalf",
      properties: { category: "Button" },
    });

    this.event.tz_offset = JSON.parse(localStorage.getItem("userInfo")).user.tz_offset;
    this.event.teamId = localStorage.getItem("teamId");

    if (
      localStorage.getItem("planId") === "gold" &&
      this.teamInfo.autoApprove !== undefined &&
      this.teamInfo.autoApprove === false
    ) {
      this.event.askApproval = true;
      this.event.botToken = localStorage.getItem("B_T");
      if (this.userInfo.approver) {
        this.event.approverId = this.userInfo.approver;
      } else {
        this.event.approverId = this.teamInfo.userId;
      }
    } else {
      this.event.askApproval = false;
    }
    if (this.event.userId !== "-1") {
      this.event.targetUsers = [this.event.userId];
    } else {
      this.event.targetUsers = this.members.map((m) => {
        return m.id;
      });
    }
    const data = await this.apiService.postMyEvents(this.event).toPromise();
    if (this.event.askApproval) {
      this.apiService.toast("Your request has been sent for approval.", "success");
    } else {
      this.apiService.toast("Your event has been created succeefully.", "success");
    }
    await this.loadEvents();
  }

  botUserMap = {};
  async ngOnInit() {
    if (localStorage.getItem("members")) {
      //this.members = JSON.parse(localStorage.getItem("members"));
      let botUsers = await this.apiService.getAllBotUsers().toPromise();
      this.members = botUsers;
      if (this.authService.isAdmin()) {
        this.membersForEvents = [...botUsers];
      } else {
        this.membersForEvents = [
          ...botUsers.filter((m) => {
            return m.userId == localStorage.getItem("userId");
          }),
        ];
      }

      //console.log("BOT USERS", botUsers);
      botUsers = botUsers
        .filter((m) => {
          return m.inActive !== true;
        })
        .map((m) => {
          this.botUserMap[m.userId] = m;
          return m.userId;
        });

      // this.members = this.members.filter((m)=>{
      //    return botUsers.includes(m.id);
      // })

      this.members.forEach((item) => {
        this.memberMap[item.id] = item;
      });
    }
    console.log("members", this.members);
    console.log("membersForEvents", this.membersForEvents);
    await this.loadEvents();
  }

  async next() {
    console.log("next ");
    this.currrentMonth++;
    await this.loadEvents();
  }
  async prev() {
    console.log("prev ");
    this.currrentMonth--;
    await this.loadEvents();
  }
  async loadEvents() {
    const from = moment().add(this.currrentMonth, "months").startOf("month").format("YYYY-MM-DD");
    const to = moment().add(this.currrentMonth, "months").endOf("month").format("YYYY-MM-DD");
    let data = await this.apiService.getTeamEventsRange(from, to).toPromise();
    let that = this;
    this.events = [];
    data.forEach((element) => {
      //console.log(moment.tz(element.fromDate, "Europe/London"));
      //console.log(moment.tz(element.toDate, "Europe/London"));
      let name = this.authService.getUsername(element.userId);
      if (!name) {
        name = this.botUserMap[element.userId].displayName
          ? this.botUserMap[element.userId].displayName
          : this.botUserMap[element.userId].name;
      }
      let event = {
        id: element.id,
        userId: element.userId,
        start: this.apiService.createDateAsUTC(new Date(element.fromDate)),
        end: this.apiService.createDateAsUTC(new Date(element.toDate)),
        title: "@" + name + " " + element.type,
        color: element.type == "PTO" ? colors.red : colors.blue,
        actions: that.actions,
        allDay: true,
        draggable: false,
      };
      if (element.comment) {
        element.comment = element.comment.replace(/[^a-zA-Z0-9_,]/g, " ");
        if (element.comment.charAt(0) == "“") {
          event["title"] += ": " + element.comment.substring(1, element.comment.length - 1);
        } else {
          event["title"] += ": " + element.comment;
        }
      }
      //console.log(event);
      this.events.push(event);
    });
    this.refresh.next();
  }

  getSelectedMemberName(userId: string): string {
    if (this.selectedUserId === "-1") {
      return "Everyone";
    }
    const selectedMember = this.members.find((member) => member.userId === userId);
    return selectedMember ? selectedMember.displayName || selectedMember.name : "";
  }

  getSelectedMemberNameForCreateEvent(userId: string): string {
    const selectedMember = this.members.find((member) => member.userId === userId);
    return selectedMember ? selectedMember.displayName || selectedMember.name : "";
  }
  filterEvents = function () {
    //console.log(this.selectedUserId);
    if (this.selectedUserId == "-1") {
      return this.events;
    } else {
      return this.events.filter((item) => {
        return item.userId == this.selectedUserId;
      });
    }
  };
  validateInput = function (): boolean {
    let valid = true;
    if (
      !this.event.userId ||
      !this.event.type ||
      !this.event.fromDate ||
      (this.event.duration == "multiple" && !this.event.toDate) ||
      (this.event.duration == "recurring" && !this.event.toDate)
    ) {
      valid = false;
    }
    if (this.event.duration == "recurring" && !(this.event.weekDays && this.event.weekDays.length > 0)) {
      valid = false;
    }
    return valid;
  };
}
