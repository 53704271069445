var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ElementRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocomplete } from "@angular/material";
import { map, startWith } from "rxjs/operators";
import { ApiService } from "src/app/shared/api.service";
import { AuthService } from "src/app/shared/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { timezones } from "src/app/shared/timezones";
import * as moment from "moment-timezone";
import { allowedLeaveTypePattern, leavTypeObjectToArray } from "src/app/shared/utility";
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(route, router, apiService, authService) {
        //delete this.teamInfo.appRoles;
        var _this = this;
        this.route = route;
        this.router = router;
        this.apiService = apiService;
        this.authService = authService;
        this.teamInfo = {};
        this.leaveCounter = [];
        this.yearEndMonths = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        this.weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        //fruitCtrl = new FormControl();
        //filteredFruits: Observable<string[]>;
        //fruits: string[] = ['Lemon'];
        //allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
        this.timezones = [];
        this.members = [];
        this.membersMap = {};
        this.adminMembers = [];
        this.hrMembers = [];
        this.adminCtrl = new FormControl();
        this.hrCtrl = new FormControl();
        this.botUsers = [];
        //@ViewChild('autohr') matAutocompletehr: MatAutocomplete;
        this.planCode = "BRONZE";
        this.offices = [];
        this.departments = [];
        this.fromInstall = false;
        this.displayedColumns = ["name", "active", "timezone", "approver"];
        this.showactive = false;
        this.validateLeaveTypeInput = function (event) {
            var inputChar = String.fromCharCode(event.charCode);
            if (!allowedLeaveTypePattern.test(inputChar)) {
                event.preventDefault();
            }
        };
        this.botUsersFiltered = new MatTableDataSource([]);
        for (var i = -1; i <= 50; i++) {
            this.leaveCounter.push(i);
        }
        this.filteredAdminUsers = this.adminCtrl.valueChanges.pipe(startWith(null), map(function (name) { return (name ? _this._filter(name) : _this.members.slice()); }));
        this.filteredHRUsers = this.hrCtrl.valueChanges.pipe(startWith(null), map(function (name) { return (name ? _this._filter(name) : _this.members.slice()); }));
        //console.log('filteredAdminUsers',this.filteredAdminUsers);
        this.userId = localStorage.getItem("userId");
    }
    SettingsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that, zones, tzLabel, key, data, members, allOffices, botUser;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        that = this;
                        zones = [];
                        tzLabel = moment.tz.guess();
                        for (key in timezones.countries) {
                            // this.countries.push({
                            //   code:key,
                            //   name:timezones.countries[key].name
                            // })
                            timezones.countries[key].zones.forEach(function (m) {
                                if (zones.indexOf(m) === -1) {
                                    zones.push(m);
                                }
                            });
                        }
                        zones = zones.sort();
                        //generate timezone
                        zones.map(function (m) {
                            var tz = moment.tz(m).utcOffset();
                            _this.timezones.push({ offset: tz * 60, name: m });
                        });
                        if (!!localStorage.getItem("members")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.authService.getMembers().toPromise()];
                    case 1:
                        data = _a.sent();
                        if (data.ok) {
                            members = data.members
                                .filter(function (user) {
                                return !user.is_bot && user.name != "slackbot";
                            })
                                .map(function (item) {
                                return {
                                    id: item.id,
                                    name: item.profile.display_name ? item.profile.display_name : item.name,
                                    displayName: item.profile.display_name,
                                    image_48: item.profile.image_48,
                                    tz_offset: item.tz_offset,
                                };
                            });
                            localStorage.setItem("members", JSON.stringify(members));
                        }
                        _a.label = 2;
                    case 2:
                        this.members = JSON.parse(localStorage.getItem("members"));
                        this.members.map(function (m) {
                            _this.membersMap[m.id] = m;
                            m.filter = "";
                        });
                        console.log("membersMap", this.membersMap);
                        this.authService.setProgressIndicator(true);
                        this.route.params.subscribe(function (params) {
                            var install = params["install"];
                            //enable only if user is admin , not just install
                            // if(install=='y'){
                            //   that.fromInstall = false;
                            // }
                        });
                        this.loadBotUsers();
                        return [4 /*yield*/, this.apiService.getAllOffices().toPromise()];
                    case 3:
                        allOffices = _a.sent();
                        this.offices = allOffices.filter(function (m) {
                            return m.type === "office";
                        });
                        //add None at the beginning of offices
                        this.offices.unshift({ officeName: "None", id: "-1" });
                        this.departments = allOffices.filter(function (m) {
                            return m.type === "department";
                        });
                        this.departments.unshift({ officeName: "None", id: "-1" });
                        return [4 /*yield*/, this.apiService.getBotUserInfo().toPromise()];
                    case 4:
                        botUser = _a.sent();
                        this.teamInfo = botUser.teamInfo;
                        if (!this.teamInfo.quota["SICK"]) {
                            this.teamInfo.quota["SICK"] = {
                                accural: false,
                                count: -1,
                            };
                        }
                        this.teamInfo.startOfWeek = this.teamInfo.startOfWeek || "Monday";
                        this.teamInfo.weekDays = this.teamInfo.weekDays || ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
                        //generate leaveTypes if not there
                        if (!this.teamInfo.leaveTypes) {
                            this.teamInfo.leaveTypes = leavTypeObjectToArray(this.teamInfo.quota);
                        }
                        if (localStorage.getItem("planId")) {
                            this.planCode = localStorage.getItem("planCode").toUpperCase();
                            if (["STANDARD"].includes(this.planCode)) {
                                this.displayedColumns.push("office", "department");
                            }
                        }
                        if (this.teamInfo.appRoles) {
                            if (this.teamInfo.appRoles["admin"] && this.teamInfo.appRoles["admin"].length > 0) {
                                this.adminMembers = this.teamInfo.appRoles["admin"].map(function (id) {
                                    return _this.members.filter(function (m) {
                                        return m.id == id;
                                    })[0];
                                });
                            }
                            if (this.teamInfo.appRoles["hr"] && this.teamInfo.appRoles["hr"].length > 0) {
                                this.hrMembers = this.teamInfo.appRoles["hr"].map(function (id) {
                                    return _this.members.filter(function (m) {
                                        return m.id == id;
                                    })[0];
                                });
                            }
                        }
                        else {
                            this.adminMembers = this.members.filter(function (m) {
                                return m.id == _this.teamInfo.userId;
                            });
                            this.hrMembers = [];
                        }
                        this.authService.setProgressIndicator(false);
                        this.isAdmin = this.authService.isOfficebottAdmin();
                        return [2 /*return*/];
                }
            });
        });
    };
    SettingsComponent.prototype.filterActive = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.showactive === true) {
                    this.botUsersFiltered.data = this.botUsers.filter(function (m) {
                        return m.active === true;
                    });
                }
                else {
                    this.botUsersFiltered.data = this.botUsers;
                }
                return [2 /*return*/];
            });
        });
    };
    SettingsComponent.prototype.loadBotUsers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        that = this;
                        _a = this;
                        return [4 /*yield*/, this.apiService.getAllBotUsers().toPromise()];
                    case 1:
                        _a.botUsers = _b.sent();
                        console.log("all bot users", this.botUsers);
                        this.botUsers.map(function (m) {
                            if (!m.approver) {
                                m.approver = m.token.userId;
                            }
                            m.active = !m.inActive;
                            m.deleted = m.inActive;
                            m.name = that.membersMap[m.userId] ? that.membersMap[m.userId].name : m.name;
                            m.filter = "";
                        });
                        console.log("bot users", this.botUsers);
                        this.botUsersFiltered.data = this.botUsers;
                        return [2 /*return*/];
                }
            });
        });
    };
    SettingsComponent.prototype._filterBotUsers = function (txt) {
        if (txt) {
            return this.botUsers.filter(function (m) {
                return m.name.toLowerCase().includes(txt.toLowerCase());
            });
        }
        else {
            return this.botUsers;
        }
    };
    SettingsComponent.prototype.add = function (event) {
        //debugger
        console.log("add", event);
        var input = event.input;
        var value = event.value;
        // Add our fruit
        // if ((value || '').trim()) {
        //   this.adminMembers.push({
        //     id:Math.random(),
        //     name:value.trim()
        //   });
        // }
        // // Reset the input value
        // if (input) {
        //   input.value = '';
        // }
        // this.adminCtrl.setValue(null);
    };
    SettingsComponent.prototype.remove = function (m, indx, type) {
        if (type === "admin") {
            this.adminMembers.splice(indx, 1);
        }
        else {
            this.hrMembers.splice(indx, 1);
        }
    };
    SettingsComponent.prototype.selected = function (event, type) {
        console.log("selected", type);
        if (type === "admin") {
            var indx = this.adminMembers
                .map(function (e) {
                return e.id;
            })
                .indexOf(event.option.value.id);
            if (indx === -1) {
                this.adminMembers.push(event.option.value);
            }
            this.adminInput.nativeElement.value = "";
            this.adminCtrl.setValue(null);
        }
        else {
            var indx = this.hrMembers
                .map(function (e) {
                return e.id;
            })
                .indexOf(event.option.value.id);
            if (indx === -1) {
                this.hrMembers.push(event.option.value);
            }
            this.hrInput.nativeElement.value = "";
            this.hrCtrl.setValue(null);
        }
    };
    SettingsComponent.prototype._filter = function (value) {
        console.log("_filter", value);
        if (typeof value === "string") {
            return this.members.filter(function (fruit) { return fruit.name.toLowerCase().includes(value.toLowerCase()); });
        }
        else {
            return this.members.filter(function (fruit) { return fruit.name.toLowerCase().includes(value.name.toLowerCase()); });
        }
    };
    SettingsComponent.prototype.getRole = function () {
        var role = "member";
        if (!this.teamInfo) {
            return role;
        }
        var appRoles = this.teamInfo["appRoles"];
        if (appRoles) {
            for (var key in appRoles) {
                if (appRoles[key].indexOf(this.userId) != -1 && role !== "admin") {
                    role = key;
                }
            }
        }
        else if (this.teamInfo.userId == this.userId) {
            role = "admin";
        }
        return role;
    };
    SettingsComponent.prototype.saveUsersSetting = function ($event) {
        var _this = this;
        var data = this.botUsers.map(function (m) {
            console.log(m);
            var user = {
                userId: m.userId,
                approver: m.approver,
                inActive: !m.active,
                officeId: m.officeId,
                deptId: m.deptId,
                wageRate: m.wageRate,
                tz: m.tz,
                tz_offset: m.tz_offset,
            };
            if (!m.officeId) {
                user.officeId = "-1";
            }
            if (!m.deptId) {
                user.deptId = "-1";
            }
            return user;
        });
        console.log(data);
        this.apiService.updateUserApprovers(data).subscribe(function (data) {
            _this.apiService.toast("Users detail updated successfully.", "success");
        }, function (error) { }, function () {
            //console.log("api completed");
        });
    };
    SettingsComponent.prototype.saveAdminSetting = function ($event) {
        var _this = this;
        //console.log('adminMembers',this.adminMembers);
        //console.log('hrMembers',this.hrMembers);
        if (!this.adminMembers || this.adminMembers.length == 0) {
            this.apiService.toast("You must have at least one admin user.", "error");
            return;
        }
        if (!this.teamInfo.appRoles) {
            this.teamInfo.appRoles = {};
        }
        this.teamInfo.appRoles["admin"] = this.adminMembers.map(function (m) {
            return m.id;
        });
        this.teamInfo.appRoles["hr"] = this.hrMembers.map(function (m) {
            return m.id;
        });
        console.log(this.teamInfo);
        this.apiService.updateTeamInfo(this.teamInfo).subscribe(function (data) {
            console.log(_this.teamInfo);
            localStorage.setItem("teamInfo", JSON.stringify(_this.teamInfo));
            _this.apiService.toast("Admin settings updated successfully.", "success");
        }, function (error) { }, function () {
            //console.log("api completed");
        });
    };
    SettingsComponent.prototype.checked = function (value) {
        if (this.teamInfo && this.teamInfo.weekDays) {
            return this.teamInfo.weekDays.includes(value);
        }
        else {
            return false;
        }
    };
    SettingsComponent.prototype.getTimezones = function (filterText) {
        if (filterText) {
            return this.timezones.filter(function (m) {
                return m.name.toUpperCase().includes(filterText.toUpperCase());
            });
        }
        else {
            return this.timezones;
        }
    };
    SettingsComponent.prototype.valueChanged = function (value, $event) {
        console.log($event);
        if ($event.checked) {
            this.teamInfo.weekDays.push(value);
        }
        else {
            var indx = this.teamInfo.weekDays.indexOf(value);
            console.log("indx", indx);
            this.teamInfo.weekDays.splice(indx, 1);
        }
    };
    SettingsComponent.prototype.addNewLeaveType = function () {
        // Create a new leave type object
        var newLeaveType = {
            name: "LEAVE_TYPE",
            count: 0,
            accural: false,
            emoji: ":date: ",
        };
        // Add the new leave type to the list
        this.teamInfo.leaveTypes.push(newLeaveType);
    };
    SettingsComponent.prototype.deleteLeaveType = function (index) {
        this.teamInfo.leaveTypes.splice(index, 1);
    };
    SettingsComponent.prototype.updateEmoji = function (index, emoji) {
        console.log(index, emoji);
        if (this.teamInfo.leaveTypes[index]) {
            this.teamInfo.leaveTypes[index].emoji = emoji;
        }
        console.log(this.teamInfo.leaveTypes);
    };
    return SettingsComponent;
}());
export { SettingsComponent };
