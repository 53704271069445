<!-- <mat-progress-bar *ngIf="authService.getProgressIndicator()" mode="indeterminate" color="primary"></mat-progress-bar> -->
<div
  style="margin-top: 20px"
  fxLayout="row"
  fxLayoutGap="1%"
  fxLayout.xs="column"
  fxLayoutWrap
  fxLayoutAlign="start none"
  class="dashboard-page"
>
  <mat-card fxFlex.gt-sm="33%" class="dashboard-events-card">
    <div>
      <h4 class="header mat-subheading-2">Team next 15 days</h4>
    </div>
    <div class="card-loading" *ngIf="!loadedEvents">
      <div class="logo"></div>
    </div>
    <div class="card-icon" *ngIf="loadedEvents && events.length === 0">
      <img class="logo" src="./assets/hybrid-work.png" />
      <h4 class="mat-subheading-1">
        It's a blank canvas! No events on the horizon this week.
      </h4>
    </div>
    <div class="scrollable-pane" *ngIf="loadedEvents && events.length > 0">
      <table>
        <tr *ngFor="let event of events">
          <td>
            <img
              *ngIf="memberMap[event.userId]"
              class="icon"
              [src]="event.image_48"
              matTooltipPosition="after"
              [matTooltip]="
                memberMap[event.userId].displayName ||
                memberMap[event.userId].name
              "
            />
          </td>
          <td *ngIf="loadedEvents && memberMap[event.userId]">
            {{
              memberMap[event.userId].displayName ||
                memberMap[event.userId].name
            }}
          </td>

          <td *ngIf="!memberMap[event.userId]">
            {{ loadedEvents }}
          </td>

          <td>
            <div
              class="type"
              [className]="'type ' + event.title"
              [ngStyle]="{ backgroundColor: stringToColour(event.title) }"
            >
              {{ event.title }}
            </div>
          </td>
          <td></td>
          <td *ngIf="event.duration == 'single'">
            {{ event.start | date : "E, dd MMM" }}
          </td>
          <td *ngIf="event.duration == 'multiple'">
            {{ event.start | date : "E, dd MMM" }}
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
            {{ event.end | date : "E, dd MMM" }}
          </td>
        </tr>
      </table>
    </div>
  </mat-card>

  <mat-card fxFlex.gt-sm="33%" class="dashboard-events-card">
    <div>
      <h4 class="header mat-subheading-2">Your last 3 months</h4>
    </div>

    <div
      class="card-icon"
      *ngIf="loadedProfileMap && yourTotalPrevEvents === 0"
    >
      <img class="logo" src="./assets/schedule2.png" />
      <h4 class="mat-subheading-1">
        Fresh start or quiet quarter? No events in your last 3 months. Time to
        make some plans!
      </h4>
    </div>

    <div
      style="display: block"
      *ngIf="loadedProfileMap && yourTotalPrevEvents > 0"
    >
      <canvas
        baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [chartType]="barChartType"
        [colors]="barColors"
      >
      </canvas>
    </div>
  </mat-card>

  <mat-card fxFlex.gt-sm="33%" class="dashboard-events-card">
    <div>
      <h4 class="header mat-subheading-2">Holidays/Events</h4>
    </div>
    <div class="card-loading" *ngIf="!loadingHolidays">
      <div class="logo"></div>
    </div>
    <div class="card-icon" *ngIf="holidays && holidays.length === 0">
      <img class="logo" src="./assets/hybrid-work.png" />
      <h4 class="mat-subheading-1">No events on the horizon this year.</h4>
    </div>
    <div
      class="scrollable-pane holidays"
      *ngIf="holidays && holidays.length > 0"
    >
      <table>
        <tr *ngFor="let holiday of holidays">
          <td>
            <div class="holiday-name">
              <div
                class="holiday-avatar"
                [style.background-color]="getAvatarColor(holiday.holidayName)"
              >
                {{ getInitial(holiday.holidayName) }}
              </div>
              {{ holiday.holidayName }}
            </div>
          </td>
          <td></td>
          <td>
            {{ holiday.fromDate | date : "E, dd MMM y" : "UTC" }}
          </td>
        </tr>
      </table>
    </div>
  </mat-card>

  <!-- <mat-card fxFlex="25%" class="dashboard-events-card" *ngIf="false">
    <div>
      <h4 class="header mat-subheading-2">Remaining</h4>
    </div>

    <div style="display: block" *ngIf="!loadingChart">
      <canvas
        baseChart
        class="chart"
        [labels]="doughnutChartLabels"
        [datasets]="doughnutDataset"
        [chartType]="'doughnut'"
        [colors]="doughnutColors"
        [legend]="barChartLegend"
      >
      </canvas>
    </div>
  </mat-card> -->
</div>

<div
  *ngIf="hasShiftAccess()"
  style="margin-top: 20px"
  fxLayout="row"
  fxLayoutGap="1%"
  fxLayout.xs="column"
  fxLayoutWrap
  fxLayoutAlign="start none"
  class="dashboard-page"
>
  <mat-card fxFlex.gt-sm="33%" class="dashboard-events-card">
    <div>
      <h4 class="header mat-subheading-2">Team shifts this week</h4>
    </div>
    <div class="card-icon" *ngIf="!roster || !roster.shifts">
      <img class="logo" src="./assets/daily-task.png" />
      <h4 class="mat-subheading-1">No team shifts scheduled at the moment.</h4>
    </div>

    <div class="scrollable-pane">
      <table *ngIf="roster && roster.shifts">
        <tr *ngFor="let shift of roster.shifts">
          <td>
            <img
              *ngIf="memberMap[shift.userId]"
              class="icon"
              [src]="memberMap[shift.userId].image_48"
              matTooltipPosition="after"
              [matTooltip]="
                memberMap[shift.userId].displayName ||
                memberMap[shift.userId].name
              "
            />
          </td>
          <td *ngIf="loadedProfileMap && memberMap[shift.userId]">
            {{
              memberMap[shift.userId].displayName ||
                memberMap[shift.userId].name
            }}
          </td>

          <td *ngIf="!memberMap[shift.userId]">
            {{ loadedProfileMap }}
          </td>

          <td>
            <div
              class="type"
              [className]="'type ' + shift.shiftType"
              [ngStyle]="{
                'background-color': stringToColour(shift.shiftType)
              }"
            >
              {{ shift.shiftType }}
            </div>
          </td>
          <td></td>

          <td>
            {{ shift.fromDate | date : "E, dd MMM" }}
          </td>

          <td>
            {{ shift.fromDate | date : "shortTime" }}
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
            {{ shift.toDate | date : "shortTime" }}
          </td>
        </tr>
      </table>
    </div>
  </mat-card>
  <mat-card fxFlex.gt-sm="33%" class="dashboard-events-card">
    <div>
      <h4 class="header mat-subheading-2">Shift distribution (hr)</h4>
    </div>

    <div class="card-icon" *ngIf="!roster || !roster.shifts">
      <img class="logo" src="./assets/business-report.png" />
      <h4 class="mat-subheading-1">
        No distribution data available at the moment.
      </h4>
    </div>

    <div style="display: block" *ngIf="roster && roster.shifts">
      <canvas
        baseChart
        class="chart"
        [labels]="pieChartData.labels"
        [datasets]="pieChartData.datasets"
        [chartType]="'pie'"
        [colors]="doughnutColors"
      >
      </canvas>
    </div>
  </mat-card>
  <mat-card fxFlex.gt-sm="33%" class="dashboard-events-card">
    <div>
      <h4 class="header mat-subheading-2">Your hours</h4>
    </div>

    <div class="scrollable-pane">
      <div class="workinghour">
        {{ workingHours }}
      </div>
    </div>
  </mat-card>
</div>
