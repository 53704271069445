import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../environments/environment";
import { MatSnackBar } from "@angular/material";
import { Observable } from "rxjs";

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    public router: Router,
    private matSnackBar: MatSnackBar
  ) {}

  createDateAsUTC(date: Date): Date {
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    return date;
  }

  getAppliedLeaveTypes(quota: any) {
    let leaveTypes = ["PTO", "WFH", "FLEX", "WELLNESS", "SICK", "REMOTE"];
    let applied = leaveTypes.filter((m) => {
      return (
        quota === undefined ||
        quota[m] === undefined ||
        quota[m].hide === undefined ||
        quota[m].hide === false
      );
    });
    return applied;
  }

  getTeamEventTypes(teamInfo: any): any {
    if (teamInfo && teamInfo.leaveTypes) {
      return teamInfo.leaveTypes.map((m: any) => m.name);
    } else {
      return ["PTO", "WFH", "FLEX", "WELLNESS", "SICK", "REMOTE", "ITO"];
    }
  }
  getBotUserInfo(): any {
    return this.http.get(environment.serviceUrl + "/member/dash/userinfo");
  }
  getMyEvents(): any {
    return this.http.get(
      environment.serviceUrl +
        "/member/events/" +
        localStorage.getItem("userId")
    );
  }

  getDashByMonths(): any {
    return this.http.get(environment.serviceUrl + "/member/dash/bymonth");
  }

  getTeamEvents(): any {
    return this.http.get(environment.serviceUrl + "/member/team-events");
  }

  getTeamEventsRange(fromDate: string, toDate: string): any {
    return this.http.get(
      `${environment.serviceUrl}/member/team-events?from=${fromDate}&to=${toDate}`
    );
  }

  postMyEvents(event: any): any {
    return this.http.post(
      environment.serviceUrl +
        "/member/events/" +
        localStorage.getItem("userId"),
      event
    );
  }

  deleteMyEvents(event: any): any {
    console.log("deleting event", event);

    return this.http.delete(
      environment.serviceUrl +
        "/member/events/" +
        localStorage.getItem("userId") +
        "?type=delete&id=" +
        event.id
    );
  }

  deleteOthersEvents(event: any): any {
    console.log("deleting event", event);

    return this.http.delete(
      environment.serviceUrl +
        "/member/events/" +
        event.userId +
        "?type=delete&id=" +
        event.id
    );
  }

  modifyEvents(userId: string, id: string, newEventType: string): any {
    console.log("modifying event", event);

    return this.http.delete(
      environment.serviceUrl +
        "/member/events/" +
        userId +
        "?type=modify&id=" +
        id +
        "&newEventType=" +
        newEventType
    );
  }

  getScheduler(): any {
    return this.http.get(environment.serviceUrl + "/admin/schedule");
  }

  postScheduler(entity: any): any {
    if (localStorage.getItem("userInfo")) {
      let data = JSON.parse(localStorage.getItem("userInfo"));
      entity.tz_offset = data.user.tz_offset;
    }

    return this.http.post(environment.serviceUrl + "/admin/schedule", entity);
  }

  deleteScheduler(): any {
    return this.http.delete(environment.serviceUrl + "/admin/schedule");
  }

  getPlanCode(): any {
    return this.http.get(environment.serviceUrl + "/admin/subscriptions");
  }
  getPlanCodeWithReload(): any {
    return this.http.get(
      environment.serviceUrl + "/admin/subscriptions?reload=y"
    );
  }

  // getPlans(): any {
  //   return this.http.get(environment.serviceUrl + "/admin/subscriptions");
  // }

  createFirstSubscription(entity: any): any {
    return this.http.post(
      environment.serviceUrl + "/admin/subscriptions",
      entity
    );
  }

  cancelSubscription(entity: any): any {
    return this.http.put(
      environment.serviceUrl + "/admin/subscriptions",
      entity
    );
  }

  fetchSubscription(): any {
    return this.http.get(environment.serviceUrl + "/admin/cards/payments");
  }

  getTransHistory(): any {
    return this.http.get(environment.serviceUrl + "/admin/cards/transactions");
  }

  postCards(entity: any): any {
    return this.http.post(
      environment.serviceUrl + "/admin/cards/payments",
      entity
    );
  }

  getAllBotUsers(): any {
    return this.http.get(environment.serviceUrl + "/settings/active-users");
  }

  updateUserApprovers(data: any): any {
    return this.http.post(
      environment.serviceUrl + "/settings/active-users",
      data
    );
  }

  updateTeamInfo(data: any): any {
    return this.http.post(
      environment.serviceUrl + "/settings/team-info?roster=n",
      data
    );
  }
  updateRosterInfo(data: any): any {
    return this.http.post(
      environment.serviceUrl + "/settings/team-info?roster=y",
      data
    );
  }

  getAllOffices(): any {
    return this.http.get(environment.serviceUrl + "/settings/offices");
  }
  createOffice(data: any): any {
    return this.http.post(environment.serviceUrl + "/settings/offices", data);
  }

  updateOffice(data: any): any {
    return this.http.put(environment.serviceUrl + "/settings/offices", data);
  }

  deleteOffice(id: string): any {
    return this.http.delete(
      environment.serviceUrl + "/settings/offices?id=" + encodeURIComponent(id)
    );
  }

  getAllSchedules(): any {
    return this.http.get(environment.serviceUrl + "/settings/schedule");
  }
  createSchedule(data: any): any {
    return this.http.post(environment.serviceUrl + "/settings/schedule", data);
  }

  updateSchedule(data: any): any {
    return this.http.put(environment.serviceUrl + "/settings/schedule", data);
  }

  deleteSchedule(id: string): any {
    return this.http.delete(
      environment.serviceUrl + "/settings/schedule?id=" + encodeURIComponent(id)
    );
  }

  public getTimezone(): Observable<any> {
    return this.http.get("./assets/timezones.json");
  }
  getTeamHolidays(): any {
    return this.http.get(environment.serviceUrl + "/settings/holidays");
  }
  getTeamHolidaysWithRange(start: string, end: string): any {
    return this.http.get(
      environment.serviceUrl +
        "/settings/holidays?start=" +
        start +
        "&end=" +
        end
    );
  }

  getCountryHolidays(country: string): any {
    return this.http.get(
      environment.serviceUrl + "/settings/holidays?country=" + country
    );
  }

  createOneHoliday(data: any): any {
    return this.http.post(environment.serviceUrl + "/settings/holidays", data);
  }

  updateOneHoliday(data: any): any {
    return this.http.put(environment.serviceUrl + "/settings/holidays", data);
  }

  deleteOneHolidays(id: string): any {
    return this.http.delete(
      environment.serviceUrl + "/settings/holidays?id=" + encodeURIComponent(id)
    );
  }

  getRoster(start: string, end: string, mode: string = "create"): any {
    return this.http.get(
      environment.serviceUrl +
        "/settings/roster?start=" +
        start +
        "&end=" +
        end +
        "&mode=" +
        mode
    );
  }

  createShift(shift: any): any {
    return this.http.post(environment.serviceUrl + "/settings/roster", shift);
  }

  deleteOneShift(id: string): any {
    return this.http.delete(
      environment.serviceUrl + "/settings/roster?id=" + encodeURIComponent(id)
    );
  }

  updateShift(shift: any): any {
    return this.http.put(environment.serviceUrl + "/settings/roster", shift);
  }

  publishRoster(roster: any): any {
    return this.http.post(
      environment.serviceUrl + "/settings/shift/publish",
      roster
    );
  }

  releaseRoster(roster: any): any {
    return this.http.post(
      environment.serviceUrl + "/settings/shift/release",
      roster
    );
  }

  generateICS(icsParam: any): any {
    return this.http.post(
      environment.serviceUrl + "/settings/shift/generate-ics",
      icsParam
    );
  }

  toast(message, status) {
    let snackBarRef = this.matSnackBar.open(message, "Dismiss", {
      duration: 600000,
      panelClass: ["toast", status],
    });
  }
}
