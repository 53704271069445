import { Component, OnInit, ViewChild, ElementRef, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from "@angular/forms";

import { StripeService, StripeCardComponent, ElementOptions, ElementsOptions } from "ngx-stripe";
import { ApiService } from "../../shared/api.service";
import { AuthService } from "../../shared/auth.service";
import { ErrorStateMatcher } from "@angular/material/core";
import { PageScrollService, PageScrollInstance } from "ngx-page-scroll";
import { DOCUMENT } from "@angular/common";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ConfirmDialogComponent } from "../../shared/confirm-dialog/confirm-dialog.component";
import { PaymentHistoryDialogComponent } from "../payment-history-dialog/payment-history-dialog.component";
import { getTranslatedPlanCode } from "src/app/shared/utility";
import { title } from "process";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.css"],
})
export class SubscriptionComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: ElementOptions = {
    style: {
      base: {
        "iconColor": "#666EE8",
        "color": "#31325F",
        "lineHeight": "40px",
        "fontWeight": 300,

        "fontSize": "18px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
    },
  };

  elementsOptions: ElementsOptions = {
    locale: "en",
  };

  stripeFormGroup: FormGroup;
  newCardFormGroup: FormGroup;
  matcher = new MyErrorStateMatcher();
  showPaymentMethod: boolean = false;
  showCardEntry: boolean = false;
  showCardEdit: boolean = false;
  plans: any[];
  plansMap: any = {};
  termsChecked: boolean = false;

  activeSubscription: any;
  selectedNewPlan: any;
  subcriptionProcessing: boolean = false;
  cards: any[];
  panelOpenState: boolean = false;
  transactions: any[] = [];
  botUsers: any[] = [];
  forecastedBilling = 0;
  activePlanCode: string = "TRIAL";
  nexyBillingOn: Date;
  subSummary: any;

  constructor(
    public apiService: ApiService,
    public authService: AuthService,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private pageScrollService: PageScrollService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: any
  ) {}

  async ngOnInit() {
    this.stripeFormGroup = this.fb.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      termsChecked: ["", [Validators.required]],
    });

    this.newCardFormGroup = this.fb.group({
      name: ["", [Validators.required]],
    });

    await this.fetchSubscription();
    await this.showTransactionHistory();
    await this.getPlans();
  }
  getPlans = async () => {
    this.plans = [
      {
        code: "basic",
      },
      {
        code: "standard",
      },
    ];
  };

  getTranslatedPlanCode = (code) => {
    return getTranslatedPlanCode(code);
  };

  fetchSubscription = async function () {
    const r = await this.apiService.fetchSubscription().toPromise();
    console.log("sub summary", r);

    this.showPaymentMethod = true;
    this.subSummary = r;
    this.cards = r.paymentMethod || [];
    if (r.status === "active") {
      this.activeSubscription = r.plan;
    }
  };

  swicthOrSubscribe = async (planCode: string) => {
    this.subcriptionProcessing = true;
    try {
      await this.apiService
        .cancelSubscription({
          command: "change",
          planCode: planCode,
        })
        .toPromise();
      await this.refreshSubscription();
    } catch (error) {
    } finally {
      this.subcriptionProcessing = false;
    }
  };

  // subscribe(selectedPlan: any) {
  //   console.log("subscribe to ", selectedPlan);
  //   this.showCardEntry = true;
  //   this.selectedNewPlan = selectedPlan;

  //   let that = this;
  //   let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, "#card");

  //   setTimeout(function () {
  //     that.pageScrollService.start(pageScrollInstance);
  //   }, 500);
  // }

  // switchSubscription(plan) {
  //   console.log("switchSubscription to ", plan);
  //   let that = this;
  //   this.subcriptionProcessing = true;
  //   let entity = {
  //     planId: plan.planId,
  //     code: plan.code,
  //     userPlanId: plan.userPlanId,
  //   };
  //   that.apiService.createFirstSubscription(entity).subscribe(
  //     (data) => {
  //       that.apiService.toast("Your subscription was created successfully.", "success");

  //       let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, "#top");

  //       setTimeout(function () {
  //         that.pageScrollService.start(pageScrollInstance);
  //       }, 500);
  //     },
  //     (error) => {
  //       that.subcriptionProcessing = false;
  //       that.apiService.toast("Your subscription was not created. Try again.", "error");
  //     },
  //     () => {
  //       that.subcriptionProcessing = false;
  //       console.log("api completed");
  //     }
  //   );
  // }

  // postSubscriptionFirst() {
  //   const name = this.stripeFormGroup.get("name").value;
  //   const email = this.stripeFormGroup.get("email").value;
  //   let that = this;
  //   this.subcriptionProcessing = true;
  //   this.stripeService.createToken(this.card.getCard(), { name }).subscribe((result) => {
  //     if (result.token) {
  //       // Use the token to create a charge or a customer
  //       // https://stripe.com/docs/charges
  //       console.log(result.token.id);
  //       let entity = {
  //         token: result.token.id,
  //         planId: this.selectedNewPlan.planId,
  //         userPlanId: this.selectedNewPlan.userPlanId,
  //         name: name,
  //         email: email,
  //         code: this.selectedNewPlan.code,
  //       };
  //       that.apiService.createFirstSubscription(entity).subscribe(
  //         (data) => {
  //           that.apiService.toast(
  //             "Your subscription was created successfully. If you still see a message on your Slack OfficBot to upgrade to paid plan, please ignore. That message will disappear eventually.",
  //             "success"
  //           );

  //           that.showCardEntry = false;
  //           let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, "#top");

  //           setTimeout(function () {
  //             that.pageScrollService.start(pageScrollInstance);
  //           }, 500);
  //         },
  //         (error) => {
  //           that.subcriptionProcessing = false;
  //           that.apiService.toast("Your subscription was not created. Try again.", "error");
  //         },
  //         () => {
  //           that.subcriptionProcessing = false;
  //           console.log("api completed");
  //         }
  //       );
  //     } else if (result.error) {
  //       // Error creating the token
  //       console.log(result.error.message);
  //       this.subcriptionProcessing = false;
  //       this.apiService.toast(result.error.message, "error");
  //     }
  //   });
  // }

  async refreshSubscription() {
    await this.fetchSubscription();
    const r = await this.apiService.getPlanCodeWithReload().toPromise();
    console.log("plan code response", r);
    if (r.planCode) {
      console.log("resetting plancode to ", r.planCode.toUpperCase());
      localStorage.setItem("planCode", r.planCode.toUpperCase());
      localStorage.setItem("planId", r.planCode.toUpperCase());
    }
  }

  async updateCard() {
    const name = this.stripeFormGroup.get("name").value;
    console.log("updaing card", name);

    this.subcriptionProcessing = true;
    this.stripeService.createToken(this.card.getCard(), { name }).subscribe(async (result) => {
      console.log("result", result);
      if (result.token) {
        let entity = {
          customerId: this.subSummary.customerId,
          token: result.token.id,
        };
        console.log(entity);
        try {
          await this.apiService.postCards(entity).toPromise();
          await this.refreshSubscription();
          this.showCardEdit = false;

          this.apiService.toast("Card added successfully!", "success");
        } catch (error) {
          console.log(error);
        } finally {
          this.subcriptionProcessing = false;
        }
      } else if (result.error) {
        // Error creating the token
        console.log(result.error.message);
        this.subcriptionProcessing = false;
        this.apiService.toast(result.error.message, "error");
      }
    });

    // let that = this;
    // this.subcriptionProcessing = true;
    // this.stripeService.createToken(this.card.getCard(), { name }).subscribe((result) => {
    //   if (result.token) {
    //     console.log("activeSubscription", this.activeSubscription);
    //     let entity = {
    //       customerId: this.activeSubscription.subscription.customerId,
    //       token: result.token.id,
    //     };
    //     this.apiService.postCards(entity).subscribe(
    //       (resp) => {
    //         this.subcriptionProcessing = false;
    //         this.apiService.toast("Card added successfully!", "success");
    //       },
    //       (error) => {
    //         this.subcriptionProcessing = false;
    //       },
    //       () => {
    //         console.log("get card api completed");
    //       }
    //     );
    //   } else if (result.error) {
    //     // Error creating the token
    //     console.log(result.error.message);
    //     this.subcriptionProcessing = false;
    //     this.apiService.toast(result.error.message, "error");
    //   }
    // });
  }

  showTransactionHistory = async function () {
    this.transactions = await this.apiService.getTransHistory().toPromise();
    console.log("transactions", this.transactions);
    // this.authService.setProgressIndicator(true);
    // let that = this;
    // const dialogRef = this.dialog.open(PaymentHistoryDialogComponent, {
    //   width: "80%",
    //   data: {
    //     message: "You are going to cancel your subscription. Are you sure?",
    //   },
    // });
  };
  cancelSubscription = async function () {
    let that = this;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "auto",
      data: {
        yesLabel: "Yes, cancel",
        content: `<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #e0e0e0; border-radius: 5px;">
    <h2 style="color: #ff6600; margin-top: 0;">Whoa there! Before you hit that cancel button, let's chat about your Officebot experience:</h2>
    
    <ul style="list-style-type: none; padding-left: 0;">
        <li style="margin-bottom: 10px;">
            <strong>📈 Loving some features but not others?</strong> We're all ears! Tell us how we can improve.
        </li>
        <li style="margin-bottom: 10px;">
            <strong>🆘 Facing any challenges?</strong> Our support team is ready to swoop in and help.
        </li>
        <li style="margin-bottom: 10px;">
            <strong>💰 Budget concerns?</strong> Let's explore our flexible plans that might better suit your needs.
        </li>
    </ul>

    <p><strong>Remember, cancelling means:</strong></p>
    <ul>
        <li>Losing access to Officebot's time-saving magic</li>
        <li>Saying goodbye to all your data after 7 days</li>
    </ul>

    <p style="font-style: italic; color: #666;">Why not give us a chance to make things awesome for you? If you're still set on cancelling, we'll understand – but we'd hate to see you go!</p>

    <p style="font-weight: bold; margin-top: 20px;">Ready to cancel, or want to chat about options?</p>
    
    
</div>`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      //console.log('The dialog was closed ', result);
      if (result && result.action == "yes") {
        console.log("ready to cancel subscription");

        this.subcriptionProcessing = true;
        try {
          await this.apiService
            .cancelSubscription({
              command: "cancel",
            })
            .toPromise();
          await this.refreshSubscription();
        } catch (error) {
        } finally {
          this.subcriptionProcessing = false;
        }
      }
    });
  };
}
