<mat-progress-bar
  *ngIf="authService.getProgressIndicator()"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>

<mat-card class="notifications-card no-box-shadow">
  <mat-card-content>
    <!-- Add Notification Button -->
    <div class="button-container" *ngIf="getRole() === 'admin'">
      <button
        mat-raised-button
        color="primary"
        (click)="openNotificationDialog()"
        [disabled]="!authService.isOfficebottAdmin()"
      >
        <mat-icon>add_alert</mat-icon>
        Add Notification
      </button>
    </div>

    <!-- Existing notifications table -->
    <div class="notifications-table mat-elevation-z2 no-box-shadow">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="office">
          <th mat-header-cell *matHeaderCellDef>Office</th>
          <td mat-cell *matCellDef="let schedule">
            {{
              schedule.officeId === "-1"
                ? "ALL"
                : officeMap[schedule.officeId]?.officeName || schedule.officeId
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef>Department</th>
          <td mat-cell *matCellDef="let schedule">
            {{
              schedule.deptId === "-1"
                ? "ALL"
                : deptMap[schedule.deptId]?.officeName || schedule.deptId
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="channel">
          <th mat-header-cell *matHeaderCellDef>Channel</th>
          <td mat-cell *matCellDef="let schedule">
            {{ getChannelName(schedule.channelId) }}
            <div class="note">
              <ng-container [ngSwitch]="true">
                <span
                  *ngSwitchCase="
                    !schedule.officeId || schedule.officeId === '-1'
                  "
                >
                  Only includes members of this channel
                </span>
                <span
                  *ngSwitchCase="
                    schedule.officeId !== '-1' &&
                    (!schedule.deptId || schedule.deptId === '-1')
                  "
                >
                  Only includes members of this Office
                </span>
                <span *ngSwitchDefault>
                  Only includes members of this Department in this Office
                </span>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="times">
          <th mat-header-cell *matHeaderCellDef>Notification Times</th>
          <td mat-cell *matCellDef="let schedule">
            <div>Morning: {{ getTimeSlotLabel(schedule.localHour) }}</div>
            <div *ngIf="!schedule.disableTomorrow">
              Afternoon: {{ getTimeSlotLabel(schedule.localHour2) }}
            </div>
            <div class="timezone">{{ schedule.tz_label }}</div>
          </td>
        </ng-container>

        <!-- ... keep everything else the same, just replace the edit expansion panel section ... -->

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let schedule; let i = index">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [disabled]="!authService.isOfficebottAdmin()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="openEditDialog(schedule, i)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteSchedule(schedule, i)">
                <mat-icon color="warn">delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="[
            'office',
            'department',
            'channel',
            'times',
            'actions'
          ]"
        ></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            columns: ['office', 'department', 'channel', 'times', 'actions']
          "
        ></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>

<!-- <mat-tab label="ICS">
    <h5 class="page-card-title">Generate ICS file.</h5>

    <div class="ics-wrapper" fxLayout="column" fxLayout.lt-lg="column" fxLayoutWrap fxLayoutAlign="center">
      <mat-form-field appearance="outline" fxFlex="50%">
        <mat-label>Office</mat-label>
        <mat-select placeholder="Office" [(ngModel)]="icsParams.officeId">
          <mat-option [value]="'-1'"> ALL </mat-option>
          <mat-option *ngFor="let m of offices" [value]="m.id">
            {{ m.officeName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="50%">
        <mat-label>Period</mat-label>
        <mat-select placeholder="Period" [(ngModel)]="icsParams.period">
          <mat-option *ngFor="let m of periods" [value]="m.p">
            {{ m.l }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxFlex="100%">
        <div fxLayout="row">
          <mat-checkbox
            style="margin-right: 16px"
            [checked]="checked(d)"
            color="primary"
            *ngFor="let d of allLeaveTypes"
            (change)="valueChanged(d, $event)"
            >{{ d }}</mat-checkbox
          >
        </div>
      </div>
      <div fxFlex="50%" style="margin-top: 20px">
        <button mat-raised-button color="primary" (click)="generateICS()">Generate ICS</button>
        <div style="margin-top: 20px">
          <h5>Do you know you can automatically sync your google calendar?</h5>
          <h5 style="margin-top: 20px">Type <span class="code">sync</span> as direct message to OfficeBot</h5>
        </div>
      </div>

      <div style="margin-top: 20px" *ngIf="downloadLink">
        <h5>Your ics file is ready for download. Please click below link.</h5>
        <a [href]="downloadLink">Download</a>
      </div>
    </div>
  </mat-tab> -->
