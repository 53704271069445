import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/shared/api.service";
import { AuthService } from "src/app/shared/auth.service";
import * as moment from "moment-timezone";
import { MatAutocomplete } from "@angular/material";
import { timezones } from "src/app/shared/timezones";

@Component({
  selector: "app-departments",
  templateUrl: "./departments.component.html",
  styleUrls: ["./departments.component.scss"],
})
export class DepartmentsComponent implements OnInit {
  public offices: any[] = [];
  public departments: any[] = [];
  public officeMap = {};
  public deptMap = {};
  public teamInfo: any = {};
  public userId: string;
  public channels: any[] = [];
  public channelMap: any = {};
  public timezones: any[] = [];
  public userInfo: any = {};
  public planId: string;
  public officename: string;
  public departmentname: string;
  public allLeaveTypes: string[] = [];

  constructor(
    private apiService: ApiService,
    public authService: AuthService
  ) {}

  async ngOnInit() {
    this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.planId = "gold"; // localStorage.getItem("planId");
    this.userId = localStorage.getItem("userId");

    let allOffices = await this.apiService.getAllOffices().toPromise();
    this.offices = allOffices.filter((m) => {
      return m.type === "office";
    });
    this.departments = allOffices.filter((m) => {
      return m.type === "department";
    });
    console.log("allOffices", allOffices);

    this.offices.map((m) => {
      this.officeMap[m.id] = m;
    });
    this.departments.map((m) => {
      this.deptMap[m.id] = m;
    });

    let zones = [];
    let tzLabel = moment.tz.guess();
    for (let key in timezones.countries) {
      // this.countries.push({
      //   code:key,
      //   name:timezones.countries[key].name
      // })
      timezones.countries[key].zones.forEach((m) => {
        if (zones.indexOf(m) === -1) {
          zones.push(m);
        }
      });
    }
    zones = zones.sort();
    //generate timezone
    zones.map((m) => {
      let tz = moment.tz(m).utcOffset();

      this.timezones.push({ offset: tz * 60, name: m });
    });
  }

  getTimezones(filterText: string) {
    if (filterText) {
      return this.timezones.filter((m) => {
        return m.name.toUpperCase().includes(filterText.toUpperCase());
      });
    } else {
      return this.timezones;
    }
  }
  getInitial(name: string): string {
    if (name) {
      return name.substring(0, 1);
    }
  }

  getRole(): string {
    let role = "member";
    var appRoles = this.teamInfo["appRoles"];

    if (appRoles) {
      for (var key in appRoles) {
        if (appRoles[key].indexOf(this.userId) != -1 && role !== "admin") {
          role = key;
        }
      }
    } else if (this.teamInfo.userId == this.userId) {
      role = "admin";
    }
    return role;
  }

  create = async (name: string, type: string) => {
    console.log("creating ", type, name);
    let tzLabel = moment.tz.guess();
    let data = {
      type: type,
      officeName: name,
      tz_label: tzLabel,
      tz_offset: moment.tz(tzLabel).utcOffset() * 60,
    };

    const r = await this.apiService.createOffice(data).toPromise();
    console.log("returned ", r);
    this.apiService.toast("New " + type.toUpperCase() + " created.", "success");
    if (type === "office") {
      this.offices.push(r);
      this.officeMap[r.id] = r;
    } else {
      this.departments.push(r);
      this.deptMap[r.id] = r;
    }
  };

  delete = async (office: any, index: number) => {
    console.log("delete index", index);
    await this.apiService.deleteOffice(office.id).toPromise();
    this.apiService.toast(office.type.toUpperCase() + " deleted.", "success");
    if (office.type === "office") {
      this.offices.splice(index, 1);
    } else {
      this.departments.splice(index, 1);
    }
  };

  update = async (office: any) => {
    console.log("update");
    if (office.type === "department") {
      let tzLabel = moment.tz.guess();
      office.tz_offset = moment.tz(tzLabel).utcOffset() * 60;
      office.tz_label = tzLabel;
    } else {
      office.tz_offset = moment.tz(office.tz_label).utcOffset() * 60;
    }

    await this.apiService.updateOffice(office).toPromise();

    delete office.editing;
    this.apiService.toast(
      office.type.toUpperCase() + " name updated.",
      "success"
    );
  };
}
