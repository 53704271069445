import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-demos',
  templateUrl: './demos.component.html',
  styleUrls: ['./demos.component.scss']
})
export class DemosComponent implements OnInit {

  public videos=[
    {
      title:'01. How to use slack',
      description:"Never used slack before? Don't worry. It's super simple. Just watch the video which will guide you get started with your first slack workspace",
      url:"https://youtube.com/embed/USV9GhCmu1w",
      video:"assets/howtouseslack.mp4"
    },
    {
      title:'02. How to install OfficeBot',
      description:"Can't be simpler. Just one click and OfficeBot will be installed in your worksplace.",
      url:"https://youtube.com/embed/ca03WBkwINQ",
      video:"assets/installofficebot.mp4"
    },
    {
      title:'03. Leave approval and admin panel',
      description:"Manage leave approval workflow for your team. Assign leave approvers in admin panel. You may turn on/off auto leave approval process as well.",
      url:"https://youtube.com/embed/8wtcAk0_Jpk",
      video:"assets/leavapproval.mp4"
    },
    {
      title:'04. How to setup office and departments',
      description:"If you are running a business in multiple geographic locations and have multiple departments, you can set them up in admin panel and configure daily & weekly feed to specific slack channels so that only members belong to same team gets the relevant events.",
      url:"https://youtube.com/embed/AVKEy6D81og",
      video:"assets/createoffices.mp4"
    },
    {
      title:'05. Customize holidays',
      description:"Setup your team's holiday calendar just in few clicks.  Copy from existing templates available for many countries and then customize based on your company approved holidays.",
      url:"https://youtube.com/embed/AVKEy6D81og",
      video:"assets/holidays.mp4"
    },
    {
      title:'06. Setup notification',
      description:"Setup notification just by inviting the bot to your channel. You will receive notification everyday morning at 8AM listing all the events of the members of that channel who are on vacation on that day. You can change the time of the feed from admin panel.",
      url:"https://youtube.com/embed/AVKEy6D81og",
      video:"assets/notification.mp4"
    },
    {
      title:'07. Employee Scheduling',
      description:"Get started with employee scheduling in few minutes. Notify employees real time through Slack when new roster is published.",
      url:"https://youtube.com/embed/AVKEy6D81og",
      video:"assets/roster.mp4"
    }
  ]
  constructor(public sanitizer:DomSanitizer) { }

  ngOnInit() {
  }

}
