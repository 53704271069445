var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { AuthService } from "../../shared/auth.service";
import { ApiService } from "../../shared/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { EventEmitterService } from "src/app/shared/event-emitter.service";
import { ChartConfiguration } from "chart.js";
import { LeaveColorMap, hasShiftAccess, stringToColour } from "src/app/shared/utility";
import * as moment from "moment-timezone";
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(route, router, apiService, authService, _eventEmiter) {
        this.route = route;
        this.router = router;
        this.apiService = apiService;
        this.authService = authService;
        this._eventEmiter = _eventEmiter;
        this.members = [];
        this.memberMap = {};
        this.events = [];
        this.dashByMonths = {};
        this.userInfo = {};
        this.loadedProfileMap = false;
        this.loadedEvents = false;
        this.loadedShifts = false;
        this.doughnutChartLabels = ["Used", "Remaining"];
        this.doughnutChartType = "doughnut";
        this.doughnutColors = ["red"];
        this.doughnutDataset = [
            {
                data: [40, 60],
                backgroundColor: ["blue", "lightgrey"],
                hoverOffset: 4,
            },
            {
                data: [30, 70],
                backgroundColor: ["yellow", "lightgrey"],
                hoverOffset: 4,
            },
        ];
        // Pie
        this.pieChartOptions = {
            plugins: {
                legend: {
                    display: true,
                    position: "top",
                },
                datalabels: {
                    formatter: function (value, ctx) {
                        if (ctx.chart.data.labels) {
                            return ctx.chart.data.labels[ctx.dataIndex];
                        }
                        return "";
                    },
                },
            },
        };
        this.pieChartData = {
            labels: [],
            datasets: [],
        };
        this.barChartOptions = {
            responsive: true,
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: true,
                            drawOnChartArea: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        ticks: {
                            display: false,
                        },
                        gridLines: {
                            display: false,
                            drawOnChartArea: false,
                        },
                    },
                ],
            },
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: "Chart.js Bar Chart",
                },
                datalabels: {
                    anchor: "end",
                    align: "end",
                },
            },
        };
        this.barChartLabels = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        this.colorMap = LeaveColorMap;
        this.barChartType = "bar";
        this.barChartLegend = false;
        this.barChartData = [];
        this.loadingChart = true;
        this.barColors = ["red", "green", "blue"];
        this.planId = "BRONZE";
        this.planCode = "NONE";
        this.workingHours = 0;
        this.paidBreak = 0;
        this.unpaidBreak = 0;
        this.shiftType2HourMap = {};
        this.yourTotalPrevEvents = 0;
        this.stringToColour = function (str) {
            return stringToColour(str);
        };
    }
    DashboardComponent.prototype.ngOnDestroy = function () {
        return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/];
        }); });
    };
    DashboardComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.subsciption = this.route.parent.snapshot.data["subscription"];
                console.log("this.subsciption", this.subsciption);
                if (this.subsciption.planCode) {
                    this.planCode = this.subsciption.planCode.toUpperCase();
                    this.planId = this.subsciption.planCode.toUpperCase();
                    localStorage.setItem("planCode", this.planCode);
                    localStorage.setItem("planId", this.planCode);
                }
                if (this.planCode == "NONE") {
                    this.router.navigate(["/secured/subscription"]);
                    return [2 /*return*/];
                }
                console.log("1. dashboard ng init called");
                this._eventEmiter.emitter.subscribe(function (data) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(data == "profileloaded")) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.loadDash()];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); });
                if (localStorage.getItem("profileloaded")) {
                    console.log("dashloadedonce");
                    this.loadDash();
                }
                return [2 /*return*/];
            });
        });
    };
    DashboardComponent.prototype.hasShiftAccess = function () {
        //console.log("evaluting plancode", this.planCode);
        return hasShiftAccess(this.planCode);
    };
    DashboardComponent.prototype.loadDash = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that, leaveTypes, i, graphData, now, currentMonth, prev3month, slicer_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        that = this;
                        if (localStorage.getItem("members")) {
                            this.members = JSON.parse(localStorage.getItem("members"));
                            this.members.forEach(function (item) {
                                _this.memberMap[item.id] = item;
                                console.log(item);
                            });
                        }
                        else {
                            console.log("$$$ Member maps not in local storage");
                        }
                        return [4 /*yield*/, this.loadTeamEvents()];
                    case 1:
                        _a.sent();
                        this.loadedProfileMap = true;
                        console.log("3. teamInfo");
                        if (!localStorage.getItem("teamInfo")) return [3 /*break*/, 3];
                        this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
                        this.barChartData = [];
                        leaveTypes = this.apiService.getTeamEventTypes(this.teamInfo);
                        for (i = 0; i < leaveTypes.length; i++) {
                            this.barChartData.push({
                                backgroundColor: "red",
                                label: leaveTypes[i],
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            });
                        }
                        console.log("loading chart");
                        return [4 /*yield*/, this.apiService.getDashByMonths().toPromise()];
                    case 2:
                        graphData = _a.sent();
                        now = new Date();
                        currentMonth = now.getMonth() + 1;
                        prev3month = currentMonth < 3 ? currentMonth + 12 - 3 : currentMonth - 3;
                        console.log("3 months", prev3month, currentMonth);
                        slicer_1 = [prev3month, currentMonth];
                        this.barChartLabels = graphData.months;
                        this.barChartLabels = this.barChartLabels.slice(slicer_1[0], slicer_1[1]);
                        graphData.data.forEach(function (item) {
                            item.data = item.data.slice(slicer_1[0], slicer_1[1]);
                            var indx = _this.barChartData
                                .map(function (e) {
                                return e.label;
                            })
                                .indexOf(item.label);
                            var used = item.data.reduce(function (total, num) { return total + num; }, 0);
                            _this.yourTotalPrevEvents += used;
                            console.log("item", item.label, used);
                            // item.label =
                            //   item.label +
                            //   "(" +
                            //   item.data.reduce((total, num) => total + num, 0) +
                            //   ")";
                            //console.log("color", item.label, this.colorMap[item.label]);
                            item.backgroundColor = _this.stringToColour(item.label); //this.colorMap[item.label];
                            _this.barChartData[indx] = item;
                        });
                        this.loadingChart = false;
                        // console.log("bar chart data ", this.barChartData);
                        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
                        return [3 /*break*/, 4];
                    case 3:
                        console.log("could not load chart data");
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this.loadHolidays()];
                    case 5:
                        _a.sent();
                        if (!this.hasShiftAccess()) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.loadShiftInfo()];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.loadHolidays = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loadingHolidays = false;
                        _a = this;
                        return [4 /*yield*/, this.apiService.getTeamHolidays().toPromise()];
                    case 1:
                        _a.holidays = _b.sent();
                        this.loadingHolidays = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.loadShiftInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var nowTime, strWeekDay, data, shiftTypes, pieData_1, pieDataBgColor_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        nowTime = moment(new Date().getTime() + this.userInfo.user.tz_offset * 1000);
                        strWeekDay = this.teamInfo.startOfWeek || "Monday";
                        this.startOfWeek = moment(new Date().getTime() + this.userInfo.user.tz_offset * 1000)
                            .day(strWeekDay)
                            .startOf("day");
                        if (nowTime.valueOf() < this.startOfWeek.valueOf()) {
                            this.startOfWeek = this.startOfWeek.subtract(7, "days");
                            this.endOfWeek = moment(this.startOfWeek).add(6, "days");
                        }
                        else {
                            this.endOfWeek = moment(this.startOfWeek).add(6, "days");
                        }
                        console.log("start of week", this.startOfWeek);
                        return [4 /*yield*/, this.apiService
                                .getRoster(this.startOfWeek.format("YYYY-MM-DD"), this.endOfWeek.format("YYYY-MM-DD"), "view")
                                .toPromise()];
                    case 1:
                        data = _a.sent();
                        if (data.roster && ["published"].includes(data.roster.rStatus) && data.shifts) {
                            data.shifts.forEach(function (element) {
                                element["fromDate"] = moment(element.fromDate)
                                    .add(-1 * element.tz_offset, "seconds")
                                    .toDate();
                                element["toDate"] = moment(element.toDate)
                                    .add(-1 * element.tz_offset, "seconds")
                                    .toDate();
                                element["duration"] = Math.round((element["toDate"] - element["fromDate"]) / 3600000);
                                console.log("element.duration", element.duration);
                                if (element.userId === _this.userInfo.user.id) {
                                    console.log("adding working hour for ", element.userId);
                                    _this.workingHours += element.duration;
                                }
                                _this.shiftType2HourMap[element.shiftType] = (_this.shiftType2HourMap[element.shiftType] || 0) + element.duration;
                            });
                            this.roster = data;
                            shiftTypes = Object.keys(this.shiftType2HourMap);
                            pieData_1 = [];
                            pieDataBgColor_1 = [];
                            this.pieChartData.labels = shiftTypes;
                            shiftTypes.forEach(function (shiftType) {
                                pieData_1.push(_this.shiftType2HourMap[shiftType]);
                                pieDataBgColor_1.push(_this.stringToColour(shiftType));
                            });
                            this.pieChartData.datasets.push({
                                data: pieData_1,
                                backgroundColor: pieDataBgColor_1,
                            });
                            console.log("pieChartData", this.pieChartData);
                            console.log("roster data", data);
                            console.log("workingHours", this.workingHours);
                            console.log("shiftType2HourMap", this.shiftType2HourMap);
                            //populate shift distribution pie chart
                            // this.pieChartData = {
                            //   labels: ["Used", "Remaining"],
                            //   datasets: [
                            //     {
                            //       data: [
                            //         this.workingHours,
                            //         this.userInfo.user.shiftLimit - this.workingHours,
                            //       ],
                            //       backgroundColor: ["red", "lightgrey"],
                            //     },
                            //   ],
                            // };
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.loadTeamEvents = function () {
        return __awaiter(this, void 0, void 0, function () {
            var from, to, data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.events = [];
                        from = moment().format("YYYY-MM-DD");
                        to = moment().add(15, "days").format("YYYY-MM-DD");
                        return [4 /*yield*/, this.apiService.getTeamEventsRange(from, to).toPromise()];
                    case 1:
                        data = _a.sent();
                        console.log("team events", data);
                        this.events = data.map(function (element) {
                            var event = {
                                id: element.id,
                                userId: element.userId,
                                start: _this.apiService.createDateAsUTC(new Date(element.fromDate)),
                                end: _this.apiService.createDateAsUTC(new Date(element.toDate)),
                                title: element.type,
                                duration: element.duration,
                            };
                            if (_this.memberMap[element.userId]) {
                                if (_this.memberMap[element.userId].image_48 === undefined ||
                                    _this.memberMap[element.userId].image_48 === null ||
                                    _this.memberMap[element.userId].image_48 === "null") {
                                    event["image_48"] = "assets/favicon.png";
                                }
                                else {
                                    event["image_48"] = _this.memberMap[element.userId].image_48;
                                }
                                console.log("image", event["image_48"]);
                            }
                            else {
                                event["image_48"] = "assets/favicon.png";
                            }
                            return event;
                        });
                        this.events.sort(function (a, b) {
                            return a.start - b.start;
                        });
                        this.events = this.events.slice(0, 10);
                        this.loadedEvents = true;
                        console.log("events loaded", this.memberMap);
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.getAvatarColor = function (name) {
        // Simple hash function
        var hash = name.split("").reduce(function (acc, char) {
            return char.charCodeAt(0) + ((acc << 5) - acc);
        }, 0);
        // Convert hash to RGB values
        var r = (hash & 0xff0000) >> 16;
        var g = (hash & 0x00ff00) >> 8;
        var b = hash & 0x0000ff;
        // Ensure the color is not too light (for visibility on white background)
        var minBrightness = 50;
        var adjustedR = Math.max(r, minBrightness);
        var adjustedG = Math.max(g, minBrightness);
        var adjustedB = Math.max(b, minBrightness);
        // Convert to hex and return
        return "#" + ((adjustedR << 16) | (adjustedG << 8) | adjustedB).toString(16).padStart(6, "0");
    };
    DashboardComponent.prototype.getInitial = function (name) {
        return name.charAt(0).toUpperCase();
    };
    return DashboardComponent;
}());
export { DashboardComponent };
