var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var allowedCommentPattern = /[a-zA-Z0-9_,-]/; // Allowed characters: alphanumeric, underscore, comma, and hyphen
export var allowedLeaveTypePattern = /[a-zA-Z0-9_]/; // Allowed characters: alphanumeric, underscore, comma, and hyphen
export var getTranslatedPlanCode = function (planCode) {
    if (planCode == "silver") {
        return "Basic";
    }
    else if (planCode == "gold") {
        return "Standard";
    }
    else if (planCode == "3") {
        return "Premium";
    }
    else {
        return planCode;
    }
};
export var validateCommentInput = function (event) {
    var inputChar = String.fromCharCode(event.charCode);
    if (!allowedCommentPattern.test(inputChar)) {
        event.preventDefault();
    }
};
export var stringToColour = function (str) {
    if (str === undefined || str === null) {
        return "#ededed";
    }
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convert hash to RGB values
    var r = (hash & 0xff0000) >> 16;
    var g = (hash & 0x00ff00) >> 8;
    var b = hash & 0x0000ff;
    // Ensure color isn't too bright by reducing brightness if necessary
    var maxBrightness = 100; // Adjust this value to control maximum brightness
    var brightness = (r * 299 + g * 587 + b * 114) / 1000;
    if (brightness > maxBrightness) {
        var reduction = maxBrightness / brightness;
        r = Math.floor(r * reduction);
        g = Math.floor(g * reduction);
        b = Math.floor(b * reduction);
    }
    // Convert RGB to hex
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};
export var LeaveColorMap = {
    PTO: "#E91E63",
    REMOTE: "#00c853",
    FLEX: "#2196F3",
    WFH: "#673AB7",
    WELLNESS: "#FF9800",
    SICK: "#FDD835",
};
export var hasShiftAccess = function (planCode) {
    return ["GOLD", "STANDARD", "PREMIUM", "TRIAL"].includes(planCode);
};
export var leavTypeObjectToArray = function (obj) {
    return Object.entries(obj).map(function (_a) {
        var name = _a[0], props = _a[1];
        return (__assign({ name: name }, props, { emoji: ":date:" }));
    });
};
