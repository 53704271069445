import { Component, OnInit } from "@angular/core";
// import {
//   trigger,
//   state,
//   style,
//   animate,
//   transition
// } from '@angular/animations';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-shift",
  templateUrl: "./shift.component.html",
  styleUrls: ["./shift.component.scss"],
  // animations: [
  //   trigger('openClose', [
  //     state('open', style({
  //       height: '100px',
  //       opacity: 1,

  //     })),
  //     state('closed', style({
  //       height: '100px',
  //       opacity: 0

  //     })),
  //     transition('open => closed', [
  //       animate('1s')
  //     ]),
  //     transition('closed => open', [
  //       animate('0.5s')
  //     ])
  //   ])
  // ]
})
export class ShiftComponent implements OnInit {
  public isOpen: boolean = false;

  public carousel = [
    {
      image: "assets/shift4.jpg",
      image2: "assets/p1.png",
      title: "Employee scheduling that works for everyone",
      subTitle:
        "Ditch Google Docs, Microsoft Excel or Paper & Pencil for scheduling. Create schedules in minutes.",
    },
    {
      image: "assets/shift3.jpg",
      image2: "assets/p2.png",
      title: "Get Notified. No more sticky note or post it!",
      subTitle:
        "Get notified right inside Slack when weekly roster is published. Get reminded when shift is approaching.",
    },
  ];

  public features: any[] = [
    {
      image: "assets/s1.png",
      title: "Check your shift anytime",
      subTitle:
        "View your upcoming shifts anytime, from anywhere right inside your Slack. Find your colleagues with same schedule as you for a car pool or share ride. <i class='material-icons'>airport_shuttle</i>",
    },
    {
      image: "assets/s5.png",
      title: "Request shift swap",
      subTitle:
        "Get summary snapshot end of every month right on your channel. ",
    },
    {
      image: "assets/s4.png",
      title: "Employee accepts or rejects",
      subTitle:
        "Check your upcoming schedule right inside your slack. Delete or re-create leave events anytime you want.",
    },
    {
      image: "assets/s7.png",
      title: "Get Notified",
      subTitle:
        "Get notified automatically 2 hours before your shift starts. Never forget your shifts anymore.",
    },
  ];

  public displayCounter = 0;
  public slider;
  public redirectUrl: string = environment.redirectUlr;
  constructor() {
    console.log("redirectUrl", this.redirectUrl);
  }

  ngOnInit() {
    let that = this;
    that.isOpen = true;
    that.slider = that.carousel[this.displayCounter];
    setInterval(() => {
      that.isOpen = true;
      that.slider = that.carousel[that.displayCounter];

      if (that.displayCounter >= that.carousel.length - 1) {
        that.displayCounter = 0;
      } else {
        that.displayCounter++;
      }
      setTimeout(() => {
        that.isOpen = false;
      }, 9000);
    }, 10000);
  }
}
