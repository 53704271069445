import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-edit-notification-dialog",
  templateUrl: "./edit-notification-dialog.component.html",
  styleUrls: ["./edit-notification-dialog.component.scss"],
})
export class EditNotificationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditNotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      schedule: any;
      offices: any[];
      departments: any[];
      channels: any[];
      timeSlots: any[];
      getTimezones: (filter: string) => any[];
      isEdit: boolean;
    }
  ) {}

  isValid(): boolean {
    const s = this.data.schedule;
    return s.channelId && s.localHour && s.tz_label;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.isValid()) {
      this.dialogRef.close(this.data.schedule);
    }
  }
}
