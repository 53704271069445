import { getCurrencySymbol } from "@angular/common";
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import * as moment from "moment-timezone";
import { validateCommentInput } from "../utility";

@Component({
  selector: "app-roster-dialog",
  templateUrl: "./roster-dialog.component.html",
  styleUrls: ["./roster-dialog.component.scss"],
})
export class RosterDialogComponent implements OnInit {
  repeatedOn: any[] = [];

  breakSlots: any[] = [0, 15, 45, 60];
  options = { prefix: "$" };

  ngOnInit() {}

  constructor(
    public dialogRef: MatDialogRef<RosterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.options.prefix = getCurrencySymbol(data.currency, "narrow");

    data.days.forEach((m) => {
      this.repeatedOn.push({
        checked:
          m.format("dd") === this.data.slot.start.format("dd") ? true : false,
        day: m,
      });
    });
  }

  onNoClick(): void {
    this.dialogRef.close({ action: "no" });
  }

  onYesClick() {
    //console.log('saving', this.data);
    this.dialogRef.close({
      action: "yes",
      data: this.data,
      repeated: this.repeatedOn,
    });
  }

  getDiff(slot: any) {
    let diff = moment.utc(slot.end.diff(slot.start));
    //console.log(diff);
    if (this.data.slot.paidBreak !== true) {
      diff.subtract(this.data.slot.breakTime, "minutes");
    }
    return diff.format("hh:mm");
  }

  setValue() {
    this.data.slot.start = moment(this.data.slot.fromDate);
    this.data.slot.end = moment(this.data.slot.toDate);
    //let diff = this.data.slot.end.diff(this.data.slot.start);
    //this.data.slot.end.add(diff/60000,'minutes');
    //this.data.slot['toDate'] = this.data.slot.end.toDate();
    //console.log(diff);
  }

  validateCommentInput = (event: KeyboardEvent) => {
    validateCommentInput(event);
  };
}
