import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';

@Component({
  selector: 'app-contact-us-sheet',
  templateUrl: './contact-us-sheet.component.html',
  styleUrls: ['./contact-us-sheet.component.css']
})
export class ContactUsSheetComponent implements OnInit {

  constructor(private bottomSheetRef: MatBottomSheetRef<ContactUsSheetComponent>) { }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
  
  ngOnInit() {
  }

}
