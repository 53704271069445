import { Component, OnInit } from "@angular/core";
// import {
//   trigger,
//   state,
//   style,
//   animate,
//   transition,
// } from "@angular/animations";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  public isOpen: boolean = false;

  public faqs = [
    {
      category: "General Questions",
      questions: [
        {
          question: "What is Officebot and how does it work?",
          answer:
            "Officebot is a cloud-based leave and shift management solution that integrates with Slack. It helps teams efficiently manage time off, work-from-home arrangements, and shift schedules, all while keeping everyone informed through automated notifications.",
        },
        {
          question: "Who is Officebot designed for?",
          answer:
            "Our product is designed for businesses of all sizes, from small startups to large enterprises, that want to streamline their leave management and shift scheduling processes. It's particularly useful for teams that use Slack as their primary communication tool.",
        },
        {
          question: "Do I need to install any software to use Officebot?",
          answer:
            "No, Officebot is a cloud-based solution. You don't need to install any software on your computer. However, you will need to integrate it with your Slack workspace and access our web console for certain features.",
        },
      ],
    },
    {
      category: "Features and Functionality",
      questions: [
        {
          question: "What types of leave can be managed with Officebot?",
          answer:
            "Depending on your plan, you can manage various types of leave including PTO (Paid Time Off), WFH (Work From Home), FLEX time, REMOTE work, SICK leave, and WELLNESS days.",
        },
        {
          question: "Can managers approve leave requests through the system?",
          answer:
            "Yes, our Standard and Premium plans include a leave approval workflow that allows managers to review and approve leave requests efficiently.",
        },
        {
          question: "Does Officebot integrate with other calendar systems?",
          answer:
            "Our Premium plan offers integration with Google Calendar. We're continuously working on expanding our integration capabilities.",
        },
        {
          question: "How far in advance can shifts be scheduled?",
          answer:
            "With our Basic and Standard plans, you can schedule shifts up to 2 weeks in advance. Our Premium plan extends this to 6 weeks, allowing for more long-term planning.",
        },
      ],
    },
    {
      category: "Pricing and Plans",
      questions: [
        {
          question: "What's the difference between the Basic and Standard plans?",
          answer:
            "Each plan offers progressively more features. The Basic plan covers essential leave management needs, the Standard plan covers advanced scheduling features.",
        },
        {
          question: "Can I switch between plans?",
          answer: "Yes, you can upgrade or downgrade your plan at any time. Your billing will be adjusted accordingly.",
        },
        {
          question: "Is there a free trial available?",
          answer:
            "Yes, we offer a 14-day free trial of our Standard plan so you can experience the key features of Officebot before making a commitment.",
        },
        {
          question: "How can I cancel my subscription, and what is the refund policy?",
          answer:
            "We've made cancellation easy and hassle-free. You can cancel your subscription online at any time without needing to contact customer support. Your service will remain active until the end of the current billing period, your balance will be due immediately. Please note that we cannot provide refunds for subscription fees paid after trial is over.",
        },
        {
          question: "Do I need to provide payment information for the free trial?",
          answer:
            "Start your free trial today - no credit card required! You can explore all our features without any payment information. When your trial period comes to an end, you'll have the option to subscribe by adding your payment details to continue enjoying our service.",
        },
        {
          question: "How is the pricing calculated?",
          answer: `<p>
            Example-1: You are on the Basic plan and you have 10 active users.
            <br />
            Your monthly expense: $1 x 10 = $10 plus tax
            <br />
            <br />
            Example-2: You are on the Standard plan and you have 20 active
            users.
            <br />
            Your monthly expense: $2 x 20 = $40 plus tax
          </p>`,
        },
        {
          question: "How is the number of users calculated?",
          answer:
            "We only count active OfficeBot users. You should be able to deactivate users from the app and that use will be excluded from billing from next cycle.",
        },
        {
          question: "Do you offer discounts for non-profits?",
          answer:
            "Yes, we have a special discount for organizations that use Officebot for their whole organization. Please contact us at hello@appstacksolutions.com for more details.",
        },
        {
          question: "Do you offer discounts for educational institutions?",
          answer:
            "Yes, we have a special discount for organizations that use Officebot for their whole organization. Please contact us at XXXXXXXXXXXXXXXXXXXXXXXXXXX for more details.",
        },
        {
          question: "Do I need to provide credit card for free tier?",

          answer:
            "Yes, we require credit card details for the free tier. You will not be charged as long as number of users are 3 or less. If you exceed Officebot will automatically move your plan to Standard and start charging your credit card as per standar pricing model.",
        },
      ],
    },
    {
      category: "Security and Data",
      questions: [
        {
          question: "How secure is my data with Officebot?",
          answer:
            "We take data security very seriously. All data is encrypted in transit and at rest. We use industry-standard security protocols and regularly undergo security audits.",
        },
        {
          question: "Can I export my data from Officebot?",
          answer: "Yes, you can export your data in various formats, including iCalendar (ICS) files. ",
        },
      ],
    },
    {
      category: "Support and Training",
      questions: [
        {
          question: "What kind of customer support do you offer?",
          answer:
            "We offer email support for all plans. Our Premium plan customers also have access to priority support with faster response times.",
        },
        {
          question: "Do you provide training on how to use Officebot?",
          answer:
            "Yes, we provide comprehensive documentation, video tutorials, and webinars to help you get the most out of Officebot. Premium plan customers also have access to personalized onboarding sessions.",
        },
        {
          question: "How often do you release updates and new features?",
          answer:
            "We continuously work on improving Officebot and typically release updates on a monthly basis. Major new features are usually released quarterly.",
        },
      ],
    },
  ];

  public carousel = [
    {
      image: "assets/office2.jpg",
      image2: "assets/p1.png",
      title: "Leave tracking was never easy before",
      subTitle:
        "Project management tools suck. Timesheet is for internal system to track employee's leave. But there is no easy way to find the whereabouts of a teammate. OfficeBot is here to solve your problem.",
    },
    {
      image: "assets/vacation.jpg",
      image2: "assets/p2.png",
      title: "Take a break! But don't forget to inform your team",
      subTitle:
        "Works for both small and big teams. Manage multiple offices, departments, leave entitlements, leave quotas all in one place.",
    },
    {
      image: "assets/w4.jpg",
      image2: "assets/p11.png",
      title: "Coffee shop or corporate office. Works for all.",
      subTitle:
        "Whether you own a coffee shop or run a corporate office. You must need a simple and efficient leave management system to keep track of your employees leave or vacation.",
    },
  ];

  public features: any[] = [
    {
      image: "assets/p3.png",
      title: "Can't be simpler",
      subTitle:
        "Simply type your schedule and let everyone in your team know where you are. No more looking around and hide & seek. Supported types are WFH, PTO, FLEX, REMOTE and WELLNESS.",
    },
    {
      image: "assets/p23.png",
      title: "Visual snapshot",
      subTitle: "Get summary snapshot end of every month right on your channel. ",
    },
    {
      image: "assets/p8.png",
      title: "Manage your leaves",
      subTitle:
        "Check your upcoming schedule right inside your slack. Delete or re-create leave events anytime you want.",
    },
    {
      image: "assets/p24.png",
      title: "Manage leave types",
      subTitle:
        "Hide the leave types your organization don't allow and reduce the confusion among your employees. All from the same admin panel.",
    },
    {
      image: "assets/p11.png",
      title: "Approval workflow",
      subTitle:
        "Leave request is sent manager for approval. Manager receives notification everyday until leave is approved or rejected. Member is notified when leave is approved or rejected.",
    },

    {
      image: "assets/p21.png",
      title: "Get notified",
      subTitle:
        "Get the summary of whereabouts every morning to your desired channel, private or public. In addition receive summary of the week ahead on every Monday morning to same channel. Simply invite OfficeBot to a channel.",
    },
    {
      image: "assets/p14.png",
      title: "Multiple offices and departments",
      subTitle:
        "Your leave consumption and leave balance at your finger tips. Just a command away. You can view other's summary as well which is very handy during planning, specially year end supports and availibility.",
    },
    {
      image: "assets/p7.png",
      title: "Check schedule",
      subTitle:
        "Hide n seek does not help. Let it be transparent and simple. Check your peer's upcoming schedule whenever you need to. ",
    },

    {
      image: "assets/p10.png",
      title: "Automatic status update",
      subTitle:
        "OfficeBot will automatically update your slack status when your are on vacation or remote. You may opt out if you want. You need expliclitely grant permission to allow OfficeBot to change status on your behalf. No feaking surprises.",
    },
    {
      image: "assets/p30.png",
      title: "Notify others on your status update",
      subTitle: "OfficeBot will automatically send your status update to all the channels where OfficeBot is invited.",
    },
    {
      image: "assets/p9.png",
      title: "Leave balance",
      subTitle:
        "Your leave consumption and leave balance at your finger tips. Just a command away. You can view other's summary as well which is very handy during planning, specially year end supports and availibility.",
    },
    {
      image: "assets/p20.png",
      title: "Holiday Reminders",
      subTitle:
        "Configure hodidays or copy from templates. Get notified on channel for upcoming holidays. Also you can view holidays anytime.",
    },
    {
      image: "assets/ics1.png",
      title: "Export iCalendar (ics)",
      subTitle:
        "Export your upcoming events as ics file and import into Google calendar. You don't need to create events in multiple places anymore.",
    },
    {
      image: "assets/google-calendar-logo.png",
      title: "Auto Sync with Google Calendar",
      subTitle:
        "Enable Google calendar sync and forget about exporting ics files. OfficeBot will automatically sync events between OfficeBot and your Google calendar.",
    },
  ];

  public displayCounter = 0;
  public slider;
  public redirectUrl: string = environment.redirectUlr;
  constructor() {
    console.log("redirectUrl", this.redirectUrl);
  }

  ngOnInit() {
    // let that = this;
    // that.isOpen = true;
    // that.slider = that.carousel[this.displayCounter];
    // setInterval(() => {
    //   that.isOpen = true;
    //   that.slider = that.carousel[that.displayCounter];
    //   if (that.displayCounter >= that.carousel.length - 1) {
    //     that.displayCounter = 0;
    //   } else {
    //     that.displayCounter++;
    //   }
    //   setTimeout(() => {
    //     that.isOpen = false;
    //   }, 9000);
    // }, 10000);
  }
}
