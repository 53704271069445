import { OnInit } from "@angular/core";
import { ApiService } from "../../shared/api.service";
import { startOfDay, endOfDay, isSameDay, isSameMonth } from "date-fns";
import { Subject } from "rxjs";
import { CalendarView, DAYS_OF_WEEK, } from "angular-calendar";
import * as moment from "moment-timezone";
import { AuthService } from "../../shared/auth.service";
import { ConfirmDialogComponent } from "../../shared/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { validateCommentInput } from "src/app/shared/utility";
// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
moment.updateLocale("en", {
    week: {
        dow: DAYS_OF_WEEK.MONDAY,
        doy: 0,
    },
});
var colors = {
    red: {
        primary: "rgba(218, 21, 6, 1)",
        secondary: "rgba(218, 21, 6, 0.5)",
    },
    blue: {
        primary: "#1e90ff",
        secondary: "#D1E8FF",
    },
    yellow: {
        primary: "#e3bc08",
        secondary: "#FDF1BA",
    },
};
var EventsComponent = /** @class */ (function () {
    function EventsComponent(apiService, authService, dialog, sanitizer) {
        var _this = this;
        this.apiService = apiService;
        this.authService = authService;
        this.dialog = dialog;
        this.sanitizer = sanitizer;
        this.allowedCommentPattern = /[a-zA-Z0-9_,-]/; // Allowed characters: alphanumeric, underscore, comma, and hyphen
        this.view = CalendarView.Month;
        this.CalendarView = CalendarView;
        this.viewDate = new Date();
        this.actions = [
            {
                label: "<i  style=\"color:red\" class=\"fa fa-fw fa-trash red-icon\" ></i>",
                onClick: function (_a) {
                    var event = _a.event;
                    _this.deleteEvent(event);
                },
            },
        ];
        this.refresh = new Subject();
        this.events = [];
        this.teamInfo = {};
        this.userInfo = {};
        this.planId = "BRONZE";
        this.event = { duration: "single" };
        this.activeDayIsOpen = false;
        this.eventTypes = [
            { tag: "pto", description: "PTO" },
            { tag: "wfh", description: "WFH" },
            { tag: "FLEX", description: "FLEX" },
            { tag: "WELLNESS", description: "WELLNESS" },
            { tag: "REMOTE", description: "REMOTE" },
            { tag: "SICK", description: "SICK" },
        ];
        this.appliedEventTypes = [];
        this.daysOfWeek = [
            { tag: "mon", description: "Monday" },
            { tag: "tue", description: "Tuesday" },
            { tag: "wed", description: "Wednesday" },
            { tag: "thu", description: "Thursday" },
            { tag: "fri", description: "Friday" },
            { tag: "sat", description: "Saturday" },
            { tag: "sun", description: "Sunday" },
        ];
        this.validateCommentInput = function (event) {
            validateCommentInput(event);
        };
        this.validateInput = function () {
            var valid = true;
            if (!this.event.type ||
                !this.event.fromDate ||
                (this.event.duration == "multiple" && !this.event.toDate) ||
                (this.event.duration == "recurring" && !this.event.toDate)) {
                valid = false;
            }
            if (this.event.duration == "recurring" && !(this.event.weekDays && this.event.weekDays.length > 0)) {
                valid = false;
            }
            return valid;
        };
        if (localStorage.getItem("teamInfo")) {
            this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
            // this.appliedEventTypes = apiService.getAppliedLeaveTypes(
            //   this.teamInfo.quota
            // );
            this.appliedEventTypes = apiService.getTeamEventTypes(this.teamInfo);
        }
        if (localStorage.getItem("userInfo")) {
            this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        }
        if (localStorage.getItem("planId")) {
            this.planId = localStorage.getItem("planId").toUpperCase();
        }
    }
    EventsComponent.prototype.dayClicked = function (_a) {
        var date = _a.date, events = _a.events;
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                this.activeDayIsOpen = true;
            }
        }
    };
    EventsComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        event.start = newStart;
        event.end = newEnd;
        this.handleEvent("Dropped or resized", event);
        this.refresh.next();
    };
    EventsComponent.prototype.handleEvent = function (action, event) {
        this.modalData = { event: event, action: action };
    };
    EventsComponent.prototype.addEvent = function () {
        this.events.push({
            title: "New event",
            start: startOfDay(new Date()),
            end: endOfDay(new Date()),
            color: colors.red,
            draggable: true,
            resizable: {
                beforeStart: true,
                afterEnd: true,
            },
        });
        this.refresh.next();
    };
    EventsComponent.prototype.createEvent = function () {
        var _this = this;
        this.event.tz_offset = JSON.parse(localStorage.getItem("userInfo")).user.tz_offset;
        this.event.teamId = localStorage.getItem("teamId");
        this.event.userId = localStorage.getItem("userId");
        console.log(localStorage.getItem("planId"), this.teamInfo.autoApprove);
        if (localStorage.getItem("planId") === "gold" &&
            this.teamInfo.autoApprove !== undefined &&
            this.teamInfo.autoApprove === false) {
            this.event.askApproval = true;
            this.event.botToken = localStorage.getItem("B_T");
            if (this.userInfo.approver) {
                this.event.approverId = this.userInfo.approver;
            }
            else {
                this.event.approverId = this.teamInfo.userId;
            }
        }
        else {
            this.event.askApproval = false;
        }
        this.event.targetUsers = [this.event.userId];
        console.log(this.event);
        this.apiService.postMyEvents(this.event).subscribe(function (data) {
            if (_this.event.askApproval) {
                _this.apiService.toast("Your request has been sent for approval.", "success");
            }
            else {
                _this.apiService.toast("Your event has been created succeefully.", "success");
            }
            _this.loadEvents();
        }, function (error) { }, function () {
            console.log("api completed");
        });
    };
    EventsComponent.prototype.deleteEvent = function (event) {
        // console.log(this.apiService.createDateAsUTC(new Date()).getTime(),event.start.getTime());
        // if(this.apiService.createDateAsUTC(new Date()).getTime() > event.start.getTime()){
        //   this.apiService.toast("You cannot delete an event which started in the past.","error");
        //   return;
        // }
        //console.log('deleting', event.start.getTime(), this.apiService.createDateAsUTC(new Date()).getTime());
        var that = this;
        var dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: "350px",
            data: {
                message: "You are going to delete your event. Do you want to continue?",
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            //console.log('The dialog was closed ', result);
            if (result && result.action == "yes") {
                that.apiService.deleteMyEvents(event).subscribe(function (data) {
                    that.apiService.toast("Your event has been deleted successfully.", "success");
                    var index = that.events.findIndex(function (item) { return item.id == event.id; });
                    that.events.splice(index, 1);
                    that.refresh.next();
                }, function (error) { }, function () {
                    console.log("api completed");
                });
            }
        });
    };
    EventsComponent.prototype.ngOnInit = function () {
        this.loadEvents();
    };
    EventsComponent.prototype.loadEvents = function () {
        var _this = this;
        var that = this;
        that.events = [];
        this.apiService.getMyEvents().subscribe(function (data) {
            data.forEach(function (element) {
                //console.log(moment.tz(element.fromDate, "Europe/London"));
                //console.log(moment.tz(element.toDate, "Europe/London"));
                var event = {
                    id: element.id,
                    start: _this.apiService.createDateAsUTC(new Date(element.fromDate)),
                    end: _this.apiService.createDateAsUTC(new Date(element.toDate)),
                    title: element.type,
                    color: element.type == "PTO" ? colors.red : colors.blue,
                    allDay: true,
                    draggable: false,
                };
                if (element.shouldDelete === true) {
                    event["actions"] = that.actions;
                }
                if (element.comment) {
                    element.comment = element.comment.replace(/[^a-zA-Z0-9_,]/g, " ");
                    console.log("replaced comment", element.comment);
                    if (element.comment.charAt(0) == "“") {
                        event["title"] += ": " + element.comment.substring(1, element.comment.length - 1);
                    }
                    else {
                        event["title"] += ": " + element.comment;
                    }
                }
                //console.log(event);
                that.events.push(event);
            });
            _this.refresh.next();
        }, function (error) { }, function () {
            console.log("api completed");
            // this.authService.setProgressIndicator(false);
        });
    };
    return EventsComponent;
}());
export { EventsComponent };
