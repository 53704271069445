import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "src/app/shared/api.service";
import { AuthService } from "src/app/shared/auth.service";
import * as moment from "moment-timezone";
import { MatAutocomplete, MatDialog } from "@angular/material";
import { timezones } from "src/app/shared/timezones";
import * as ct from "countries-and-timezones";
import { EditNotificationDialogComponent } from "src/app/shared/edit-notification-dialog/edit-notification-dialog.component";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
@Component({
  selector: "app-offices",
  templateUrl: "./offices.component.html",
  styleUrls: ["./offices.component.scss"],
})
export class OfficesComponent implements OnInit {
  public offices: any[] = [];
  public departments: any[] = [];

  public newOfficeSchedule = {};
  public schedules: any[] = [];
  public officeMap = {};
  public deptMap = {};
  public teamInfo: any = {};
  public userId: string;
  public channels: any[] = [];
  public channelMap: any = {};
  public timezones: any[] = [];
  public userInfo: any = {};
  public planId: string;
  public officename: string;
  public allLeaveTypes: string[] = [];
  public icsParams: any = {
    officeId: "-1",
    period: 1,
    leaveTypes: [],
  };
  public periods: any[] = [
    { p: 1, l: "One month" },
    { p: 3, l: "Three months" },
    { p: 6, l: "Six months" },
  ];
  public downloadLink: string;

  public timeSlots: any[] = [
    { id: 0, label: "00:00 AM" },
    { id: 1, label: "01:00 AM" },
    { id: 2, label: "02:00 AM" },
    { id: 3, label: "03:00 AM" },
    { id: 4, label: "04:00 AM" },
    { id: 5, label: "05:00 AM" },
    { id: 6, label: "06:00 AM" },
    { id: 7, label: "07:00 AM" },
    { id: 8, label: "08:00 AM" },
    { id: 9, label: "09:00 AM" },
    { id: 10, label: "10:00 AM" },
    { id: 11, label: "11:00 AM" },
    { id: 12, label: "12:00 PM" },
    { id: 13, label: "01:00 PM" },
    { id: 14, label: "02:00 PM" },
    { id: 15, label: "03:00 PM" },
    { id: 16, label: "04:00 PM" },
    { id: 17, label: "05:00 PM" },
    { id: 18, label: "06:00 PM" },
    { id: 19, label: "07:00 PM" },
    { id: 20, label: "08:00 PM" },
    { id: 21, label: "09:00 PM" },
    { id: 22, label: "10:00 PM" },
    { id: 23, label: "11:00 PM" },
  ];

  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort, {}) sort: MatSort;

  private filterTz: string;
  public countries: any[] = [];
  public teamHolidays: any[] = [];
  public newHoliday: any = {};
  public country: string;
  public fromTemplate: boolean = false;

  constructor(
    private apiService: ApiService,
    public authService: AuthService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.planId = localStorage.getItem("planId");
    this.userId = localStorage.getItem("userId");

    this.allLeaveTypes = this.apiService.getTeamEventTypes(this.teamInfo);

    let allOffices = await this.apiService.getAllOffices().toPromise();
    this.offices = allOffices.filter((m) => {
      return m.type === "office";
    });
    this.departments = allOffices.filter((m) => {
      return m.type === "department";
    });
    console.log("allOffices", allOffices);
    this.schedules = await this.apiService.getAllSchedules().toPromise();
    this.dataSource = new MatTableDataSource(this.schedules);
    this.schedules.forEach((m) => {
      if (m.onlyDaily === undefined) {
        m.onlyDaily = true;
      }

      if (m.onlyWeekly === undefined) {
        m.onlyWeekly = true;
      }
    });
    this.offices.map((m) => {
      this.officeMap[m.id] = m;
    });
    this.departments.map((m) => {
      this.deptMap[m.id] = m;
    });
    let channelResponse = await this.authService.getChannels().toPromise();
    //let channelResponse2 = await this.authService.getChannels2().toPromise();
    let channelResponse3 = await this.authService.getChannels3().toPromise();
    let channelResponse4 = await this.authService.getChannels4().toPromise();
    //console.log('channelResponse',channelResponse);
    if (channelResponse.ok) {
      this.channels = channelResponse.channels;
      this.channels = this.channels.filter((m) => {
        return m.is_archived !== true;
      });
      this.channels.map((m) => {
        this.channelMap[m.id] = m;
      });
    }

    if (channelResponse4.ok) {
      let channels4 = channelResponse4.channels.filter((m) => {
        return m.is_archived !== true && !this.channelMap[m.id];
      });

      this.channels = this.channels.concat(channels4);
      this.channels.map((m) => {
        this.channelMap[m.id] = m;
      });
    }

    if (channelResponse3.ok) {
      let channels3 = channelResponse3.groups.filter((m) => {
        return m.is_archived !== true && !this.channelMap[m.id];
      });
      this.channels = this.channels.concat(channels3);
      this.channels.map((m) => {
        this.channelMap[m.id] = m;
      });
    }

    this.channels = this.channels.sort((a, b) => (a.name > b.name ? 1 : -1));

    let zones = [];
    let tzLabel = moment.tz.guess();
    for (let key in timezones.countries) {
      // this.countries.push({
      //   code:key,
      //   name:timezones.countries[key].name
      // })
      timezones.countries[key].zones.forEach((m) => {
        if (zones.indexOf(m) === -1) {
          zones.push(m);
        }
        if (m == tzLabel) {
          this.country = key;
        }
      });
    }
    zones = zones.sort();
    //generate timezone
    zones.map((m) => {
      let tz = moment.tz(m).utcOffset();

      this.timezones.push({ offset: tz * 60, name: m });
    });

    //console.log('$$$timezones',this.timezones);
    //console.log('$$$countries',this.countries);
    //console.log(ct.getCountriesForTimezone('America/New_York'));
  }

  checked(value: string): boolean {
    return this.icsParams.leaveTypes.includes(value);
  }
  getChannelName(id: string) {
    let channel = this.channels.filter((m) => {
      return m.id === id;
    });
    if (channel && channel.length > 0) {
      //console.log(`resolved channel name ${channel[0].name} for id ${id}`);
      return channel[0].name;
    } else {
      //console.log(`could not resolve channel name for id ${id}`);
      return id;
    }
  }

  valueChanged(value, $event) {
    console.log($event);
    if ($event.checked) {
      this.icsParams.leaveTypes.push(value);
    } else {
      let indx = this.icsParams.leaveTypes.indexOf(value);
      console.log("indx", indx);
      this.icsParams.leaveTypes.splice(indx, 1);
    }
  }

  getTz(offset: number) {
    let v = this.timezones.filter((m) => {
      return m.offset == offset;
    });
    if (v.length > 0) {
      return v[0].name;
    } else {
      ("404");
    }
  }

  getTimezones(filterText: string) {
    if (filterText) {
      return this.timezones.filter((m) => {
        return m.name.toUpperCase().includes(filterText.toUpperCase());
      });
    } else {
      return this.timezones;
    }
  }

  getTimeSlotLabel(timeId: number): string {
    const timeSlot = this.timeSlots.find((slot) => slot.id === timeId);

    if (!timeSlot) {
      // Convert number to time format if no matching slot found
      const ampm = timeId >= 12 ? "PM" : "AM";
      const hours = timeId > 12 ? timeId - 12 : timeId;
      return `${hours}:00 ${ampm}`;
    }
    return timeSlot.label;
  }

  getInitial(name: string): string {
    if (name) {
      return name.substring(0, 1);
    }
  }

  getRole(): string {
    let role = "member";
    var appRoles = this.teamInfo["appRoles"];

    if (appRoles) {
      for (var key in appRoles) {
        if (appRoles[key].indexOf(this.userId) != -1 && role !== "admin") {
          role = key;
        }
      }
    } else if (this.teamInfo.userId == this.userId) {
      role = "admin";
    }
    return role;
  }

  create(name: string, type: string) {
    console.log("creating ", type, name);
    let tzLabel = moment.tz.guess();
    let data = {
      type: type,
      officeName: name,
      tz_label: tzLabel,
      tz_offset: moment.tz(tzLabel).utcOffset() * 60,
    };
    this.apiService.createOffice(data).subscribe((res) => {
      //console.log('returned ',res);
      this.apiService.toast("New " + type + " created.", "success");
      if (type === "office") {
        this.offices.push(res);
        this.officeMap[res.id] = res;
      } else {
        this.departments.push(res);
        this.deptMap[res.id] = res;
      }
    });
  }

  validForm(): boolean {
    let valid = false;
    if (
      this.newOfficeSchedule["channelId"] &&
      this.newOfficeSchedule["localHour"] &&
      this.newOfficeSchedule["tz_label"]
    ) {
      valid = true;
    }

    return valid;
  }

  delete(office: any, index: number) {
    console.log("delete index", index);
    this.apiService.deleteOffice(office.id).subscribe((res) => {
      //console.log('returned ',res);
      this.apiService.toast(office.type + " deleted.", "success");
      if (office.type === "office") {
        this.offices.splice(index, 1);
      } else {
        this.departments.splice(index, 1);
      }
    });
  }

  async createSchedule(newOfficeSchedule: any) {
    console.log(newOfficeSchedule);
    this.newOfficeSchedule = newOfficeSchedule;
    delete this.newOfficeSchedule["tz_filter"];
    let offsetHour =
      moment.tz(this.newOfficeSchedule["tz_label"]).utcOffset() / 60;
    console.log("offsetHour", offsetHour);
    let utcHour = this.newOfficeSchedule["localHour"] + offsetHour * -1;
    if (utcHour > 24) {
      utcHour = utcHour - 24;
    }
    this.newOfficeSchedule["utcHour"] = utcHour;

    let utcHour2 = this.newOfficeSchedule["localHour2"] + offsetHour * -1;
    if (utcHour2 > 24) {
      utcHour2 = utcHour2 - 24;
    }
    this.newOfficeSchedule["utcHour2"] = utcHour2;

    this.newOfficeSchedule["tz_offset"] =
      moment.tz(this.newOfficeSchedule["tz_label"]).utcOffset() * 60;
    if (this.newOfficeSchedule["deptId"] == undefined) {
      this.newOfficeSchedule["deptId"] = "-1";
    }
    if (this.newOfficeSchedule["officeId"] == undefined) {
      this.newOfficeSchedule["officeId"] = "-1";
    }
    //console.log('this.newOfficeSchedule',this.newOfficeSchedule);

    const res = await this.apiService
      .createSchedule(this.newOfficeSchedule)
      .toPromise();
    this.apiService.toast("New notification schedule was created.", "success");

    const data = this.dataSource.data;
    data.push(res);
    this.dataSource.data = [...data];
  }

  async deleteSchedule(schedule: any, index: number) {
    console.log("delete index", index, schedule);
    console.log("delete id", schedule.id);
    try {
      await this.apiService.deleteSchedule(schedule.id).toPromise();

      // Remove the item from the dataSource
      const data = this.dataSource.data;
      data.splice(index, 1);
      this.dataSource.data = [...data]; // Trigger change detection

      this.apiService.toast("Notification schedule was deleted.", "success");
    } catch (error) {
      console.error("Error deleting schedule:", error);
      this.apiService.toast("Error deleting notification schedule.", "error");
    }
  }

  async updateSchedule(schedule: any, index: number) {
    console.log("update");
    delete schedule.tz_filter;
    let offsetHour = moment.tz(schedule.tz_label).utcOffset() / 60;
    let utcHour = schedule.localHour + offsetHour * -1;
    schedule.utcHour = utcHour;

    let utcHour2 = schedule.localHour2 + offsetHour * -1;
    schedule.utcHour2 = utcHour2;

    if (schedule.deptId == undefined) {
      schedule.deptId = null;
    }

    await this.apiService.updateSchedule(schedule).toPromise();
    delete schedule.editing;
    // Update the data source
    const data = this.dataSource.data;
    data[index] = schedule;
    this.dataSource.data = data;
    this.apiService.toast("Notification schedule was updated.", "success");
  }

  openNotificationDialog(schedule?: any, index?: number): void {
    console.log("this is new notification", index);
    const isEdit = !!schedule;
    const dialogData = {
      schedule: isEdit ? { ...schedule } : this.getEmptySchedule(),
      offices: this.offices,
      departments: this.departments,
      channels: this.channels,
      timeSlots: this.timeSlots,
      getTimezones: this.getTimezones.bind(this),
      isEdit,
    };

    const dialogRef = this.dialog.open(EditNotificationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (isEdit) {
          await this.updateSchedule(result, index);
        } else {
          await this.createSchedule(result);
        }
      }
    });
  }

  getEmptySchedule() {
    return {
      officeId: null,
      deptId: null,
      channelId: null,
      localHour: null,
      localHour2: null,
      tz_label: null,
      disableTomorrow: false,
      enableNoShow: false,
      onlyDaily: true,
      onlyWeekly: true,
      tz_filter: "",
    };
  }

  openEditDialog(schedule: any, index: number): void {
    console.log("this is edit notification", index);
    const dialogRef = this.dialog.open(EditNotificationDialogComponent, {
      data: {
        schedule: { ...schedule }, // Clone the schedule to avoid direct mutation
        offices: this.offices,
        departments: this.departments,
        channels: this.channels,
        timeSlots: this.timeSlots,
        getTimezones: this.getTimezones.bind(this),
        isEdit: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateSchedule(result, index);
      }
    });
  }

  getAvatarColor(name: string): string {
    // Simple hash function
    const hash = name.split("").reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);

    // Convert hash to RGB values
    const r = (hash & 0xff0000) >> 16;
    const g = (hash & 0x00ff00) >> 8;
    const b = hash & 0x0000ff;

    // Ensure the color is not too light (for visibility on white background)
    const minBrightness = 50;
    const adjustedR = Math.max(r, minBrightness);
    const adjustedG = Math.max(g, minBrightness);
    const adjustedB = Math.max(b, minBrightness);

    // Convert to hex and return
    return `#${((adjustedR << 16) | (adjustedG << 8) | adjustedB)
      .toString(16)
      .padStart(6, "0")}`;
  }

  createOneHoliday(): void {
    console.log(this.newHoliday);

    this.apiService.createOneHoliday(this.newHoliday).subscribe((res) => {
      this.apiService.toast("New holiday created.", "success");
      res.fromDate = this.apiService.createDateAsUTC(new Date(res.fromDate));
      res.toDate = this.apiService.createDateAsUTC(new Date(res.toDate));
      this.teamHolidays.push(res);
      this.teamHolidays = this.teamHolidays.sort((m, n) => {
        return m.fromDate - n.fromDate;
      });
    });
  }

  deleteHoliday(holiday: any, index: number) {
    if (!holiday.id) {
      this.teamHolidays.splice(index, 1);
    } else {
      this.apiService.deleteOneHolidays(holiday.id).subscribe((res) => {
        this.apiService.toast("Holiday deleted.", "success");
        this.teamHolidays.splice(index, 1);
      });
    }
  }

  updateHoliday(holiday: any) {
    console.log(holiday);
    if (holiday.id) {
      this.apiService.updateOneHoliday(holiday).subscribe((res) => {
        delete holiday.editing;
        this.apiService.toast("Holiday updated.", "success");
        this.teamHolidays = this.teamHolidays.sort((m, n) => {
          return m.fromDate - n.fromDate;
        });
      });
    } else {
      delete holiday.editing;
    }
  }

  async copyFromTemplate(country: string) {
    console.log(country);
    this.teamHolidays = await this.apiService
      .getCountryHolidays(country)
      .toPromise();
    this.fromTemplate = true;
  }

  async generateICS() {
    console.log(this.icsParams);
    this.downloadLink = undefined;
    let response = await this.apiService
      .generateICS(this.icsParams)
      .toPromise();
    this.downloadLink = response.fileName;
  }

  async saveFromTemplate() {
    // this.teamHolidays.forEach((m)=>{
    //    this.apiService.createOneHoliday(m);
    // })

    for (let m of this.teamHolidays) {
      m.fromTemplate = true;
      let response = await this.apiService.createOneHoliday(m).toPromise();
    }
    this.apiService.toast("Holidays created for your team.", "success");
    this.fromTemplate = false;
    this.teamHolidays = await this.apiService.getTeamHolidays().toPromise();
    this.teamHolidays = this.teamHolidays.sort((m, n) => {
      return m.fromDate - n.fromDate;
    });
    this.teamHolidays.forEach((m) => {
      m.fromDate = this.apiService.createDateAsUTC(new Date(m.fromDate));
      m.toDate = this.apiService.createDateAsUTC(new Date(m.toDate));
    });
  }
}
