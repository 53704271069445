/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./emoji-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i3 from "@angular/material/menu";
import * as i4 from "@angular/common";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/cdk/overlay";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "./emoji-picker.component";
var styles_EmojiPickerComponent = [i0.styles];
var RenderType_EmojiPickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmojiPickerComponent, data: {} });
export { RenderType_EmojiPickerComponent as RenderType_EmojiPickerComponent };
function View_EmojiPickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.selectEmoji(_v.context.$implicit.icon) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatMenuItem_0, i2.RenderType_MatMenuItem)), i1.ɵdid(1, 180224, [[1, 4]], 0, i3.MatMenuItem, [i1.ElementRef, i4.DOCUMENT, i5.FocusMonitor, [2, i3.ɵf24]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "span", [["class", "emoji-option"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).role; var currVal_1 = i1.ɵnov(_v, 1)._highlighted; var currVal_2 = i1.ɵnov(_v, 1)._triggersSubmenu; var currVal_3 = i1.ɵnov(_v, 1)._getTabIndex(); var currVal_4 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_5 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _v.context.$implicit.icon; var currVal_7 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_6, currVal_7); }); }
export function View_EmojiPickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "emoji-button"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i5.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(3, 1196032, null, 0, i3.MatMenuTrigger, [i10.Overlay, i1.ElementRef, i1.ViewContainerRef, i3.MAT_MENU_SCROLL_STRATEGY, [2, i3.MatMenu], [8, null], [2, i11.Directionality], i5.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "mat-menu", [["class", "emoji-menu"]], null, null, null, i2.View_MatMenu_0, i2.RenderType_MatMenu)), i1.ɵprd(6144, null, i3.ɵf24, null, [i3.MatMenu]), i1.ɵdid(7, 1294336, [["emojiMenu", 4]], 2, i3.MatMenu, [i1.ElementRef, i1.NgZone, i3.MAT_MENU_DEFAULT_OPTIONS], { panelClass: [0, "panelClass"] }, null), i1.ɵqud(603979776, 1, { items: 1 }), i1.ɵqud(335544320, 2, { lazyContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_EmojiPickerComponent_1)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵnov(_v, 7); _ck(_v, 3, 0, currVal_3); var currVal_5 = "emoji-menu"; _ck(_v, 7, 0, currVal_5); var currVal_6 = _co.emojiList; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 3).menuOpen || null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.renderIconFromCode(_co.selectedEmoji); _ck(_v, 4, 0, currVal_4); }); }
export function View_EmojiPickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-emoji-picker", [], null, null, null, View_EmojiPickerComponent_0, RenderType_EmojiPickerComponent)), i1.ɵdid(1, 114688, null, 0, i12.EmojiPickerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmojiPickerComponentNgFactory = i1.ɵccf("app-emoji-picker", i12.EmojiPickerComponent, View_EmojiPickerComponent_Host_0, { selectedEmoji: "selectedEmoji" }, { emojiSelected: "emojiSelected" }, []);
export { EmojiPickerComponentNgFactory as EmojiPickerComponentNgFactory };
