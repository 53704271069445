import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable } from "rxjs/Observable";
import { AuthService } from "./auth.service";
import "rxjs/add/operator/do";
import { Router } from "@angular/router";
import { ApiService } from "./api.service";
import { environment } from "../../environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public excludedUrls: [string] = ["GET_/admin/subscriptions"];
  constructor(
    public auth: AuthService,
    public router: Router,
    private apiService: ApiService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let that = this;

    if (request.url.indexOf("https://slack.com") === -1) {
      request = request.clone({
        setHeaders: {
          "X-WEB-TOKEN": localStorage.getItem("web_token") || "X",
        },
      });
    }

    this.auth.setProgressIndicator(true);

    return next.handle(request).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
          this.auth.setProgressIndicator(false);
        }
      },
      (err: any) => {
        this.auth.setProgressIndicator(false);
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // redirect to the login route
            that.auth.logout();
          }
          if (err.status === 403) {
            // redirect to the login route
            let url = request.url.replace(environment.serviceUrl, "");

            if (!this.excludedUrls.includes(`${request.method}_${url}`)) {
              that.apiService.toast(
                "You have requested some information which are restricted to admin/hr roles only.",
                "error"
              );
            }
          }
          if (err.status === 500) {
            //console.log(err);
            that.apiService.toast(err.error.message, "error");
          }
        }
      },
      () => {}
    );
  }
}
