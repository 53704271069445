<app-header></app-header>
<div class="signup-page" fxLayout="row" fxLayout.xs="column">
  <div fxFlex="50%" fxFlex.xs="100%" class="left" fxLayoutAlign="center center" fxLayout="column">
    <div class="mat-display-1">Welcome to <span class="brand">Officebot</span></div>

    <div>
      <a
        href="https://slack.com/oauth/authorize?client_id=187674243633.272542434992&scope=bot,users:read,users:read.email&redirect_uri={{
          redirectUrl
        }}"
      >
        <img
          alt="Add to Slack"
          height="40"
          width="139"
          src="https://platform.slack-edge.com/img/add_to_slack.png"
          srcset="
            https://platform.slack-edge.com/img/add_to_slack.png    1x,
            https://platform.slack-edge.com/img/add_to_slack@2x.png 2x
          "
        />
      </a>
    </div>
    <div class="mat-subheading-2">Get started with 14 days free trial</div>
    <div class="mat-subheading-1">
      By clicking "Add to Slack" button you will be taken to Slack authorization page. When you approve, Officebot will
      be added to your Slack workspace.
    </div>

    <div class="mat-subheading-1">
      By proceeding, you agree to the
      <a href="https://appstacksolutions.github.io/officebot-legal/terms.html" target="_blank"
        >Terms of Service and Privacy Policy.</a
      >
    </div>
  </div>
  <div fxFlex="50%" fxFlex.xs="100%" class="right" fxLayoutAlign="center center">
    <div class="poster"><img [src]="'assets/signup.svg'" /></div>
  </div>
</div>
<app-footer></app-footer>
