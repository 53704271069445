<app-header></app-header>
<div class="container" style="padding-top: 5rem">
  <h5 class="text-center"><strong>Last Updated: August 7, 2019</strong></h5>

  <p>
    Your privacy is important to us. This Privacy Policy covers how we collect, use, disclose, transfer, and store
    information that we receive when you use our OfficeBot slack app. Please take a moment and read over our privacy
    policy and let us know if you have any questions.
  </p>

  <h3 class="text-center">User Provided Information</h3>
  <p>
    We only ask for information we need. We capture user's timezone to calculate and show dates specific to users
    timezone. We aks for slack status update related permission whne user opts for the feature.
  </p>

  <h3 class="text-center">Disclosure to Third Parties</h3>
  <p>
    Your data is highly secured with us. We don't share any of your personal data to any third party organizations. But
    we can use the data internally to determine growth of the app and further analysis. Rest assured, your data will
    never be shared.
  </p>

  <h3 class="text-center">Your payment Information</h3>
  <p>
    Your credit card information is securely stored by Stripe.com. We don't store your credit card information at any
    time. Stripe.com is PCI compliance. Please visit https://stripe.com for more information.
  </p>

  <h3 class="text-center">Google Calendar</h3>
  <p>
    If you wish to sync your google calendar with OfficeBot, you need to authorize OfficeBot to edit your calendar. We
    store your google calendar token securely and never use for any other purpose except modifying your calendar when a
    new event is created/modified or deleted.
  </p>

  <h3 class="text-center">Changes</h3>

  <p>
    This Privacy Policy may be updated from time to time for any reason. We will notify you through slack when we have
    our policy updated.
  </p>
  <h3 class="text-center">Contact us</h3>

  <p>
    If you have any questions regarding privacy while using the Application, or have questions about our practices,
    please contact us via email at hello@appstacksolutions.com
  </p>
</div>
<app-footer></app-footer>
