import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {codes} from 'src/app/shared/currencycodes';
import {getCurrencySymbol} from '@angular/common';
import { from } from 'rxjs';

@Component({
  selector: 'app-roster-settings',
  templateUrl: './roster-settings.component.html',
  styleUrls: ['./roster-settings.component.scss']
})
export class RosterSettingsComponent implements OnInit {

  weekdays:string[]=["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"];
  
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  currcodes:any[]=[];
  filter:string;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  options = {  prefix: '$'};

  constructor(public dialogRef: MatDialogRef<RosterSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.currcodes = codes;
      //console.log(this.currcodes);
      console.log(getCurrencySymbol('USD','narrow'));
     }

  async ngOnInit() {
  }

  getCurrSymbol(code:string){
    return getCurrencySymbol(code,'narrow');
  }

  getOptions(){
    this.options.prefix = this.getCurrSymbol(this.data.teamInfo.roster.currency);
    return this.options;
  }
  checked(value:string):boolean{
    return this.data.teamInfo.weekDays.includes(value);
   
  }

  _filterCurrencyCodes(filter:string){
    if(filter){
      return this.currcodes.filter((m)=>{return m.startsWith(filter.toUpperCase())});
    }else{
      return this.currcodes;
    }
  }

  valueChanged(value,$event){
    console.log($event);
    if($event.checked){
      this.data.teamInfo.weekDays.push(value);
    }else{
      let indx = this.data.teamInfo.weekDays.indexOf(value);
      console.log("indx",indx);
      this.data.teamInfo.weekDays.splice(indx,1);
    }
  }
  onNoClick(): void {
    this.dialogRef.close({action: "no"});
  }

  onYesClick() {
    //console.log('saving', this.data);
    this.dialogRef.close({action: "yes", data:this.data});
  }

  invalidForm():boolean{
    if(this.data.teamInfo.roster.allowShiftSwap===undefined
      ||this.data.teamInfo.roster.paidBreak===undefined
      ||!this.data.teamInfo.roster.positions
      ||this.data.teamInfo.roster.positions.length==0
      ||this.data.teamInfo.roster.wageRate===undefined
      ||!this.data.teamInfo.roster.breaks
      ||this.data.teamInfo.roster.breaks.length===0
      ||!this.data.teamInfo.roster.break
      ||!this.data.teamInfo.weekDays
      ||this.data.teamInfo.weekDays.length===0){
        return true;
      }else{
        return false;
      }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value:string = event.value;

    if(!this.data.teamInfo.roster.positions){
      this.data.teamInfo.roster.positions=[];
    }
    // Add our fruit
    if ((value || '').trim()) {
      ;
      this.data.teamInfo.roster.positions.push(value.charAt(0).toUpperCase() + value.slice(1).trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(name: string): void {
    const index = this.data.teamInfo.roster.positions.indexOf(name);

    if (index >= 0) {
      this.data.teamInfo.roster.positions.splice(index, 1);
    }
  }

  addBreak(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    let v = parseInt(value);

    if(isNaN(v)){
      return;
    }

    if(!this.data.teamInfo.roster.breaks){
      this.data.teamInfo.roster.breaks=[];
    }
    this.data.teamInfo.roster.breaks.push(v);

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeBreak(name: number): void {
    console.log('deleting ',name);

    const index = this.data.teamInfo.roster.breaks.indexOf(name);

    if (index >= 0) {
      this.data.teamInfo.roster.breaks.splice(index, 1);
    }
  }
  
}
