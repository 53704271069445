<div class="confimation-dialog">
  <!-- <mat-toolbar color="primary" >
        <mat-card-header>Confirm</mat-card-header>
      </mat-toolbar> -->

  <h4 mat-dialog-title>{{ data.message || data.title }}</h4>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
      <div [innerHTML]="data.content"></div>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onYesClick()">{{ data.yesLabel || "Yes" }}</button>
    <button mat-raised-button (click)="onNoClick()">{{ data.noLabel || "No" }}</button>
  </div>
</div>
