var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { ApiService } from "../../shared/api.service";
import { startOfDay, endOfDay, isSameDay, isSameMonth } from "date-fns";
import { Subject } from "rxjs";
import { CalendarView, DAYS_OF_WEEK, } from "angular-calendar";
import * as moment from "moment-timezone";
import { AuthService } from "../../shared/auth.service";
import { Angulartics2 } from "angulartics2";
import { MatDialog } from "@angular/material";
import { ConfirmDialogComponent } from "src/app/shared/confirm-dialog/confirm-dialog.component";
import { ModifyLeaveComponent } from "src/app/shared/modify-leave/modify-leave.component";
// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
moment.updateLocale("en", {
    week: {
        dow: DAYS_OF_WEEK.MONDAY,
        doy: 0,
    },
});
var colors = {
    red: {
        primary: "rgba(218, 21, 6, 1)",
        secondary: "rgba(218, 21, 6, 0.5)",
    },
    blue: {
        primary: "#1e90ff",
        secondary: "#D1E8FF",
    },
    yellow: {
        primary: "#e3bc08",
        secondary: "#FDF1BA",
    },
};
var TeamEventsComponent = /** @class */ (function () {
    function TeamEventsComponent(apiService, authService, angulartics2, dialog) {
        var _this = this;
        this.apiService = apiService;
        this.authService = authService;
        this.angulartics2 = angulartics2;
        this.dialog = dialog;
        this.view = CalendarView.Month;
        this.CalendarView = CalendarView;
        this.viewDate = new Date();
        this.role = "member";
        this.currrentMonth = 0;
        this.actions = [
            {
                label: "<i  style=\"color:red\" class=\"fa fa-fw fa-edit blue-icon\" ></i>",
                onClick: function (_a) {
                    var event = _a.event;
                    _this.modifyEvent(event);
                },
            },
            {
                label: "<i  style=\"color:red\" class=\"fa fa-fw fa-trash red-icon\" ></i>",
                onClick: function (_a) {
                    var event = _a.event;
                    _this.deleteEvent(event);
                },
            },
        ];
        this.refresh = new Subject();
        this.events = [];
        this.teamInfo = {};
        this.userInfo = {};
        this.planId = "BRONZE";
        this.event = { duration: "single" };
        this.activeDayIsOpen = false;
        this.appliedEventTypes = [];
        this.daysOfWeek = [
            { tag: "mon", description: "Monday" },
            { tag: "tue", description: "Tuesday" },
            { tag: "wed", description: "Wednesday" },
            { tag: "thu", description: "Thursday" },
            { tag: "fri", description: "Friday" },
            { tag: "sat", description: "Saturday" },
            { tag: "sun", description: "Sunday" },
        ];
        this.members = [];
        this.membersForEvents = [];
        this.memberMap = {};
        this.selectedUserId = "-1";
        this.botUserMap = {};
        this.filterEvents = function () {
            var _this = this;
            //console.log(this.selectedUserId);
            if (this.selectedUserId == "-1") {
                return this.events;
            }
            else {
                return this.events.filter(function (item) {
                    return item.userId == _this.selectedUserId;
                });
            }
        };
        this.validateInput = function () {
            var valid = true;
            if (!this.event.userId ||
                !this.event.type ||
                !this.event.fromDate ||
                (this.event.duration == "multiple" && !this.event.toDate) ||
                (this.event.duration == "recurring" && !this.event.toDate)) {
                valid = false;
            }
            if (this.event.duration == "recurring" && !(this.event.weekDays && this.event.weekDays.length > 0)) {
                valid = false;
            }
            return valid;
        };
        if (localStorage.getItem("teamInfo")) {
            this.teamInfo = JSON.parse(localStorage.getItem("teamInfo"));
            // this.appliedEventTypes = apiService.getAppliedLeaveTypes(
            //   this.teamInfo.quota
            // );
            this.appliedEventTypes = apiService.getTeamEventTypes(this.teamInfo);
        }
        if (localStorage.getItem("userInfo")) {
            this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        }
        if (localStorage.getItem("planId")) {
            this.planId = localStorage.getItem("planId").toUpperCase();
        }
        if (localStorage.getItem("role")) {
            this.role = localStorage.getItem("role");
        }
    }
    TeamEventsComponent.prototype.deleteEvent = function (event) {
        console.log(event.userId, this.userInfo.user.id);
        if (!this.authService.isOfficebottAdmin() && event.userId !== this.userInfo.user.id) {
            this.apiService.toast("Only admin can delete other's events", "error");
            return;
        }
        var that = this;
        var dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: "350px",
            data: {
                title: "Delete event",
                content: "You are going to delete this event. Do you want to continue?",
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            //console.log('The dialog was closed ', result);
            if (result && result.action == "yes") {
                that.apiService.deleteOthersEvents(event).subscribe(function (data) {
                    that.apiService.toast("Event has been deleted successfully.", "success");
                    var index = that.events.findIndex(function (item) { return item.id == event.id; });
                    that.events.splice(index, 1);
                    that.refresh.next();
                }, function (error) { }, function () {
                    console.log("api completed");
                });
            }
        });
    };
    TeamEventsComponent.prototype.modifyEvent = function (event) {
        var _this = this;
        var that = this;
        var dialogRef = this.dialog.open(ModifyLeaveComponent, {
            width: "350px",
            data: { message: "Select Leave Type", eventTypes: this.appliedEventTypes },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            //console.log('The dialog was closed ', result);
            if (result && result.action == "yes") {
                console.log("Event Type", result.newEventType);
                that.apiService.modifyEvents(event.userId, event.id, result.newEventType).subscribe(function (data) {
                    that.apiService.toast("Your event has been modified successfully.", "success");
                    var index = that.events.findIndex(function (item) { return item.id == event.id; });
                    that.events[index]["title"] = "@" + _this.authService.getUsername(event.userId) + " " + result.newEventType;
                    that.refresh.next();
                }, function (error) { }, function () {
                    console.log("api completed");
                });
            }
        });
    };
    TeamEventsComponent.prototype.dayClicked = function (_a) {
        var date = _a.date, events = _a.events;
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                this.activeDayIsOpen = true;
            }
        }
    };
    TeamEventsComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        event.start = newStart;
        event.end = newEnd;
        this.handleEvent("Dropped or resized", event);
        this.refresh.next();
    };
    TeamEventsComponent.prototype.handleEvent = function (action, event) {
        this.modalData = { event: event, action: action };
    };
    TeamEventsComponent.prototype.addEvent = function () {
        this.events.push({
            title: "New event",
            start: startOfDay(new Date()),
            end: endOfDay(new Date()),
            color: colors.red,
            draggable: true,
            resizable: {
                beforeStart: true,
                afterEnd: true,
            },
        });
        this.refresh.next();
    };
    TeamEventsComponent.prototype.createEvent = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.angulartics2.eventTrack.next({
                            action: "EventOnBehalf",
                            properties: { category: "Button" },
                        });
                        this.event.tz_offset = JSON.parse(localStorage.getItem("userInfo")).user.tz_offset;
                        this.event.teamId = localStorage.getItem("teamId");
                        if (localStorage.getItem("planId") === "gold" &&
                            this.teamInfo.autoApprove !== undefined &&
                            this.teamInfo.autoApprove === false) {
                            this.event.askApproval = true;
                            this.event.botToken = localStorage.getItem("B_T");
                            if (this.userInfo.approver) {
                                this.event.approverId = this.userInfo.approver;
                            }
                            else {
                                this.event.approverId = this.teamInfo.userId;
                            }
                        }
                        else {
                            this.event.askApproval = false;
                        }
                        if (this.event.userId !== "-1") {
                            this.event.targetUsers = [this.event.userId];
                        }
                        else {
                            this.event.targetUsers = this.members.map(function (m) {
                                return m.id;
                            });
                        }
                        return [4 /*yield*/, this.apiService.postMyEvents(this.event).toPromise()];
                    case 1:
                        data = _a.sent();
                        if (this.event.askApproval) {
                            this.apiService.toast("Your request has been sent for approval.", "success");
                        }
                        else {
                            this.apiService.toast("Your event has been created succeefully.", "success");
                        }
                        return [4 /*yield*/, this.loadEvents()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TeamEventsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var botUsers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!localStorage.getItem("members")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.apiService.getAllBotUsers().toPromise()];
                    case 1:
                        botUsers = _a.sent();
                        this.members = botUsers;
                        if (this.authService.isAdmin()) {
                            this.membersForEvents = botUsers.slice();
                        }
                        else {
                            this.membersForEvents = botUsers.filter(function (m) {
                                return m.userId == localStorage.getItem("userId");
                            }).slice();
                        }
                        //console.log("BOT USERS", botUsers);
                        botUsers = botUsers
                            .filter(function (m) {
                            return m.inActive !== true;
                        })
                            .map(function (m) {
                            _this.botUserMap[m.userId] = m;
                            return m.userId;
                        });
                        // this.members = this.members.filter((m)=>{
                        //    return botUsers.includes(m.id);
                        // })
                        this.members.forEach(function (item) {
                            _this.memberMap[item.id] = item;
                        });
                        _a.label = 2;
                    case 2:
                        console.log("members", this.members);
                        console.log("membersForEvents", this.membersForEvents);
                        return [4 /*yield*/, this.loadEvents()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TeamEventsComponent.prototype.next = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("next ");
                        this.currrentMonth++;
                        return [4 /*yield*/, this.loadEvents()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TeamEventsComponent.prototype.prev = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("prev ");
                        this.currrentMonth--;
                        return [4 /*yield*/, this.loadEvents()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TeamEventsComponent.prototype.loadEvents = function () {
        return __awaiter(this, void 0, void 0, function () {
            var from, to, data, that;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        from = moment().add(this.currrentMonth, "months").startOf("month").format("YYYY-MM-DD");
                        to = moment().add(this.currrentMonth, "months").endOf("month").format("YYYY-MM-DD");
                        return [4 /*yield*/, this.apiService.getTeamEventsRange(from, to).toPromise()];
                    case 1:
                        data = _a.sent();
                        that = this;
                        this.events = [];
                        data.forEach(function (element) {
                            //console.log(moment.tz(element.fromDate, "Europe/London"));
                            //console.log(moment.tz(element.toDate, "Europe/London"));
                            var name = _this.authService.getUsername(element.userId);
                            if (!name) {
                                name = _this.botUserMap[element.userId].displayName
                                    ? _this.botUserMap[element.userId].displayName
                                    : _this.botUserMap[element.userId].name;
                            }
                            var event = {
                                id: element.id,
                                userId: element.userId,
                                start: _this.apiService.createDateAsUTC(new Date(element.fromDate)),
                                end: _this.apiService.createDateAsUTC(new Date(element.toDate)),
                                title: "@" + name + " " + element.type,
                                color: element.type == "PTO" ? colors.red : colors.blue,
                                actions: that.actions,
                                allDay: true,
                                draggable: false,
                            };
                            if (element.comment) {
                                element.comment = element.comment.replace(/[^a-zA-Z0-9_,]/g, " ");
                                if (element.comment.charAt(0) == "“") {
                                    event["title"] += ": " + element.comment.substring(1, element.comment.length - 1);
                                }
                                else {
                                    event["title"] += ": " + element.comment;
                                }
                            }
                            //console.log(event);
                            _this.events.push(event);
                        });
                        this.refresh.next();
                        return [2 /*return*/];
                }
            });
        });
    };
    TeamEventsComponent.prototype.getSelectedMemberName = function (userId) {
        if (this.selectedUserId === "-1") {
            return "Everyone";
        }
        var selectedMember = this.members.find(function (member) { return member.userId === userId; });
        return selectedMember ? selectedMember.displayName || selectedMember.name : "";
    };
    TeamEventsComponent.prototype.getSelectedMemberNameForCreateEvent = function (userId) {
        var selectedMember = this.members.find(function (member) { return member.userId === userId; });
        return selectedMember ? selectedMember.displayName || selectedMember.name : "";
    };
    return TeamEventsComponent;
}());
export { TeamEventsComponent };
