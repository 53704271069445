import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ElementRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete } from "@angular/material";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ApiService } from "src/app/shared/api.service";
import { AuthService } from "src/app/shared/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { timezones } from "src/app/shared/timezones";
import * as moment from "moment-timezone";
import { allowedLeaveTypePattern, leavTypeObjectToArray } from "src/app/shared/utility";
@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  public teamInfo: any = {};
  public leaveCounter: number[] = [];
  public yearEndMonths = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  weekdays: string[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  //fruitCtrl = new FormControl();
  //filteredFruits: Observable<string[]>;
  //fruits: string[] = ['Lemon'];
  //allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  public timezones: any[] = [];
  members: any[] = [];
  membersMap: any = {};
  adminMembers: any[] = [];
  hrMembers: any[] = [];
  filteredAdminUsers: Observable<string[]>;
  filteredHRUsers: Observable<string[]>;
  adminCtrl = new FormControl();
  hrCtrl = new FormControl();

  userId: string;
  botUsers: any[] = [];
  //botUsersFiltered: any[] = [];
  botUsersFiltered: MatTableDataSource<any>;

  @ViewChild("adminInput") adminInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  @ViewChild("hrInput") hrInput: ElementRef<HTMLInputElement>;
  //@ViewChild('autohr') matAutocompletehr: MatAutocomplete;
  planCode: string = "BRONZE";
  offices: any[] = [];
  departments: any[] = [];
  public fromInstall: boolean = false;
  displayedColumns: string[] = ["name", "active", "timezone", "approver"];
  isAdmin: boolean;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public apiService: ApiService,
    public authService: AuthService
  ) {
    //delete this.teamInfo.appRoles;

    this.botUsersFiltered = new MatTableDataSource<any>([]);

    for (var i = -1; i <= 50; i++) {
      this.leaveCounter.push(i);
    }

    this.filteredAdminUsers = this.adminCtrl.valueChanges.pipe(
      startWith(null),
      map((name: string | null) => (name ? this._filter(name) : this.members.slice()))
    );

    this.filteredHRUsers = this.hrCtrl.valueChanges.pipe(
      startWith(null),
      map((name: string | null) => (name ? this._filter(name) : this.members.slice()))
    );
    //console.log('filteredAdminUsers',this.filteredAdminUsers);

    this.userId = localStorage.getItem("userId");
  }

  async ngOnInit() {
    let that = this;

    let zones = [];
    let tzLabel = moment.tz.guess();
    for (let key in timezones.countries) {
      // this.countries.push({
      //   code:key,
      //   name:timezones.countries[key].name
      // })
      timezones.countries[key].zones.forEach((m) => {
        if (zones.indexOf(m) === -1) {
          zones.push(m);
        }
      });
    }
    zones = zones.sort();
    //generate timezone
    zones.map((m) => {
      let tz = moment.tz(m).utcOffset();

      this.timezones.push({ offset: tz * 60, name: m });
    });

    if (!localStorage.getItem("members")) {
      let data = await this.authService.getMembers().toPromise();
      if (data.ok) {
        let members = data.members
          .filter((user) => {
            return !user.is_bot && user.name != "slackbot";
          })
          .map((item) => {
            return {
              id: item.id,
              name: item.profile.display_name ? item.profile.display_name : item.name,
              displayName: item.profile.display_name,
              image_48: item.profile.image_48,
              tz_offset: item.tz_offset,
            };
          });
        localStorage.setItem("members", JSON.stringify(members));
      }
    }
    this.members = JSON.parse(localStorage.getItem("members"));
    this.members.map((m) => {
      this.membersMap[m.id] = m;
      m.filter = "";
    });
    console.log("membersMap", this.membersMap);

    this.authService.setProgressIndicator(true);
    this.route.params.subscribe((params) => {
      let install = params["install"];
      //enable only if user is admin , not just install
      // if(install=='y'){
      //   that.fromInstall = false;
      // }
    });
    this.loadBotUsers();
    // setTimeout(() => {
    //   if (that.botUsers.length == 0) {
    //     that.loadBotUsers();
    //   }
    // }, 5000);

    let allOffices = await this.apiService.getAllOffices().toPromise();
    this.offices = allOffices.filter((m) => {
      return m.type === "office";
    });
    //add None at the beginning of offices
    this.offices.unshift({ officeName: "None", id: "-1" });
    this.departments = allOffices.filter((m) => {
      return m.type === "department";
    });
    this.departments.unshift({ officeName: "None", id: "-1" });

    let botUser = await this.apiService.getBotUserInfo().toPromise();
    this.teamInfo = botUser.teamInfo;
    if (!this.teamInfo.quota["SICK"]) {
      this.teamInfo.quota["SICK"] = {
        accural: false,
        count: -1,
      };
    }
    this.teamInfo.startOfWeek = this.teamInfo.startOfWeek || "Monday";
    this.teamInfo.weekDays = this.teamInfo.weekDays || ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

    //generate leaveTypes if not there
    if (!this.teamInfo.leaveTypes) {
      this.teamInfo.leaveTypes = leavTypeObjectToArray(this.teamInfo.quota);
    }

    if (localStorage.getItem("planId")) {
      this.planCode = localStorage.getItem("planCode").toUpperCase();
      if (["STANDARD"].includes(this.planCode)) {
        this.displayedColumns.push("office", "department");
      }
    }

    if (this.teamInfo.appRoles) {
      if (this.teamInfo.appRoles["admin"] && this.teamInfo.appRoles["admin"].length > 0) {
        this.adminMembers = this.teamInfo.appRoles["admin"].map((id) => {
          return this.members.filter((m) => {
            return m.id == id;
          })[0];
        });
      }
      if (this.teamInfo.appRoles["hr"] && this.teamInfo.appRoles["hr"].length > 0) {
        this.hrMembers = this.teamInfo.appRoles["hr"].map((id) => {
          return this.members.filter((m) => {
            return m.id == id;
          })[0];
        });
      }
    } else {
      this.adminMembers = this.members.filter((m) => {
        return m.id == this.teamInfo.userId;
      });
      this.hrMembers = [];
    }
    this.authService.setProgressIndicator(false);
    this.isAdmin = this.authService.isOfficebottAdmin();
  }

  showactive: boolean = false;

  async filterActive() {
    if (this.showactive === true) {
      this.botUsersFiltered.data = this.botUsers.filter((m) => {
        return m.active === true;
      });
    } else {
      this.botUsersFiltered.data = this.botUsers;
    }
  }

  async loadBotUsers() {
    let that = this;
    this.botUsers = await this.apiService.getAllBotUsers().toPromise();
    console.log("all bot users", this.botUsers);
    this.botUsers.map((m) => {
      if (!m.approver) {
        m.approver = m.token.userId;
      }
      m.active = !m.inActive;
      m.deleted = m.inActive;
      m.name = that.membersMap[m.userId] ? that.membersMap[m.userId].name : m.name;
      m.filter = "";
    });
    console.log("bot users", this.botUsers);
    this.botUsersFiltered.data = this.botUsers;
  }
  _filterBotUsers(txt: string) {
    if (txt) {
      return this.botUsers.filter((m) => {
        return m.name.toLowerCase().includes(txt.toLowerCase());
      });
    } else {
      return this.botUsers;
    }
  }
  add(event: MatChipInputEvent): void {
    //debugger
    console.log("add", event);
    const input = event.input;
    const value = event.value;
    // Add our fruit
    // if ((value || '').trim()) {
    //   this.adminMembers.push({
    //     id:Math.random(),
    //     name:value.trim()
    //   });
    // }

    // // Reset the input value
    // if (input) {
    //   input.value = '';
    // }

    // this.adminCtrl.setValue(null);
  }

  remove(m: any, indx: number, type: string): void {
    if (type === "admin") {
      this.adminMembers.splice(indx, 1);
    } else {
      this.hrMembers.splice(indx, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent, type: string): void {
    console.log("selected", type);
    if (type === "admin") {
      let indx = this.adminMembers
        .map((e) => {
          return e.id;
        })
        .indexOf(event.option.value.id);
      if (indx === -1) {
        this.adminMembers.push(event.option.value);
      }

      this.adminInput.nativeElement.value = "";
      this.adminCtrl.setValue(null);
    } else {
      let indx = this.hrMembers
        .map((e) => {
          return e.id;
        })
        .indexOf(event.option.value.id);
      if (indx === -1) {
        this.hrMembers.push(event.option.value);
      }

      this.hrInput.nativeElement.value = "";
      this.hrCtrl.setValue(null);
    }
  }

  private _filter(value: any): any[] {
    console.log("_filter", value);
    if (typeof value === "string") {
      return this.members.filter((fruit) => fruit.name.toLowerCase().includes(value.toLowerCase()));
    } else {
      return this.members.filter((fruit) => fruit.name.toLowerCase().includes(value.name.toLowerCase()));
    }
  }

  getRole(): string {
    let role = "member";
    if (!this.teamInfo) {
      return role;
    }
    var appRoles = this.teamInfo["appRoles"];

    if (appRoles) {
      for (var key in appRoles) {
        if (appRoles[key].indexOf(this.userId) != -1 && role !== "admin") {
          role = key;
        }
      }
    } else if (this.teamInfo.userId == this.userId) {
      role = "admin";
    }
    return role;
  }

  saveUsersSetting($event): void {
    let data = this.botUsers.map((m) => {
      console.log(m);
      let user = {
        userId: m.userId,
        approver: m.approver,
        inActive: !m.active,
        officeId: m.officeId,
        deptId: m.deptId,
        wageRate: m.wageRate,
        tz: m.tz,
        tz_offset: m.tz_offset,
      };
      if (!m.officeId) {
        user.officeId = "-1";
      }
      if (!m.deptId) {
        user.deptId = "-1";
      }
      return user;
    });
    console.log(data);

    this.apiService.updateUserApprovers(data).subscribe(
      (data) => {
        this.apiService.toast("Users detail updated successfully.", "success");
      },
      (error) => {},
      () => {
        //console.log("api completed");
      }
    );
  }

  saveAdminSetting($event) {
    //console.log('adminMembers',this.adminMembers);
    //console.log('hrMembers',this.hrMembers);
    if (!this.adminMembers || this.adminMembers.length == 0) {
      this.apiService.toast("You must have at least one admin user.", "error");
      return;
    }
    if (!this.teamInfo.appRoles) {
      this.teamInfo.appRoles = {};
    }
    this.teamInfo.appRoles["admin"] = this.adminMembers.map((m) => {
      return m.id;
    });
    this.teamInfo.appRoles["hr"] = this.hrMembers.map((m) => {
      return m.id;
    });
    console.log(this.teamInfo);
    this.apiService.updateTeamInfo(this.teamInfo).subscribe(
      (data) => {
        console.log(this.teamInfo);
        localStorage.setItem("teamInfo", JSON.stringify(this.teamInfo));
        this.apiService.toast("Admin settings updated successfully.", "success");
      },
      (error) => {},
      () => {
        //console.log("api completed");
      }
    );
  }

  checked(value: string): boolean {
    if (this.teamInfo && this.teamInfo.weekDays) {
      return this.teamInfo.weekDays.includes(value);
    } else {
      return false;
    }
  }

  getTimezones(filterText: string) {
    if (filterText) {
      return this.timezones.filter((m) => {
        return m.name.toUpperCase().includes(filterText.toUpperCase());
      });
    } else {
      return this.timezones;
    }
  }

  valueChanged(value, $event) {
    console.log($event);
    if ($event.checked) {
      this.teamInfo.weekDays.push(value);
    } else {
      let indx = this.teamInfo.weekDays.indexOf(value);
      console.log("indx", indx);
      this.teamInfo.weekDays.splice(indx, 1);
    }
  }

  addNewLeaveType() {
    // Create a new leave type object
    const newLeaveType = {
      name: "LEAVE_TYPE", // Default name
      count: 0, // Default count
      accural: false, // Default accural status
      emoji: ":date: ",
    };

    // Add the new leave type to the list
    this.teamInfo.leaveTypes.push(newLeaveType);
  }

  deleteLeaveType(index: number) {
    this.teamInfo.leaveTypes.splice(index, 1);
  }

  updateEmoji(index: number, emoji: string) {
    console.log(index, emoji);
    if (this.teamInfo.leaveTypes[index]) {
      this.teamInfo.leaveTypes[index].emoji = emoji;
    }
    console.log(this.teamInfo.leaveTypes);
  }

  validateLeaveTypeInput = (event: KeyboardEvent) => {
    const inputChar = String.fromCharCode(event.charCode);
    if (!allowedLeaveTypePattern.test(inputChar)) {
      event.preventDefault();
    }
  };
}
