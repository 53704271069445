<app-header></app-header>

<section class="landing-page mat-typography">
  <section class="hero" fxLayout="row" fxLayout.lt-md="column">
    <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%" class="hero-text">
      <div class="headline mat-display-2">
        Streamline. Collaborate. Thrive. Your Workspace, Empowered by OfficeBot
      </div>
      <h3 class="subheadline">
        Leave Management, User Status Updates, Shift Coordination – All at Your
        Fingertips
      </h3>
      <div class="works-on">
        <span class="subheadline">Works on </span
        ><img
          style="width: 100px"
          src="assets/slack-logo.png"
          alt="slack logo"
        />
      </div>
      <div class="cta">
        <a href="/signup" class="cta-link">
          <button mat-raised-button color="primary" class="cta-button">
            Start My Free 14-Day Trial
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </button>
        </a>
        <h5 class="cta-subtext">
          No Credit Card Needed. Cancel Anytime. Start Free Today!
        </h5>
      </div>
    </div>
    <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%" fxHide.lt-md class="poster">
      <img src="assets/hero_1.svg" alt="OfficeBot Illustration" />
    </div>
  </section>
  <section fxLayout="column" class="feature-screenshot">
    <div class="screenshot">
      <img src="assets/dash-1.png" alt="Feature Screenshot" />
    </div>
  </section>
  <section fxLayout="column" class="features"></section>
</section>

<section class="customer-reviews">
  <h3 class="reviews-title">Our Customers Love Us</h3>

  <section class="capterra">
    <a
      target="_blank"
      href="https://www.capterra.com/p/185717/OfficeBot/reviews/"
    >
      <img
        src="https://assets.capterra.com/badge/541dd74610f07d72a5f17cbac2ebda29.png?v=2129872&p=185717"
    /></a>
  </section>

  <div class="review-cards">
    <div class="review-card">
      <p class="review-text">
        "The support is amazing. We quickly configured OfficeBot to do exactly
        what we need it to do. People quickly adopted OfficeBot and it's
        appearing in more and more team channels."
      </p>
      <p class="reviewer-name">- Kate G, Web Development</p>
    </div>
    <div class="review-card">
      <p class="review-text">
        "The feature we use the most is the status update. Slack already allows
        you to quickly update your status, but we found that people would put up
        funny statuses but no one would see them unless they were trying to
        message them. OfficeBot's status update is just as fast but also sends a
        notification to a channel of your choosing."
      </p>
      <p class="reviewer-name">- Daniel L. CEO</p>
    </div>
    <div class="review-card">
      <p class="review-text">
        "Everyone adopted the solution quickly, it's now part of the way we do
        business."
      </p>
      <p class="reviewer-name">- Simon L. CEO</p>
    </div>
  </div>
</section>

<section class="trusted-by">
  <h3 class="reviews-title">Trusted by Teams and Companies Worldwide</h3>
  <div class="trusted-logos">
    <img src="assets/cisco-meraki-logo.avif" alt="meraki.cisco.com/" />
    <img src="assets/AXQ_Capital_Transparent.png" alt="axqcap.com" />
    <img src="assets/bolster.png" alt="bolster.com" />
    <img src="assets/SmartD_Logo.svg" alt="smartd.co" />
    <img src="assets/Corus-Header-Logo.svg" alt="corusent.com" />
  </div>
</section>
<section class="mt-20">
  <div class="text-center">
    <a href="/signup" class="cta-link">
      <button mat-raised-button color="primary" class="big">
        Get Started <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
      </button>
    </a>
  </div>
  <div>
    <span>Start Your 14-Day Free Trial Today!</span>
  </div>
</section>

<!--leave management -->

<div fxLayout="column" class="leave-page card-panel">
  <div class="mt-20">
    <div class="mat-headline">
      Stay Updated, Be it In-Office or Embracing Hybrid Work!
    </div>
  </div>
  <div class="mat-subheading-2" style="margin-bottom: 48px">
    Your Team's Leave Calendar At Your Fingertips.
  </div>
  <div class="card" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left">
    <div fxFlex="40%" fxFlex.xs="100%">
      <div class="feature-title text-left">Daily And Weekly Summary</div>
      <p>
        Receive your daily whereabouts summary on your preferred channel,
        whether it's private or public. Plus, get a weekly summary sent to the
        same channel. Just invite OfficeBot to join your channel and stay
        informed
      </p>

      <div class="feature-title text-left">Holiday Reminders</div>
      <p>
        Receive your daily whereabouts summary on your preferred channel,
        whether it's private or public. Plus, get a weekly summary sent to the
        same channel. Just invite OfficeBot to join your channel and stay
        informed.
      </p>
      <div class="feature-title text-left">User Summary</div>
      <p>
        View user's leave summary anytime, from anywhere right inside your
        Slack.
      </p>
    </div>
    <div fxFlex="60%" fxFlex.xs="100%">
      <img [src]="'assets/ss-2.webp'" [style.max-width]="'520px'" />
    </div>
  </div>

  <div class="card" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left">
    <div fxFlex="50%" fxFlex.xs="100%">
      <img [src]="'assets/ss-1.webp'" />
    </div>
    <div fxFlex="50%" fxFlex.xs="100%">
      <div class="feature-title text-left">Manage Your Schedule</div>
      <p>
        Simply type your schedule and let everyone in your team know where you
        are. No more looking around and hide & seek. Get started with our
        pre-configured leave types or customize with up to 10 leave types as per
        your team's need. Need more? Let us know.
      </p>

      <div class="feature-title text-left">Check Your Team's Schedule</div>
      <p>
        Configure hodidays or copy from templates. Get notified on channel for
        upcoming holidays. Also you can view holidays anytime.
      </p>

      <div class="feature-title text-left">Auto Sync with Google Calendar</div>
      <p>
        Enable Google calendar sync and forget about exporting ics files.
        OfficeBot will automatically sync events between OfficeBot and your
        Google calendar.
      </p>
    </div>
  </div>
</div>

<div fxLayout="column" class="leave-page card-panel">
  <div class="mat-headline">
    Bye-bye Paperwork, Hello Effortless Shift Planning!
  </div>
  <div class="mat-subheading-2" style="margin-bottom: 48px">
    Revolutionize Your Rotations: Smart Shifts for Modern Teams.
  </div>
  <div class="card" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left">
    <div fxFlex="30%" fxFlex.xs="100%">
      <div class="feature-title text-left">
        Schedules That Click for Every Clock
      </div>
      <p>
        Farewell spreadsheets, hello speed! Craft your dream roster in minutes,
        not hours!
      </p>

      <div class="feature-title text-left">
        Sticky Notes, Consider Yourself Unstuck
      </div>
      <p>
        Slack speaks schedules: Fresh rosters land in your chat, shift reminders
        keep you on track! Say goodbye to missed shifts and hello to seamless
        planning - your workweek, served up hot and hassle-free!
      </p>
    </div>
    <div fxFlex="70%" fxFlex.xs="100%">
      <img [src]="'assets/ss-3.webp'" />
    </div>
  </div>

  <div class="card" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="left">
    <div fxFlex="50%" fxFlex.xs="100%">
      <img [src]="'assets/ss-4.webp'" />
    </div>
    <div fxFlex="50%" fxFlex.xs="100%">
      <div class="feature-title text-left">Request Shift Swap</div>
      <p>
        Need to juggle your schedule? Swap shifts in a snap! Request changes,
        get instant approvals, and keep your work-life balance in check. no more
        phone tag or frantic texts - just seamless swaps at your fingertips!
      </p>

      <div class="feature-title text-left">Ding! Your Shift's Calling</div>
      <p>
        Forget forgetfulness! Automatic alerts ping you 2 hours before showtime.
        Your personal shift reminder, keeping you punctual and prepared. Say
        goodbye to those 'oops, i forgot' moments and hello to stress-free shift
        starts!
      </p>
    </div>
  </div>
</div>

<!--leave management -->

<app-footer></app-footer>
