<div>
  <h2 mat-dialog-title>{{ data.isEdit ? "Edit" : "Add" }} Notification</h2>
  <mat-dialog-content>
    <mat-card-subtitle>
      <mat-icon color="warn">warning</mat-icon>
      You must invite OfficeBot to the channel if you create new notification.
    </mat-card-subtitle>
    <div fxLayout="column" fxLayoutGap="16px">
      <!-- Form fields -->
      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <mat-form-field *ngIf="data.offices?.length" fxFlex="calc(33% - 16px)" fxFlex.lt-md="100%">
          <mat-label>Office (Optional)</mat-label>
          <mat-select [(ngModel)]="data.schedule.officeId">
            <mat-option *ngFor="let office of data.offices" [value]="office.id">
              {{ office.officeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="data.departments?.length" fxFlex="calc(33% - 16px)" fxFlex.lt-md="100%">
          <mat-label>Department (Optional)</mat-label>
          <mat-select [(ngModel)]="data.schedule.deptId">
            <mat-option *ngFor="let dept of data.departments" [value]="dept.id">
              {{ dept.officeName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="calc(33% - 16px)" fxFlex.lt-md="100%">
          <mat-label>Channel</mat-label>
          <mat-select [(ngModel)]="data.schedule.channelId" required>
            <mat-option *ngFor="let channel of data.channels" [value]="channel.id">
              {{ channel.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="calc(33% - 16px)" fxFlex.lt-md="100%">
          <mat-label>Morning Time</mat-label>
          <mat-select [(ngModel)]="data.schedule.localHour" required>
            <mat-option *ngFor="let slot of data.timeSlots" [value]="slot.id">
              {{ slot.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="calc(33% - 16px)" fxFlex.lt-md="100%">
          <mat-label>Afternoon Time</mat-label>
          <mat-select [(ngModel)]="data.schedule.localHour2">
            <mat-option *ngFor="let slot of data.timeSlots" [value]="slot.id">
              {{ slot.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="calc(33% - 16px)" fxFlex.lt-md="100%">
          <mat-label>Timezone</mat-label>
          <mat-select [(ngModel)]="data.schedule.tz_label" required>
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search timezone..."
                noEntriesFoundLabel="No timezone found"
                [(ngModel)]="data.schedule.tz_filter"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let tz of data.getTimezones(data.schedule.tz_filter)" [value]="tz.name">
              {{ tz.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Toggle options -->
      <div fxLayout="column" fxLayoutGap="8px">
        <mat-slide-toggle color="accent" [(ngModel)]="data.schedule.disableTomorrow">
          Disable Tomorrow's feed
        </mat-slide-toggle>
        <mat-slide-toggle color="accent" [(ngModel)]="data.schedule.enableNoShow">
          No feed for no events
        </mat-slide-toggle>
        <mat-slide-toggle color="accent" [(ngModel)]="data.schedule.onlyDaily"> Daily Feed </mat-slide-toggle>
        <mat-slide-toggle color="accent" [(ngModel)]="data.schedule.onlyWeekly"> Weekly Feed </mat-slide-toggle>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onSave()" [disabled]="!isValid()">
      <mat-icon>save</mat-icon> {{ data.isEdit ? "Save Changes" : "Add Notification" }}
    </button>
  </mat-dialog-actions>
</div>
