var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { MatDialogRef, MatChipInputEvent } from '@angular/material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { codes } from 'src/app/shared/currencycodes';
import { getCurrencySymbol } from '@angular/common';
var RosterSettingsComponent = /** @class */ (function () {
    function RosterSettingsComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.addOnBlur = true;
        this.currcodes = [];
        this.separatorKeysCodes = [ENTER, COMMA];
        this.options = { prefix: '$' };
        this.currcodes = codes;
        //console.log(this.currcodes);
        console.log(getCurrencySymbol('USD', 'narrow'));
    }
    RosterSettingsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    RosterSettingsComponent.prototype.getCurrSymbol = function (code) {
        return getCurrencySymbol(code, 'narrow');
    };
    RosterSettingsComponent.prototype.getOptions = function () {
        this.options.prefix = this.getCurrSymbol(this.data.teamInfo.roster.currency);
        return this.options;
    };
    RosterSettingsComponent.prototype.checked = function (value) {
        return this.data.teamInfo.weekDays.includes(value);
    };
    RosterSettingsComponent.prototype._filterCurrencyCodes = function (filter) {
        if (filter) {
            return this.currcodes.filter(function (m) { return m.startsWith(filter.toUpperCase()); });
        }
        else {
            return this.currcodes;
        }
    };
    RosterSettingsComponent.prototype.valueChanged = function (value, $event) {
        console.log($event);
        if ($event.checked) {
            this.data.teamInfo.weekDays.push(value);
        }
        else {
            var indx = this.data.teamInfo.weekDays.indexOf(value);
            console.log("indx", indx);
            this.data.teamInfo.weekDays.splice(indx, 1);
        }
    };
    RosterSettingsComponent.prototype.onNoClick = function () {
        this.dialogRef.close({ action: "no" });
    };
    RosterSettingsComponent.prototype.onYesClick = function () {
        //console.log('saving', this.data);
        this.dialogRef.close({ action: "yes", data: this.data });
    };
    RosterSettingsComponent.prototype.invalidForm = function () {
        if (this.data.teamInfo.roster.allowShiftSwap === undefined
            || this.data.teamInfo.roster.paidBreak === undefined
            || !this.data.teamInfo.roster.positions
            || this.data.teamInfo.roster.positions.length == 0
            || this.data.teamInfo.roster.wageRate === undefined
            || !this.data.teamInfo.roster.breaks
            || this.data.teamInfo.roster.breaks.length === 0
            || !this.data.teamInfo.roster.break
            || !this.data.teamInfo.weekDays
            || this.data.teamInfo.weekDays.length === 0) {
            return true;
        }
        else {
            return false;
        }
    };
    RosterSettingsComponent.prototype.add = function (event) {
        var input = event.input;
        var value = event.value;
        if (!this.data.teamInfo.roster.positions) {
            this.data.teamInfo.roster.positions = [];
        }
        // Add our fruit
        if ((value || '').trim()) {
            ;
            this.data.teamInfo.roster.positions.push(value.charAt(0).toUpperCase() + value.slice(1).trim());
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    };
    RosterSettingsComponent.prototype.remove = function (name) {
        var index = this.data.teamInfo.roster.positions.indexOf(name);
        if (index >= 0) {
            this.data.teamInfo.roster.positions.splice(index, 1);
        }
    };
    RosterSettingsComponent.prototype.addBreak = function (event) {
        var input = event.input;
        var value = event.value;
        var v = parseInt(value);
        if (isNaN(v)) {
            return;
        }
        if (!this.data.teamInfo.roster.breaks) {
            this.data.teamInfo.roster.breaks = [];
        }
        this.data.teamInfo.roster.breaks.push(v);
        // Reset the input value
        if (input) {
            input.value = '';
        }
    };
    RosterSettingsComponent.prototype.removeBreak = function (name) {
        console.log('deleting ', name);
        var index = this.data.teamInfo.roster.breaks.indexOf(name);
        if (index >= 0) {
            this.data.teamInfo.roster.breaks.splice(index, 1);
        }
    };
    return RosterSettingsComponent;
}());
export { RosterSettingsComponent };
