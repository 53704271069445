<app-header></app-header>

<div class="login-page" fxLayout="row" fxLayout.xs="column">
  <div
    fxFlex="50%"
    fxFlex.xs="100%"
    class="right"
    fxLayoutAlign="center center"
  >
    <div class="poster"><img [src]="'assets/signup.svg'" /></div>
  </div>

  <div
    *ngIf="authService.getProgressIndicator()"
    fxFlex="50%"
    fxFlex.xs="100%"
    class="left"
    fxLayoutAlign="center center"
    fxLayout="column"
  >
    <!-- <mat-progress-spinner
      style="top: 80px"
      mode="indeterminate"
      color="light"
    ></mat-progress-spinner> -->

    <img src="assets/block-loading.svg" alt="OfficeBot Illustration" />
  </div>

  <div
    *ngIf="!authService.getProgressIndicator()"
    fxFlex="50%"
    fxFlex.xs="100%"
    class="left"
    fxLayoutAlign="center center"
    fxLayout="column"
  >
    <div class="mat-display-1">Sign in to your account</div>

    <div fxLayout="row">
      <a
        href="https://slack.com/oauth/authorize?client_id=187674243633.272542434992&scope=bot,users:read&redirect_uri={{
          redirectUrl
        }}&state=web"
      >
        <img
          src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
          srcset="
            https://platform.slack-edge.com/img/sign_in_with_slack.png    1x,
            https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x
          "
        />
      </a>
    </div>
    <div class="mat-subheading-2">
      Don't have Officebot installed on your workspace?
      <a routerLink="/signup" target="_blank">Get Started</a>
    </div>
  </div>
</div>
<app-footer></app-footer>
