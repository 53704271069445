import { Component, OnInit ,Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-modify-leave',
  templateUrl: './modify-leave.component.html',
  styleUrls: ['./modify-leave.component.css']
})
export class ModifyLeaveComponent  {

  eventTypes:any[]=[{tag:'pto',description:'PTO'},{tag:'wfh',description:'WFH'},{tag:'FLEX',description:'FLEX'},{tag:'WELLNESS',description:'WELLNESS'},{tag:'REMOTE',description:'REMOTE'},{tag:'SICK',description:'SICK'}];
  newEventType:string;

  constructor(
    public dialogRef: MatDialogRef<ModifyLeaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    }

  onNoClick(): void {
    this.dialogRef.close({action: "no"});
  }

  onYesClick() {
    this.dialogRef.close({action: "yes",newEventType:this.newEventType.toUpperCase()});
  }

}
