import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthService {
  private inProgressIndicator: boolean = false;
  constructor(private http: HttpClient, private route: ActivatedRoute, public router: Router) {}

  isAuthenticated(): boolean {
    if (localStorage.getItem("web_token")) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentPlan(): string {
    let planId = localStorage.getItem("planId");
    return planId;
  }

  isAdmin(): boolean {
    if (localStorage.getItem("userInfo")) {
      let data = JSON.parse(localStorage.getItem("userInfo"));
      return data.user.is_admin;
    } else {
      return false;
    }
  }

  isOfficebottAdmin(): boolean {
    if (["admin"].includes(localStorage.getItem("role"))) {
      return true;
    } else {
      return false;
    }
  }

  public getToken(): string {
    return localStorage.getItem("S_T");
  }

  logout(): void {
    localStorage.removeItem("S_T");
    localStorage.removeItem("B_T");
    localStorage.removeItem("userId");
    localStorage.removeItem("planId");
    localStorage.removeItem("planCode");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("teamInfo");
    localStorage.removeItem("role");
    localStorage.removeItem("members");
    localStorage.removeItem("teamId");
    localStorage.removeItem("web_token");
    this.router.navigate(["/login"]);
  }

  getUsername(userId: string): string {
    let username = "";
    if (localStorage.getItem("members")) {
      let members = JSON.parse(localStorage.getItem("members")).filter((item) => {
        return item.id == userId;
      });
      if (members.length > 0) {
        username = members[0].name;
      }
    }
    return username;
  }

  getUserInfo(): any {
    return this.http.get(
      `${environment.serviceUrl}/slack/users.info?user=${localStorage.getItem("userId")}&include_locale=true`
    );
  }

  getChannels(): any {
    return this.http.get(`${environment.serviceUrl}/slack/conversations.list?types=public_channel&limit=1000`);
  }
  getChannels2(): any {
    return this.http.get(`${environment.serviceUrl}/slack/channels.list`);
  }

  getChannels3(): any {
    return this.http.get(`${environment.serviceUrl}/slack/groups.list`);
  }

  getChannels4(): any {
    return this.http.get(`${environment.serviceUrl}/slack/conversations.list?types=private_channel&limit=1000`);
  }
  getMembers(): any {
    return this.http.get(`${environment.serviceUrl}/slack/users.list`);
  }

  getProgressIndicator(): boolean {
    return this.inProgressIndicator;
  }

  setProgressIndicator(status: boolean): void {
    this.inProgressIndicator = status;
  }
}
